import React from "react";
import { Modal, Box, Grid, FormLabel, Button, FormGroup, Typography } from "@mui/material";
import { Formik, Field, Form } from "formik";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import useValidation from "../common/ValidationSchema";
import { useState } from "react";
import { AuthAPI } from "../../apis/AuthAPI";

const ChangePasswordComponent = () => {
  // call styles for controls
  const classes = useStyle();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  // select box changen value
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(true);
  const [lang, setLang] = useState("");
  const handleSaveChanges = (e) => {
    setLang(t("msg_PreferenceSaveChanges"));
    setTimeout(function () {
      setOpen(false);
    }, 1000);
    window.location.reload();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const currentUser = JSON.parse(localStorage.getItem("user"));

  // Validation Section
  const initialValues = {
    newPassword: "",
    oldPassword: "",
    confirmPassword: "",
  };

  // ChangePassword Event
  const handleChangePassword = async (values, { setStatus, resetForm }) => {
    setStatus({})
    const { oldPassword, newPassword, confirmPassword } = values;
    AuthAPI.changePassword({ oldPassword, newPassword, confirmPassword })
      .then((response) => {
        resetForm({});
        if(response.status_code != 200){
        	setStatus({
          	sent: false,
          	msg: response.message,
        	});
        }else{
        	setStatus({
          	sent: true,
          	msg: response.message,
        	});
        }
      })
      .catch((error) => {
        setStatus({
          sent: true,
          msg: error.message,
        });
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalBox}> 
    <Grid className="">   
    <Grid className="content_panel">
      <Typography component="h1">{t("change_password_en_fr")}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={useValidation.validateChangePassword(t)}
        onSubmit={handleChangePassword}
      >
        {({ handleChange, status, values, errors }) => (
          <Form>
            {status && (
              <Typography
                component="p"
                className={`${status.sent ? "success_msg" : "error_msg"}`}
              >
                {status.msg}
              </Typography>
            )}
            {errors && (
              <Typography component="p" className="error_msg">
                {errors.oldPassword ||
                  errors.newPassword ||
                  errors.confirmPassword}
              </Typography>
            )}
            <FormGroup>
              <Grid style={{width: "100%", display: "flex"}} className="form_odd_field">
                <Grid className="grid_form_odd_even">
                  <FormLabel>
                    <b>{t("label_oldPass_en_fr")}</b>
                  </FormLabel>
                </Grid>
                <Grid style={{alignItems: "center",
                  display: "flex", marginRight: "1%"}} >
                  <Field 
                    name="oldPassword"
                    data-testid="oldPassword"
                    value={values.oldPassword}
                    onChange={handleChange}
                    type="password"
                    className="txt_edit_user"
                  />
                </Grid>
              </Grid>
              <Grid style={{width: "100%", display: "flex" }} className="form_odd_field">
                <Grid className="grid_form_odd_even">
                  <FormLabel>
                    <b>{t("label_newPass_en_fr")}</b>
                  </FormLabel>
                </Grid>
                <Grid style={{alignItems: "center",
                  display: "flex", marginRight: "1%"}} >
                  <Field 
                    name="newPassword"
                    data-testid="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    type="password"
                    className="txt_edit_user"
                  />
                </Grid>
              </Grid>
              <Grid style={{width: "100%", display: "flex"}} className="form_odd_field">
                <Grid className="grid_form_odd_even">
                  <FormLabel>
                    <b>{t("label_confirmPass_en_fr")}</b>
                  </FormLabel>
                </Grid>
                <Grid style={{alignItems: "center", display: "flex", marginRight: "1%"}}>
                  <Field  
                    name="confirmPassword"
                    data-testid="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    type="password"
                    className="txt_edit_user"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <Grid>
              <Button
                type="submit"
                data-testid="submit"
                className={classes.button}
              >
                {t("btn_update_en_fr")}
              </Button>
              <Button
                type="button"
                className={classes.button}
                onClick={handleClose}
              >
                {t("close")}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
    </Grid>
    </Box>
    </Modal>
  );
};
  {/* );
}; */}

const modalBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #D0DCF9",
  boxShadow: 5,
  p: 4,
};

//Styles Area
const useStyle = makeStyles({
  button: {
    width: "120px",
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: "5px",
    },
  },
  Link: {
    "&.MuiTypography-root": {
      color: "#1976d2",
      fontWeight: "bold",
      textDecoration: "none",
      "&:hover": {
        color: "#FFAB19",
        fontWeight: "bold",
        cursor: "pointer",
      },
    },
  },
  tabs: {
    width: "600px",
    borderRadius: "10px 10px 0px 0px",
    marginLeft: "10px",
    marginTop: "15px",
    "& .MuiTab-root": {
      display: "block",
      color: "white",
      backgroundColor: "#2F3D92",
      margin: "5px 5px 0px 0px",
      borderRadius: "6px 6px 0px 0px",
      fontWeight: "bold",
      textTransform: "capitalize",
      "&:hover": {
        color: "#FFAB19 !important",
      },
    },
    "& .Mui-selected": {
      color: "#2F3D92 !important",
      backgroundColor: "#D0DCF9",
      fontWeight: "bold",
    },
  },
  p: {
    "&.MuiTypography-root": {
      position: 'relative',
      padding: '5px',
      float: 'right',
    },
  },
  Image: {
    "&.MuiCardMedia-root": {
      width: "1%",
    },
  },
});

export default ChangePasswordComponent;
 
