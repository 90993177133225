import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { makeStyles } from "@mui/styles";
import useValidation from "../common/ValidationSchema";
import { useParams } from "react-router-dom";
import EnhancedDataGridTableComponent from "../common/EnhancedDataGridTable";
import { CommentAPI } from "../../apis/CommentAPI";

const CommentsComponent = () => { 
  // call styles for controls
  const classes = useStyles();
  // Call i18n translate function
  const { t } = useTranslation();
  const [commentRows, setCommentRows] = React.useState([]);
  const params = useParams();
  const id = params.id

  // Items for the backend pagination system
  const [totalRows, setTotalRows] = useState(0)
  const [selectedFilters, setSelectedFilters] = useState({ rows: [] })
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [orderBy, setOrderBy] = useState('-id')
  // Function called by the table elements (page, per page, order actions)
  const callback = (page, rowsPerPage, orderBy) => {
    setPage(page)
    setPerPage(rowsPerPage)
    setOrderBy(orderBy)
    getComments(selectedFilters, page, rowsPerPage, orderBy);
  }

  const getComments = (selectedFilters, page, rowsPerPage, orderBy) => {
    CommentAPI.list({id, selectedFilters, page, rowsPerPage, orderBy})
      .then((response) => {
        if (response.data !== null) {
          setTotalRows(response.total_rows)
          setCommentRows(response.data);
        } else {
          setCommentRows([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  Fetching list of comment
  useEffect(() => {
    getComments({ rows: [] }, 1, 10, orderBy);
  }, []);

  const createComment = async (values, { setStatus, resetForm }) => {
    const {comment} = values;
    CommentAPI.create({telegram_id: id, comment}, true)
    .then((response) => {
      resetForm({});
      setStatus({
        sent: true,
        msg: response.message,
      }, getComments({ rows: [] }, page, perPage, orderBy));
    })
    .catch((error) => {
      setStatus({
        sent: false,
        msg: error.user,
      });
    });
  };

  const headCells = [
    { id: "comment", label: t("comment") },
    { id: "created_at", label: t("comments_created_at") },
    { id: "created_by", label: t("created_by") },
  ];

  return (
    <Box className="wrapper">
      <Box className="content_panel">
        <Typography component="h1" className="font-2rem">
          {t("telegram_comments")} - {id}
        </Typography>
        <Typography component="h1">{t("message_comment")}</Typography>
        <Formik
          data-testid="formik"
          initialValues={{ comment: "" }}
          validationSchema={useValidation.validateComments(t)}
          onSubmit={createComment}
        >
          {({ handleChange, status, values }) => (
            <Form data-testid="form">
              {status && (
                <Typography
                  component="p"
                  className={`${status.sent ? "success_msg" : "error_msg"}`}
                >
                  {status.msg}
                </Typography>
              )}
              <Typography component="p" className="error_msg">
                <ErrorMessage name="comment" />
              </Typography>
              <Field
                name="comment"
                data-testid="comment"
                style={{width:"50%"}}
                component="textarea"
                rows="5"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <Typography component="hr" className={classes.hr} />
              <Button
                type="submit"
                className={classes.button}
                data-testid="submit"
              >
                {t("modifier_password_en_fr")}
              </Button>
            </Form>
          )}
        </Formik>
        {commentRows && commentRows.length != 0 && <Typography component="hr" className={classes.hr} />}
        {commentRows && commentRows.length != 0&& <EnhancedDataGridTableComponent
            allListRows={commentRows}
            headCells={headCells}
            flagID={6}
            totalRows={totalRows}
            callback={callback}
          />}
      </Box>
    </Box>
  );
};

//Styles Area
const useStyles = makeStyles({
  button: {
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: "5px",
      width: "auto"
    },
  },
  hr: {
    "&.MuiTypography-root": {
      marginTop: "10px",
    },
  },
});

export default CommentsComponent;
