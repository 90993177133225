import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import { Box, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
// To Call the navigation components
// import OLMServerComponent from "../configuration/ServersOLM";
import OLMServerComponent from "./servers/ServerForm"
import { Switch, Route, Link, Redirect, useRouteMatch } from "react-router-dom";
import UsersComponent from "./users/Users";
import FleetsComponent from "./fleets/Fleets";
import GearFormComponent from './gears/GearForm';
import AddGearFormComponent from "./gears/AddGearForm";
import ProtectedRoute from "../../services/ProtectedRoute";

const Admin = (props) => {
    const { path } = useRouteMatch();
    const classes = useStyle();
    const { t } = useTranslation();
    const currentUser = JSON.parse(localStorage.getItem("user"));
    
    return (
        <Box>
            <Fragment>
              <Typography component="h1" className="font-2rem">{t("admin_lang_admin")}</Typography>
              <Route
                  path={props.selectedURL}
                  render={(history) => (
                  <Tabs
                      className={`${classes.tabs} admin-nav`}
                      TabIndicatorProps={{ style: { display: "none" } }}
                      value={
                        history.location.pathname.split('/').length > 2 ? `/${history.location.pathname.split('/')[1]}/${history.location.pathname.split('/')[2]}` : history.location.pathname
                      }
                  >
                      {
                        currentUser && ((currentUser.role.length > 0) && currentUser.role.includes('Admin') || currentUser.superuser) && <Tab label={t("admin_lang_subtab_users")}
                            value={'/admin/users'}
                            component={Link}
                            to={'/admin/users'}
                            onClick={props.handleTabChange}
                            className={history.location.pathname == '/admin/users' ? "c-blue bdr-style-tab bg-light-blue text-trans admin-nav" : "bg-blue c-white bdr-style-tab text-trans admin-nav"}
                        />
                      }
                      <Tab label={t("admin_lang_subtab_fleets")}
                          value={'/admin/fleets'}
                          component={Link}
                          to={'/admin/fleets'}
                          onClick={props.handleTabChange}
                          className={history.location.pathname == '/admin/fleets' ? "c-blue bdr-style-tab bg-light-blue text-trans admin-nav" : "bg-blue c-white bdr-style-tab text-trans admin-nav"}
                      />
                      <Tab label={t("home_lang_tab_engins")}
                          value={'/admin/gears'}
                          component={Link}
                          to={'/admin/gears'}
                          onClick={props.handleTabChange}
                          className={history.location.pathname.includes('/admin/gears/')
                              ? "c-blue bdr-style-tab bg-light-blue text-trans admin-nav"
                              : "bg-blue c-white bdr-style-tab text-trans admin-nav"}
                      />
                      <Tab label={t("admin_lang_subtab_servers")}
                          value={'/admin/olmServer'}
                          component={Link}
                          to={'/admin/olmServer'}
                          onClick={props.handleTabChange}
                          className={history.location.pathname == '/admin/olmServer' ? "c-blue bdr-style-tab bg-light-blue text-trans admin-nav" : "bg-blue c-white bdr-style-tab text-trans admin-nav"}
                      />
                  </Tabs>
                  )}
              />
              <Box className="box_tabs"></Box>
              <Box className="">
                  <Switch>
                      <Route
                          exact
                          path={path}
                          render={() => <Redirect to={currentUser.role.includes('Admin') ? `${path}/users` : `${path}/fleets`} />}
                      />
                      <Route path={`${path}/gears/edit/:gear_id`}>
                          <GearFormComponent />
                      </Route>
                      <Route path={`${path}/gears`}>
                          <AddGearFormComponent />
                      </Route>
                      <ProtectedRoute roles={['Admin']} path={`${path}/users`}>
                          <UsersComponent />
                      </ProtectedRoute>
                      <Route path={`${path}/fleets`}>
                          <FleetsComponent />
                      </Route>
                      <Route path={`${path}/olmServer`}>
                          <OLMServerComponent />
                      </Route>
                      <Route path="*">
                          <Redirect to="/404" />
                      </Route>
                  </Switch>
              </Box>
            </Fragment>
        </Box>
    );
};

// Tab Section Area
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            margin: "6px 6px 6px 6px",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const headCells = [
  { id: "firstname", label: "Name" },
  { id: "email", label: "Email" },
  { id: "role", label: "Profiles" },
];

//Styles Area
const useStyle = makeStyles({
  Link: {
    marginLeft: "20px",
    "&:hover": {
      textDecoration: "none",
    },
  },
  button: {
    width: "150px",
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 5%, #E2E9F9 70%)",
      color: "black",
      fontWeight: "bold",
    },
  },
  tabs: {
    width: "100%",
    borderRadius: "10px 10px 0px 0px",
    "& .MuiTab-root": {
      whiteSpace: "nowrap",
      display: "block",
      color: "white",
      backgroundColor: "#2F3D92",
      margin: "5px 5px 0px 0px",
      borderRadius: "6px 6px 0px 0px",
      textTransform: "capitalize",
      fontWeight: "bold",
      "&:hover": {
        color: "#FFAB19 !important",
      },
    },
    "& .Mui-selected": {
      color: "#2F3D92 !important",
      backgroundColor: "#D0DCF9",
      fontWeight: "bold",
    },
  },
});

export default Admin;