// React and Redux declarations
import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import EnhancedDataGridTableComponent from "../../common/EnhancedDataGridTable";
import BackButton from "../../common/BackButton";
import { FleetAPI } from "../../../apis/FleetAPI";
import { GearAPI } from "../../../apis/GearAPI";


const FleetComponent = (props) => {
    const params = useParams();
    const fleet_id = params.fleet_id
    const [fleet, setFleet] = useState({});
    const [gears, setGears] = useState([]);
    const { t } = useTranslation();

    // Items for the backend pagination system
    const [totalRows, setTotalRows] = useState(0)
    const [selectedFilters, setSelectedFilters] = useState({ rows: [] })
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [orderBy, setOrderBy] = useState('-id')

    // Function called by the table elements (page, per page, order actions)
    const callback = (page, rowsPerPage, orderBy) => {
        setPage(page)
        setPerPage(rowsPerPage)
        setOrderBy(orderBy)
        getFleetGears(page, rowsPerPage, orderBy);
    }

    const getFleetGears = (page, rowsPerPage, orderBy) => {
        GearAPI.byFleet({fleet_id, page, rowsPerPage, orderBy}, true)
            .then((response) => {
                if (response !== null) {
                    setTotalRows(response.total_rows)
                    setGears(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getFleet = () => {
        FleetAPI.get({ fleet_id }).then((response) => {
            if (response !== null) {
                setFleet(response.data[0])
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        getFleet()
        getFleetGears(page, perPage, orderBy);
    }, []);

    const headCells = [
        { key: "equipment_id", label: t('header_lang_tab_engins'), id: 'equipment_id' },
        { key: "is_active", label: t('config_act_olm'), id: 'config__is_active' },
        { key: "nidc_value", label: t("nidc_ranges"), labelDesc: '[min ; max]', id: 'config__nidc_value' },
        { key: "gutMlowTh", label: t("config_lower_limit"), id: 'config__gutMlowTh' },
        { key: "gutMupTh", label: t("config_upper_limit"), id: 'config__gutMupTh' },
        { key: "msgNumber", label: t("config_nomsg"), id: 'config__msgNumber' },
        { key: "configFileVersion", label: t("cfg_version"), id: 'config__configFileVersion' },
        { key: "server", label: t("target_server"), id: 'config__server__server_type' },
        { key: "certificate_file", label: t("certificate_present"), id: 'config__server__certificate' },
    ];

    return (
        <Box className="frame_border">
            <Typography component="h1" className="font-2rem">
                {t("hightlight_Fleet_Details")} {fleet && fleet.fleetname }
            </Typography>
            <Grid className="content_panel">
                <Typography component="h1" className="font-2rem">{t("adduser_h1_info")}</Typography>
                <Grid container>
                    <Grid item xs={5}>
                        <Grid container className='min_height p_10'>
                            <Grid item xs={5} >
                                <b>{t("fleet_soft_id")}</b>
                            </Grid>
                            <Grid item xs={7}>
                                {fleet && fleet.sw_identification_number}
                            </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={5} >
                                <b>{t("fleet_version")}</b>
                            </Grid>
                            <Grid item xs={7}>
                                {fleet && fleet.version}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {gears.length > 0 && <Grid className="content_panel">
                <Typography component="h1" className="font-2rem">{t("vehicles")}</Typography>
                <EnhancedDataGridTableComponent
                    allListRows={gears}
                    headCells={headCells}
                    flagID={2}
                    fleetList={true}
                    totalRows={totalRows}
                    callback={callback}
                />
            </Grid>}
            <BackButton url={'/admin/fleets'} text={t("back_to_administration")} />
        </Box >
    );
};

export default FleetComponent;