import { api } from "./config/axiosConfig"
import { defineCancelApiObject } from "./config/axiosUtils"

export const TelegramAPI = {
    get: async function ({ telegram_id }, cancel = false) {
        const url = `/message/details/${telegram_id}/`
        const response = await api.request({
            url: url,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    download: async function (telegram_id, cancel = false) {
        const url = `/message/downloadTelegram/${telegram_id}/`
        const response = await api.request({
            url: url,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    list: async function ({ selectedFilters, page, rowsPerPage, orderBy }, cancel = false) {
        let url = `/message/telegrams/?`
        url += page ? `page=${page}&` : ''
        url += rowsPerPage ? `per_page=${rowsPerPage}&` : ''
        url += orderBy ? `order_by=${orderBy}&` : ''
        url += `filters=${JSON.stringify(selectedFilters)}`
        const response = await api.request({
            url: url,
            method: "GET",
            signal: cancel ? cancelApiObject[this.list.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    delete: async function (ids, cancel = false) {
        const url = `/message/telegram_delete/?pk_ids=${ids}`
        const response = await api.request({
            url: url,
            method: "DELETE",
            signal: cancel ? cancelApiObject[this.list.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

const cancelApiObject = defineCancelApiObject(TelegramAPI)