// React and Redux declarations
import React, { Fragment } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import UsersListComponent from "./UsersList";
import EditUsersComponent from "./EditUser";
import AddUsersComponent from "./AddUser";

const UsersComponent = (props) => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <UsersListComponent />
            </Route>
            <Route exact path={`${path}/editUser/:user_id`}>
                <EditUsersComponent />
            </Route>
            <Route exact path={`${path}/addUser`}>
                <AddUsersComponent />
            </Route>
        </Switch>
    );
};

export default UsersComponent;