// React and Redux declarations
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import {
  Modal,
  Box,
  Grid,
  FormLabel,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { makeStyles } from "@mui/styles";
import useValidation from "../common/ValidationSchema";
import { AuthAPI } from "../../apis/AuthAPI";

const ForgotPasswordComponent = () => {
  // call styles for controls
  const classes = useStyles();
  // Call i18n translate function
  const { t } = useTranslation();
  // Call the modal window
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
  // Set the values for Form initiation
  const initialValues = {
    username: "",
  };
  const [isLoading, setLoading] = useState(false);
  // ForgotPassword API Calling using dispatch function
  const handleForgotPassword = async (values, { setStatus, resetForm }) => {
    setStatus({})
    const username = values.username;
    setLoading(true);
    AuthAPI.forgot(username)
      .then((response) => {
        resetForm({});
        setLoading(false);
        setStatus({
          sent: response.success,
          msg: response.message,
        });
      })
      .catch((error) => {
        setLoading(false);
        setStatus({
          sent: false,
          msg: error.user,
        });
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalBox}>
        <Grid className="content_panel">
          <h1>{t("forgot_lang_reset")}</h1>
          <Grid className="intro_text">{t("forgot_lang_notes")}</Grid>
          <Grid>
            <Formik
              initialValues={initialValues}
              validationSchema={useValidation.validateForgotPassword(t)}
              onSubmit={handleForgotPassword}
            >
              {({ handleChange, status, values }) => (
                <Form>
                  {status && (
                    <Typography
                      component="p"
                      className={`${status.sent ? "success_msg" : "error_msg"}`}
                    >
                      {status.msg}
                    </Typography>
                  )}
                  <Typography component="p" className="error_msg">
                    <ErrorMessage name="username" />
                  </Typography>
                  <Grid className="gridForm">
                    <FormLabel className={classes.Label}>
                      {t("login_lang_username")}
                    </FormLabel>
                  </Grid>
                  <Grid className="forgot_field">
                    <Field
                      name="username"
                      data-testid="username"
                      value={values.username}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      type="text"
                    />
                  </Grid>
                  <Typography component="hr" className={classes.hr} />
                  <Box className="fp_button">
                    <Grid container>
                      <Grid item xs={2}>&nbsp;</Grid>
                      <Grid item xs={6}>
                          {isLoading ? (
                          <Button
                            disabled
                            className={classes.button}
                          >
                            <CircularProgress size={8} />
                            {t("forgot_lang_sendmail")}
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            data-testid="submit"
                            className={classes.button}
                          >
                            {t("forgot_lang_reset")}
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                        type="button"
                        className={classes.button}
                        onClick={handleClose}
                      >
                        {t("common_lang_cancel")}
                      </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const modalBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #D0DCF9",
  boxShadow: 5,
  p: 4,
};
//Styles Area
const useStyles = makeStyles({
  button: {
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: 0,
    },
  },
  hr: {
    "&.MuiTypography-root": {
      marginTop: "10px",
    },
  },
  Label: {
    "&.MuiFormLabel-root": {
      color: "black",
      fontWeight: "bold",
      margin: "1px 0px 0px 10px",
      padding: "0px",
      lineHeight: "1.5em",
    },
  },
});

export default ForgotPasswordComponent;
