// React and Redux declarations
import React, { useState, useEffect, Fragment } from "react";
import moment from 'moment'
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import { Grid, Box, Typography, Button } from "@mui/material";
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import EnhancedDataGridTableComponent from "../common/EnhancedDataGridTable";
import CustomFilterComponent from "../common/CustomFilter";
import useFilters from "../common/CommonFilters";
import { setLocalStorage, getLocalStorage } from "../common/LocalStorage";
import _ from "lodash";
import { TelegramAPI } from "../../apis/TelegramAPI";


const TelegramListComponent = () => {
    const { t } = useTranslation();
    
    const headCells = [
        { id: "id", label: t("telegram_id") },
        { id: "gear__equipment_id", label: t("telegram_gear") },
        { id: "received_on", label: t("telegram_received_on") },
        { id: "count", label: t("telegram_messages_number"), },
        { id: "messages__error_number", label: t("telegram_error_numbers") },
        { id: "sent", label: t("sent") },
    ];

    const localStateName = 'telegramListState'
    const storedState = getLocalStorage(localStateName);

    // call styles for controls
    const classes = useStyle();
    // Load All telegrams list
    const [telegrams, setTelegrams] = useState([]);
    const [downloadData, setDownloadData] = useState([]);

    // Items for the backend pagination system
    const [totalRows, setTotalRows] = useState(0)
    const [selectedFilters, setSelectedFilters] = useState(storedState && storedState.selectedFilters || { rows: [] })
    const [page, setPage] = useState(storedState && storedState.page || 1)
    const [perPage, setPerPage] = useState(storedState && storedState.perPage || 10)
    const [orderBy, setOrderBy] = useState(storedState && storedState.orderBy || '-id')
    // Function called by the filters component
    const genericDispatcher = (selectedFilters) => {
        setPage(1)
        setLocalStorage(localStateName, selectedFilters, 1, perPage, orderBy)
        setSelectedFilters(selectedFilters)
        getTelegrams(selectedFilters, 1, perPage, orderBy);
    };
    // Function called by the table elements (page, per page, order actions)
    const callback = (page, rowsPerPage, orderBy) => {
        setPage(page)
        setPerPage(rowsPerPage)
        setOrderBy(orderBy)
        setLocalStorage(localStateName, selectedFilters, page, rowsPerPage, orderBy)
        getTelegrams(selectedFilters, page, rowsPerPage, orderBy);
    }

    const getTelegrams = (selectedFilters, page, rowsPerPage, orderBy) => {
        TelegramAPI.list({selectedFilters, page, rowsPerPage, orderBy}, true)
        .then((response) => {
            if (response.data !== undefined) {
                // Set the total rows used for the table
                setTotalRows(response.total_rows)
                const data = response.data.map((data, index) => {
                    return {
                        id: data['id'],
                        gear: data['gear']['uic_id'],
                        received_on: moment(data.received_on).format('DD/MM/YYYY HH:mm:ss'),
                        msg_number: data.messages.length,
                        error_number: _.join(_.map(data.messages, (msg) => msg.error_number), ', '),
                        sent: data.sent
                    }
                })
                setTelegrams(response.data);
                setDownloadData(data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    useEffect(() => {
        getTelegrams(selectedFilters, page, perPage, orderBy);
    }, []);
        
    // Delete the telegram
    const [isDelete, setDelete] = useState("");

    const handleDeleteTelegram = (rowItemID) => {
        const text = t("delete_telegram_confirmation")
        if(confirm(text) == true) {
            TelegramAPI.delete(rowItemID).then((response) => {
                if (response !== undefined) {
                    setDelete(response);
                }
                getTelegrams(selectedFilters, page, perPage, orderBy);
            });
        }
    };

    const csvHeadCells = [
        { key: "id", label: t("telegram_id") },
        { key: "gear", label: t("telegram_gear") },
        { key: "received_on", label: t("telegram_received_on") },
        { key: "msg_number", label: t("telegram_messages_number"), },
        { key: "error_number", label: t("telegram_error_numbers") },
        { key: "sent", label: t("sent") },
    ];

    return (
        <Box>
            <Fragment>
                <Typography component="h1" className="font-2rem">{t("home_lang_tab_msg")}</Typography>
                <Grid className="action_panel">
                    {Object.keys(downloadData).length > 0 && (
                        <CSVLink
                            headers={csvHeadCells}
                            data={downloadData}
                            className={classes.Link}
                            separator=";"
                            filename="exportTelegrammes.csv"
                        >
                        <Button type="button" className={classes.button}>
                            {t("user_download_file")}
                        </Button>
                        </CSVLink>
                    )}
                </Grid>
                <Box className="content_block">
                    <Grid className="content_panel">
                        <CustomFilterComponent
                            allFilters={useFilters.telegramListFilters}
                            genericDispatcher={genericDispatcher}
                            module_name={'Telegrams'}
                            initData={selectedFilters || undefined}
                        />
                    </Grid>
                    {isDelete !== "" && (
                        <Typography component="p" className={isDelete['success'] ? "success_msg": "error_msg"}>
                            {isDelete.message}
                        </Typography>
                    )}
                    <EnhancedDataGridTableComponent
                        storedState={storedState}
                        allListRows={telegrams}
                        headCells={headCells}
                        flagID={3}
                        handleDeleteTelegram={handleDeleteTelegram}
                        totalRows={totalRows}
                        callback={callback}
                        page={page}
                        setPage={setPage}
                    />
                </Box>
            </Fragment>
        </Box>
    );
};



//Styles Area
const useStyle = makeStyles({
    Link: {
        "&:hover": {
            textDecoration: "none",
        },
    },
    button: {
        "&.MuiButton-root": {
            backgroundImage: "linear-gradient(to bottom, #FFFFFF 5%, #E2E9F9 70%)",
            color: "black",
            fontWeight: "bold",
        },
    },
});

export default TelegramListComponent;
