import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }) => {
    try {
      const data = await AuthService.login(username, password);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return { user: message };
    }
  }
);

export const forgot = createAsyncThunk("auth/forgot", async (username) => {
  const data = await AuthService.forgot(username);
  return { user: data };
});

export const logout = createAsyncThunk("auth/logout", async (refreshToken) => {
  const data = await AuthService.logout(refreshToken);
  return { user: data };
});

export const allUserList = createAsyncThunk(
  "auth/allUserList",
  async ({selectedFilters, page, rowsPerPage, orderBy}) => {
    const data = await AuthService.allUserList(selectedFilters, page, rowsPerPage, orderBy);
    return { user: data };
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ oldPassword, newPassword, confirmPassword }) => {
    const data = await AuthService.changePassword(
      oldPassword,
      newPassword,
      confirmPassword
    );
    return { user: data };
  }
);

export const updateConfig = createAsyncThunk(
  "auth/updateConfig",
  async (props) => {
    const data = await AuthService.updateConfig(
      props
    );
    return { config: data };
  }
);

export const allrolelist = createAsyncThunk("auth/allrolelist", async () => {
  const data = await AuthService.allrolelist();
  return { user: data };
});

export const getUser = createAsyncThunk(
  "auth/getUser",
  async ({ user_id }) => {
    const data = await AuthService.getUser(user_id);
    return { user: data };
  }
);

export const userUpdate = createAsyncThunk(
  "auth/userUpdate",
  async ({ first_name, last_name, email, role, is_active, user_id }) => {
    const data = await AuthService.userUpdate(
      first_name,
      last_name,
      email,
      role,
      is_active,
      user_id
    );
    return { user: data };
  }
);

export const userDelete = createAsyncThunk(
  "auth/userDelete",
  async ([rowID]) => {
    const data = await AuthService.userDelete(rowID);
    return { user: data };
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ username, first_name, last_name, email, role, is_active }) => {
    try {
      const data = await AuthService.register(
        username,
        first_name,
        last_name,
        email,
        role,
        is_active,
      );
      return { user: data };
    } catch (error) {
      const data =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return { user: data };
    }
  }
);

// Create Fleets
export const createFleet = createAsyncThunk(
  "auth/createFleet",
  async ({ txtFleetName, txtSoftIdNo, txtVersion, fleet_id }) => {
    const data = await AuthService.createFleet(
      txtFleetName,
      txtSoftIdNo,
      txtVersion,
      fleet_id
    );
    return { user: data };
  }
);

export const getFleetlist = createAsyncThunk(
  "auth/getFleetlist",
  async ({selectedFilters, fleet_id, page, rowsPerPage, orderBy}) => {
    const data = await AuthService.getFleetlist(selectedFilters, fleet_id, page, rowsPerPage, orderBy);
    return { user: data };
  }
);

export const deleteCertificate = createAsyncThunk(
  "auth/deleteCertificate",
  async ({server_type}) => {
    const data = await AuthService.deleteCertificate(server_type);
    return { user: data };
  }
);

export const getGearlist = createAsyncThunk(
  "auth/getGearlist",
  async ({selectedFilters, fleet_id, gear_id, page, rowsPerPage, orderBy}) => {
    const data = await AuthService.getGearlist(selectedFilters, fleet_id, gear_id, page, rowsPerPage, orderBy);
    return { user: data };
  }
);

export const getConfig = createAsyncThunk(
  "auth/getConfig",
  async () => {
    const data = await AuthService.getConfig();
    return { config: data };
  }
);

export const createGear = createAsyncThunk(
  "auth/createGear",
  async ({
    equipment_id,
    uic_id,
    sncf_number,
    enginType,
    selectedValue,
  }) => {
    try {
      const data = await AuthService.createGear(
        equipment_id,
        uic_id,
        sncf_number,
        enginType,
        selectedValue
      );
      return { user: data };
    } catch (error) {
      const data =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return { user: data };
    }
  }
);

export const gearDelete = createAsyncThunk(
  "auth/gearDelete",
  async ([rowID]) => {
    const data = await AuthService.gearDelete(rowID);
    return { user: data };
  }
);

export const createConfigureOLM = createAsyncThunk(
  "auth/createConfigureOLM",
  async ({
    equipment_id,
    uic_id,
    sncf_number,
    is_registered,
    is_active,
    fleet,
    nidc_value,
    gutMlowTh,
    gutMupTh,
    msgNumber,
    configFileVersion,
    forcing,
    server_type,
    config_file_name,
    gear_id
  }) => {
    const data = await AuthService.createConfigureOLM(
      equipment_id,
      uic_id,
      sncf_number,
      is_registered,
      is_active,
      fleet,
      nidc_value,
      gutMlowTh,
      gutMupTh,
      msgNumber,
      configFileVersion,
      forcing,
      server_type,
      config_file_name,
      gear_id
    );
    return { user: data };
  }
);

export const getMessage = createAsyncThunk(
  "auth/getMessage",
  async ({ message_id }) => {
    const data = await AuthService.getMessage(message_id);
    return { user: data };
  }
);

export const getMessagesList = createAsyncThunk(
  "auth/getMessagesList",
  async ({selectedFilters, page, rowsPerPage, orderBy}) => {
    const data = await AuthService.getMessagesList(selectedFilters, page, rowsPerPage, orderBy);
    return { user: data };
  }
);

export const getTelegram = createAsyncThunk(
  "auth/getTelegram",
  async ({ telegram_id }) => {
    const data = await AuthService.getTelegram(telegram_id);
    return { user: data };
  }
);

export const getTelegramsList = createAsyncThunk(
  "auth/getTelegramsList",
  async ({selectedFilters, page, rowsPerPage, orderBy}) => {
    const data = await AuthService.getTelegramsList(selectedFilters, page, rowsPerPage, orderBy);
    return { user: data };
  }
);

export const getHeartbeatsList = createAsyncThunk(
  "auth/getHeartbeatsList",
  async ({selectedFilters, page, rowsPerPage, orderBy}) => {
    const data = await AuthService.getHeartbeatsList(selectedFilters, page, rowsPerPage, orderBy);
    return { user: data };
  }
);

export const messageDelete = createAsyncThunk(
  "auth/messageDelete",
  async ([rowID]) => {
    const data = await AuthService.messageDelete(rowID);
    return { user: data };
  }
);

export const telegramDelete = createAsyncThunk(
  "auth/telegramDelete",
  async ([rowID]) => {
    const data = await AuthService.telegramDelete(rowID);
    return { user: data };
  }
);

export const downloadTelegram = createAsyncThunk(
  "auth/downloadTelegram",
  async (id) => {
    const data = await AuthService.downloadTelegram(id);
    return { user: data };
  }
);

export const getServerDetails = createAsyncThunk(
  "auth/getServerDetails",
  async ({server}) => {
    const data = await AuthService.getServerDetails(server);
    return {user: data};
  }
);

export const server = createAsyncThunk(
  "auth/server",
  async ({ serverLogin, serverPass, serverUrl, olmServer, certificate }) => {
    const data = await AuthService.server(
      serverLogin,
      serverPass,
      serverUrl,
      olmServer,
      certificate
    );
    return { user: data };
  }
);

export const createComment = createAsyncThunk(
  "auth/createComment",
  async ({txtComment, id}) => {
    const data = await AuthService.createComment(txtComment, id);
    return { user: data };
  }
);

export const getCommentsList = createAsyncThunk(
  "auth/getCommentsList",
  async ({selectedFilters, id}) => {
    const data = await AuthService.getCommentsList(selectedFilters, id);
    return { user: data };
  }
);

export const getJournalList = createAsyncThunk(
  "auth/getJournalList/?lang=" + localStorage.getItem("language"),
  async ({selectedFilters, page, rowsPerPage, orderBy}) => {
    const data = await AuthService.getJournalList(selectedFilters, page, rowsPerPage, orderBy);
    return { user: data };
  }
);

export const createFilter = createAsyncThunk(
  "auth/createFilter",
  async ({name, module_name, state}) => {
    const data = await AuthService.createFilter(
      name,
      module_name,
      state
    );
    return { user: data };
  }
);

export const updateFilter = createAsyncThunk(
  "auth/updateFilter",
  async ({name, module_name, state, id}) => {
    const data = await AuthService.updateFilter(
      name,
      module_name,
      state,
      id
    );
    return { user: data };
  }
);

export const getFilterList = createAsyncThunk(
  "auth/getFilterList",
  async ( module_name ) => {
    const data = await AuthService.getFilterList(module_name);
    return { user: data };
  }
);

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [register.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = action.payload.user;
    },
    [forgot.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [allUserList.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [allrolelist.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [createFleet.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [createFleet.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [getFleetlist.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getGearlist.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [createGear.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [createGear.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [createConfigureOLM.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [createConfigureOLM.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [downloadTelegram.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [downloadTelegram.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [getMessage.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getMessage.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [getMessagesList.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getMessagesList.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [getTelegramsList.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getTelegramsList.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [getHeartbeatsList.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getHeartbeatsList.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [server.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getServerDetails.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getServerDetails.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [createComment.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [createComment.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [getJournalList.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getFilterList.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getFilterList.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [createFilter.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [createGear.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [deleteCertificate.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [deleteCertificate.rejected]: (state) => {
      state.isLoggedIn = false;
    },
    [getCommentsList.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [getCommentsList.rejected]: (state) => {
      state.isLoggedIn = false;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
