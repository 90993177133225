// React and Redux declarations
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Formik, Form, Field, ErrorMessage } from "formik";
import useValidation from "../../common/ValidationSchema";
import BackButton from "../../common/BackButton";
import { GearAPI } from "../../../apis/GearAPI";
import { FleetAPI } from "../../../apis/FleetAPI";
import WarningIcon from '@mui/icons-material/Warning';
import {
    Grid,
    Box,
    Button,
    Typography,
    Select,
    MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const GearFormComponent = (props) => {
    const classes = useStyle();
    const params = useParams();
    const { t } = useTranslation();
    const gear_id = params.gear_id;
    const [loading, setLoading] = useState(true)
    const [noCert, setNoCert] = useState(false)

    const [gear, setGear] = useState({
        equipment_id: "",
        uic_id: "",
        sncf_number: "",
        trainset_number: "",
        is_registered: true,
        is_active: false,
        fleet: "",
        nidc_value: [[448, 455]],
        gutMlowTh: "",
        gutMupTh: "",
        msgNumber: "",
        configFileVersion: "",
        forcing: false,
        server_type: 0,
        config_file_name: "",
    });

    const getGear = () => {
        GearAPI.get({gear_id}, true)
        .then((response) => {
            setLoading(false)
            if (response.data !== null) {
                const gearData = response.data[0]
                setGear({
                    equipment_id: gearData.equipment_id,
                    uic_id: gearData.uic_id,
                    sncf_number: gearData.sncf_number,
                    trainset_number: gearData.trainset_number,
                    is_registered: gearData.is_registered,
                    is_active: gearData.config?.is_active,
                    fleet: gearData.fleet?.id,
                    nidc_value: gearData.config.nidc_value.map(data => [data.lower, data.upper]),
                    gutMlowTh: gearData.config?.gutMlowTh,
                    gutMupTh: gearData.config?.gutMupTh,
                    msgNumber: gearData.config?.msgNumber,
                    configFileVersion: gearData.config?.configFileVersion,
                    forcing: gearData.config?.forcing,
                    server_type: gearData.config?.server?.server_type,
                    config_file_name: gearData.config?.config_file_name
                })
                setNoCert(!gearData.config?.server?.certificate)
            }
        })
        .catch((error) => {
            setLoading(false)
            console.log(error);
        });
    };

    const getFleetslist = () => {
        FleetAPI.list({}, true)
        .then((response) => {
            if (response.data !== null) {
                setFleets(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    const [fleets, setFleets] = useState([]);

    useEffect(() => {
        getFleetslist();
        gear_id !== '' && !isNaN(gear_id) ? getGear() : ""
    }, []);
    

    const validateLowerAndUpperLimit = (gutMlowTh, gutMupTh) => {
        if (
            parseInt(gutMlowTh) >= 1 && parseInt(gutMlowTh) <=200
            && parseInt(gutMupTh) >= 1 && parseInt(gutMupTh) <= 200
            && parseInt(gutMlowTh) <= parseInt(gutMupTh)
        ) {
            return true;
        } else{
            return false;
        }
    }
    const [status1, setStatus1] = React.useState({});
    
    const updateGear = async (values, { setStatus, resetForm }) => {
        setStatus({})
        const {
            gutMlowTh,
            gutMupTh,
            config_file_name
        } = values;
        if (!validateLowerAndUpperLimit(gutMlowTh, gutMupTh)) {
            setStatus1({
                sent: false,
                success: false,
                msg: t("gutm_low_high_validation"),
                formNo: 1
            })
            setStatus({})
            // return
        } else if (config_file_name && (config_file_name.length > 34 || config_file_name.length < 5)){
            setStatus1({
                sent: false,
                success: false,
                msg: t("file_name_validation"),
                formNo: 1
            })
            setStatus({})
        } else {
            values.nidc_value = _.map(values.nidc_value, arr => _.map(arr, el => parseInt(el)))
            GearAPI.update(values, gear_id, true)
            .then((response) => {
                if (response != undefined) {
                    setStatus({
                        sent: true,
                        success: response.success,
                        msg: response.message,
                    });
                    setStatus1({})
                    setNoCert(!response.server_certificate)
                }
            }).catch((error) => {
                console.log(error)
                setStatus({
                    sent: true,
                    success: false,
                    msg: error.message,
                });
            });
        }
    };
    const numCheck = (e, callback) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            callback(e);
        } else {
            e.target.value = '';
            callback(e.target.defaultValue)
        }
    };

    return (
        <Box className="frame_border">
            { !loading && <Typography component="h1" className="font-2rem">{gear_id ? `${t("edit_gear")} - ${gear.equipment_id || ''}` : t("add_gear")}</Typography>}
            {
            !loading && <Grid container className="content_panel">
                <Grid xs={6}>
                <Grid container>
                    <Grid xs={12}>
                    {status1 && (
                        <Typography
                            component="p"
                            className={`${status1.success ? "success_msg" : "error_msg"}`}
                        >
                            {status1.msg}
                        </Typography>
                    )}
                    <Formik
                        initialValues={{...gear}}
                        enableReinitialize={true}
                        validationSchema={useValidation.validateOLMConfiguration(t)}
                        onSubmit={updateGear}
                    >
                        {({ handleChange, status, values, errors, setFieldValue }) => (
                        <Form>
                            {status && (
                            <Typography
                                component="p"
                                className={`${status.success ? "success_msg" : "error_msg"}`}
                            >
                                {status.msg}
                            </Typography>
                            )}
                            <Grid>
                                <Grid container className='min_height p_10'>
                                    <Grid item xs={6} >
                                        <b className='p_t_7'>{t("equipment_id")} <span className='asterisk'> {'*'} </span></b>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name="equipment_id"
                                            data-testid="equipment_id"
                                            value={values.equipment_id || ""}
                                            onChange={handleChange}
                                            type="text"
                                            className="input_txt"
                                        />
                                        <ErrorMessage component="div" className="error_msg" name="equipment_id" />
                                    </Grid>
                                </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("uic_id")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="uic_id"
                                        data-testid="uic_id"
                                        value={values.uic_id || ""}
                                        onChange={handleChange}
                                        type="text"
                                        className="input_txt"
                                    />
                                    <ErrorMessage component="div" className="error_msg" name="uic_id" />
                                </Grid>
                            </Grid>
                            <Grid container className='min_height p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("sncf_number")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="sncf_number"
                                        data-testid="sncf_number"
                                        value={values.sncf_number || ""}
                                        onChange={handleChange}
                                        type="text"
                                        className="input_txt"
                                    />
                                    <ErrorMessage component="div" className="error_msg" name="sncf_number" />
                                </Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("trainset_number")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="trainset_number"
                                        data-testid="trainset_number"
                                        value={values.trainset_number || ""}
                                        onChange={handleChange}
                                        type="text"
                                        className="input_txt"
                                    />
                                    <ErrorMessage component="div" className="error_msg" name="trainset_number" />
                                </Grid>
                            </Grid>
                            <Grid container className='min_height p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("config_fleet_name")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        value={values.fleet || ""}
                                        className="select_edit_style"
                                        name="fleet"
                                        onChange={handleChange}
                                    >
                                        {fleets.map(({ id, fleetname }, index) => (
                                            <MenuItem key={index} value={id}>
                                                {fleetname}
                                            </MenuItem>
                                        ))}
                                    </Select><br />
                                    <ErrorMessage component="div" className="error_msg" name="fleet" />
                                </Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("config_act_olm")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        value={values.is_active || false}
                                        name="is_active"
                                        data-testid="OlmData"
                                        onChange={handleChange}
                                        className="select_edit_style"
                                    >
                                        {selectedfnOLM.map(({ id, name }, index) => (
                                            <MenuItem key={index} value={id} >
                                                {t(name)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container className='min_height p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("nidc_ranges")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    {values && values.nidc_value && values.nidc_value.map((item, idx) => (
                                        <div id="addr0" key={idx}>
                                            <Field
                                                name={`nidc_value[${idx}][0]`}
                                                value={item[0]}
                                                onChange={(e) => numCheck(e, handleChange)}
                                                type="text"
                                                className="nidc_edit_value"
                                                min="0"
                                                max="1023"
                                            />
                                            <Field
                                                name={`nidc_value[${idx}][1]`}
                                                value={item[1]}
                                                onChange={(e) => numCheck(e, handleChange)}
                                                type="text"
                                                className="nidc_edit_value"
                                                min="0"
                                                max="1023"
                                            />
                                            {
                                                values.nidc_value && values.nidc_value.length > 1 && idx != 0 && <DeleteForeverIcon
                                                    className="delete_icon t_5"
                                                    onClick={() => {
                                                        values.nidc_value.splice(idx, 1)
                                                        setFieldValue('nidc_value', values.nidc_value)
                                                    }}
                                                />
                                            }
                                            {
                                                (values.nidc_value.length < 5 && idx === values.nidc_value.length - 1) && <AddIcon
                                                    className="add_icon t_5"
                                                    onClick={() => setFieldValue('nidc_value', [...values.nidc_value, [0, 1]])}
                                                />
                                            }
                                            {
                                                errors.nidc_value && errors.nidc_value[idx] && <Typography component="p" className="error_msg">
                                                    {errors.nidc_value[idx]}
                                                </Typography>
                                            }
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("GutM_low_th")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="gutMlowTh"
                                        data-testid="gutMlowTh"
                                        value={values.gutMlowTh || ""}
                                        onChange={(e) => numCheck(e, handleChange)}
                                        type="text"
                                        className="input_txt"
                                    />
                                    <ErrorMessage component="div" className="error_msg" name="gutMlowTh" />
                                </Grid>
                            </Grid>
                            <Grid container className='min_height p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("GutM_up_th")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="gutMupTh"
                                        data-testid="gutMupTh"
                                        value={values.gutMupTh || ""}
                                        onChange={(e) => numCheck(e, handleChange)}
                                        type="text"
                                        className="input_txt"
                                    />
                                    <ErrorMessage component="div" className="error_msg" name="gutMupTh" />
                                </Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("config_nomsg_packet")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="msgNumber"
                                        data-testid="msgNumber"
                                        value={values.msgNumber || ""}
                                        onChange={(e) => numCheck(e, handleChange)}
                                        type="text"
                                        className="input_txt"
                                    />
                                    <ErrorMessage component="div" className="error_msg" name="msgNumber" />
                                </Grid>
                            </Grid>
                            <Grid container className='min_height p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("config_file_version")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="configFileVersion"
                                        data-testid="configFileVersion"
                                        value={values.configFileVersion || ""}
                                        onChange={handleChange}
                                        type="text"
                                        className="input_txt"
                                    />
                                    <ErrorMessage component="div" className="error_msg" name="configFileVersion" />
                                </Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("config_forcing")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        value={values.forcing || false}
                                        data-testid="forcing"
                                        name="forcing"
                                        onChange={handleChange}
                                        className="select_edit_style"
                                    >
                                        {selectedForcing.map(({ id, name }, index) => (
                                            <MenuItem key={index} value={id}>
                                                {t(name)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container className='min_height p_10'>
                                <Grid item xs={6} >
                                    <b className='p_t_7'>{t("target_server")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        name="server_type"
                                        value={values.server_type || 0}
                                        data-testid="server_choice"
                                        onChange={(e) => {setNoCert(false); handleChange(e)}}
                                        className="select_edit_style"
                                    >
                                        {selectedServer.map(({ id, name }, index) => (
                                            <MenuItem key={index} value={id}>
                                            {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {
                                        noCert && <Grid container direction="row" alignItems="center" sx={{ marginTop: '5px' }}>
                                            <Grid item>
                                                <WarningIcon color="error" />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    component="p"
                                                    color="error"
                                                >
                                                    { t('no_certificate') }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={6} >
                                <b className='p_t_7'>{t("config_file_name")} <span className='asterisk'> {'*'} </span></b>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="config_file_name"
                                        data-testid="config_file_name"
                                        value={values.config_file_name|| gear.config_file_name || ""}
                                        onChange={handleChange}
                                        type="text"
                                        className="input_txt"
                                    />
                                    <ErrorMessage component="div" className="error_msg" name="config_file_name" />
                                </Grid>
                            </Grid>
                            <Grid>
                                <Button
                                type="submit"
                                data-testid="submit"
                                className={classes.button}
                                >
                                {t("modifier_password_en_fr")}
                                </Button>
                            </Grid>
                            </Grid>
                        </Form>
                        )}
                    </Formik>
                    </Grid>
                </Grid>
                </Grid>
                <Grid xs={6}></Grid>
            </Grid>
            }
            <BackButton url={'/gears'} text={t("back_to_gears")} />
        </Box>
    );
};

//Styles Area
const useStyle = makeStyles({
    deleteImg: {
        "&.MuiSvgIcon-root": {
            color: "red",
            cursor: "pointer"
            }
    },
    button: {
        "&.MuiButton-root": {
            backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
            color: "black",
            fontWeight: "bold",
            border: "1px solid",
            borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
            margin: "15px 0px",
            whiteSpace: "nowrap",
            minWidth: "max-content"
        },
    },
    p: {
        width: "100%",
        "&.MuiTypography-root": {
            marginTop: "-35px",
            marginLeft: "350px",
        },
    },
});

const selectedfnOLM = [
    { id: true, name: "gear_activated" },
    { id: false, name: "gear_inactivated" },
];
const selectedServer = [
    { id: 0, name: "Integration" },
    { id: 1, name: "Production" },
];

const selectedForcing = [
    { id: true, name: "gear_activated" },
    { id: false, name: "gear_inactivated" },
];

export default GearFormComponent;