// React and Redux declarations
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import { Grid, Box, Typography, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
//Call the Icon Images
import RedIcon from "../../static/images/red.png";
import AmberIcon from "../../static/images/orange.png";
import GreenIcon from "../../static/images/green.png";
import GrayIcon from "../../static/images/gray.png";
// To Call the navigation components
import EnhancedDataGridTableComponent from "../common/EnhancedDataGridTable";
import CustomFilterComponent from "../common/CustomFilter";
import useFilters from "../common/CommonFilters";
import { setLocalStorage, getLocalStorage } from "../common/LocalStorage";
import { HeartbeatAPI } from "../../apis/HeartbeatAPI";

const MonitoringListComponent = () => {
  // call styles for controls
  const classes = useStyle();
  const { t } = useTranslation();
  const headCells = [
    { id: "gear__equipment_id", label: t("monitoring_eq_id") },
    { id: "datetime", label: t("monitoring_life_frame") },
    { id: "datetime", label: t("monitoring_status") },
  ];

  const localStateName = 'monitoringListState'
  const storedState = getLocalStorage(localStateName)
  console.log(storedState)

  // Load All Message list
  const [allMessageRows, setAllMessageListRows] = useState([]);

  // Items for the backend pagination system
  const [totalRows, setTotalRows] = useState(0)
  const [selectedFilters, setSelectedFilters] = useState(storedState && storedState.selectedFilters || { rows: [] })
  const [page, setPage] = useState(storedState && storedState.page || 1)
  const [perPage, setPerPage] = useState(storedState && storedState.perPage || 10)
  const [orderBy, setOrderBy] = useState(storedState && storedState.orderBy || '-datetime')

  // Function called by the filters component
  const genericDispatcher = (selectedFilters) => {
    setPage(1)
    setLocalStorage(localStateName, selectedFilters, 1, perPage, orderBy)
    setSelectedFilters(selectedFilters)
    getHeartbeats(selectedFilters, 1, perPage, orderBy);
  };
  // Function called by the table elements (page, per page, order actions)
  const callback = (page, rowsPerPage, orderBy) => {
    setPage(page)
    setPerPage(rowsPerPage)
    setOrderBy(orderBy)
    setLocalStorage(localStateName, selectedFilters, page, rowsPerPage, orderBy)
    getHeartbeats(selectedFilters, page, rowsPerPage, orderBy);
  }

  const getHeartbeats = (selectedFilters, page, rowsPerPage, orderBy) => {
    HeartbeatAPI.list({selectedFilters, page, rowsPerPage, orderBy}, true)
      .then((response) => {
        if (response.data !== null) {
          setTotalRows(response.total_rows)
          setAllMessageListRows(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getHeartbeats(selectedFilters, page, perPage, orderBy);
  }, []);

  return (
    <Box>
      <Typography component="h1" className="font-2rem">{t("home_lang_tab_monitor")}</Typography>
      <Box className="content_block">
        <Grid className="content_panel">
          <CustomFilterComponent
            allFilters={useFilters.monitoringListFilters}
            genericDispatcher={genericDispatcher}
            module_name={'Monitoring'}
            initData={selectedFilters || undefined}
          />
        </Grid>
        <Grid className="content_panel">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container alignItems="baseline" justifyContent="space-evenly">
              {Array.from(Array(4)).map((_, index) => (
                <Grid item key={index}>
                  {(() => {
                    switch (index) {
                      case 0:
                        return (
                          <Grid
                            container
                            spacing={1}
                          >
                            <Grid item>
                              <CardMedia
                                component="img"
                                image={RedIcon}
                                alt=""
                                className={classes.Image}
                              />
                            </Grid>
                            <Grid item>
                              <Typography component="p" className={classes.p} >
                                {t("monitoring_more10days")}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      case 1:
                        return (
                          <Grid
                            container
                            spacing={1}
                          >
                            <Grid item>
                              <CardMedia
                                component="img"
                                image={AmberIcon}
                                alt=""
                                className={classes.Image}
                              />
                            </Grid>
                            <Grid item>
                            <Typography component="p" className={classes.p}>
                              {t("monitoring_less10days")}
                            </Typography>
                            </Grid>
                          </Grid>
                        );
                      case 2:
                        return (
                          <Grid
                            container
                            spacing={1}
                          >
                            <Grid item>
                              <CardMedia
                                component="img"
                                image={GreenIcon}
                                alt=""
                                className={classes.Image}
                              />
                            </Grid>
                            <Grid item>
                            <Typography component="p" className={classes.p}>
                              {t("monitoring_less4days")}
                            </Typography>
                            </Grid>
                          </Grid>
                        );
                      default:
                        return (
                          <Grid
                          container
                          spacing={1}
                        >
                          <Grid item>
                            <CardMedia
                              component="img"
                              image={GrayIcon}
                              alt=""
                              className={classes.Image}
                            />
                          </Grid>
                          <Grid item>
                          <Typography component="p" className={classes.p}>
                            {t("monitoring_nodays")}
                          </Typography>
                          </Grid>
                        </Grid>
                        );
                    }
                  })()}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <EnhancedDataGridTableComponent
          storedState={storedState}
          allListRows={allMessageRows}
          headCells={headCells}
          flagID={4}
          totalRows={totalRows}
          callback={callback}
          page={page}
          setPage={setPage}
        />
      </Box>
    </Box>
  );
};

//Styles Area
const useStyle = makeStyles({
  Image: {
    "&.MuiCardMedia-root": {
      width: "20px",
      height: "20px"
    },
  },
  Icon: {
    "&.MuiSvgIcon-root": {
      color: "red",
    },
  },
  p: {
    width: "100%",
    "&.MuiTypography-root": {
    },
  },
});

export default MonitoringListComponent;
