import { api } from "./config/axiosConfig"
import { defineCancelApiObject } from "./config/axiosUtils"

export const CommentAPI = {
    list: async function ({ id, selectedFilters, page, rowsPerPage, orderBy }, cancel = false) {
        let url = `message/comment/${id}/?`
        url += page ? `page=${page}&` : ''
        url += rowsPerPage ? `per_page=${rowsPerPage}&` : ''
        url += orderBy ? `order_by=${orderBy}&` : ''
        url += `filters=${JSON.stringify(selectedFilters)}`
        const response = await api.request({
            url: url,
            method: "GET",
            signal: cancel ? cancelApiObject[this.list.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    create: async function ({telegram_id, comment}, cancel = false) {
        const url = `message/comment/${telegram_id}/`
        const response = await api.request({
            url: url,
            method: "POST",
            data: {
                comment
            },
            signal: cancel ? cancelApiObject[this.list.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

const cancelApiObject = defineCancelApiObject(CommentAPI)