import React, { useState, Fragment, useEffect } from "react";
// Language files
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Link as Link1,
  AppBar,
  Tabs,
  Tab,
  Typography,
  CardMedia,
} from "@mui/material";
import PropTypes from "prop-types";
// Image file path_location
import CustomerLogo from "../../static/images/logo_sncf.png";
import FooterImage from "../../static/images/fy_logo.png";
import CFFLogo from '../../static/images/logo_SBB_CFF_FFS.png';
// Call other Components
import PreferenceComponent from "../common/Preference";
import ChangePasswordComponent from "../common/ChangePassword";
import Management from "../management/index";
import Messages from "../message/Messages";
import Admin from "../admin/Admin";
import MonitoringListComponent from "../monitoring/MonitoringList";
import JournalListComponent from "../journal/JournalList";
import { Route, Switch, Link, useHistory, useLocation } from "react-router-dom";
import { ConfigAPI } from "../../apis/ConfigAPI";
import GearsComponent from "../gear/Gears";
import { openNewWindow } from "../../helpers/helpers";


const HomePageComponent = (props) => {
  const _location = useLocation();
  // call styles for controls
  const classes = useStyle();
  const config = JSON.parse(localStorage.getItem('configOLM'))
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [showModal, setShowModal] = useState(false);
  const handlePreferenceWindow = () => {
    setShowModal(true);
  };
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const handleChangePassword = (e) => {
    setShowChangePasswordModal(true);
  };
  // translate languange call
  const { t } = useTranslation();
 
  // call logout function
  const handlelogOut = () => {
    localStorage.removeItem('user')
    _history.push("/");
  };
  const [open, setOpen] = useState(true);
  const [lang, setLang] = useState("");
  const handleSaveChanges = (e) => {
    setLang(t("msg_PreferenceSaveChanges"));
    setTimeout(function () {
      setOpen(false);
    }, 1000);
    window.location.reload();
  };
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
 
  const handleTabChange = (e)=> {
    ConfigAPI.get()
    try {
      const url = e.target.href.split(`${process.env.REACT_APP_URL}`);
      setSelectedURL(url.join(''));
    } catch(err) {
      const url = window.location.href.split(`${process.env.REACT_APP_URL}`);
      setSelectedURL(url.join(''));
    }
  }
  const routes = [
    "/gears",
    "/telegrams",
    "/monitoring",
    "/journal",
    "/admin",
    "/configuration"
  ];
  const [selectedURL, setSelectedURL] = React.useState(
                _location.pathname ? _location.pathname : '/gears')
  const [ locationKeys, setLocationKeys ] = useState([])
  const _history = useHistory()
  useEffect(() => {
    return _history.listen(location => {
      if (_history.action === 'PUSH') {
        setLocationKeys([ location.key ]);
        setSelectedURL(location.pathname);
      }
 
      if (_history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)
          setSelectedURL(location.pathname);
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
          setSelectedURL(location.pathname); 
        }
      }
    })
  }, [ locationKeys, ]);

  const changeUrl = (url) => {
    setSelectedURL(url);
  }

  return (
    <Grid className="">
      <Grid className="head">
        <Grid container sx={{alignItems: 'stretch'}}>
          <Grid xs={'auto'} container sx={{alignItems: 'end'}}>
            <CardMedia
              component="img"
              image={config.customer_logo || CustomerLogo}
              alt=""
              className="logo card"
            />
          </Grid>
          <Grid container xs={'auto'} sx={{alignItems: 'end'}}>
            <Box className="p-l-20">
                <Route
                    path={selectedURL}
                    render={(history) => (
                    <AppBar className="relative_position bg-white b-shadow-none">
                        <Tabs
                            data-testid="tabs"
                            value={
                                `/${history.location.pathname.split('/')[1]}`
                            }
                            sx={{minHeight: 'unset'}}
                            TabIndicatorProps={{style: {background:'#D0DCF9'}}}
                        >
                            <Tab
                                data-testid="engine"
                                value={routes[0]}
                                label={t("home_lang_tab_engins")}
                                component={Link}
                                to={routes[0]}
                                onClick={handleTabChange}
                                className="bg-blue c-white bdr-style-tab text-trans nav-tab"
                            />
                            <Tab
                                data-testid="message"
                                value={routes[1]}
                                label={t("home_lang_tab_msg")}
                                component={Link}
                                to={routes[1]}
                                onClick={handleTabChange}
                                className="m-l-7 bg-blue c-white bdr-style-tab text-trans nav-tab"
                            />
                            <Tab
                                data-testid="monitor"
                                value={routes[2]}
                                label={t("home_lang_tab_monitor")}
                                component={Link}
                                to={routes[2]}
                                onClick={handleTabChange}
                                className="m-l-7 bg-blue c-white bdr-style-tab text-trans nav-tab"
                            />
                            <Tab       
                                data-testid="journal"      
                                value={routes[3]}  
                                label={t("home_lang_tab_journal")} 
                                component={Link}   
                                to={routes[3]}     
                                onClick={handleTabChange}
                                className="m-l-7 bg-blue c-white bdr-style-tab text-trans nav-tab"   
                            />
                            {(currentUser && currentUser.role && currentUser.role.length
                            && (currentUser.role.includes('Admin') || currentUser.role.includes('Configurator') || currentUser.superuser)) && <Tab  
                                data-testid="admin"
                                value={routes[4]}  
                                label={t("home_lang_tab_admin")}   
                                component={Link}   
                                to={routes[4]}     
                                onClick={handleTabChange}
                                className="m-l-7 bg-blue c-white bdr-style-tab text-trans nav-tab"  
                            />}
                            {currentUser && currentUser.superuser && <Tab      
                                value={routes[5]}      
                                label={t("configuration")}
                                component={Link}       
                                to={routes[5]}
                                onClick={handleTabChange}
                                className="m-l-7 bg-blue c-white bdr-style-tab text-trans nav-tab"
                            />}
                        </Tabs>
                    </AppBar>
                )}
                />
            </Box>
          </Grid>
          <Grid xs={true}>
            <Box className="align_right p-r-10 left-menu-block">             
              <span className="header_logout">
                <Box component="span" className="caps_username">
                  {currentUser.superuser ? 'Faiveley Administrator' : `${currentUser.first_name} ${currentUser.last_name}`}
                </Box>
                <Box component="span" className="user_panel_separator">
                  |
                </Box>
                <Link1 className={classes.Link} onClick={handlelogOut} data-testid="logout">
                  {t("home_lang_logout")}
                </Link1>
                <Box component="span" className="user_panel_separator">
                  |
                </Box>
                <Link1 className={classes.Link} onClick={handlePreferenceWindow}>
                  {t("home_lang_pref")}
                </Link1>
                <Box component="span" className="user_panel_separator">
                  |
                </Box>
                <Link1 className={classes.Link} target="_blank">
                  {t("home_lang_help")}
                </Link1>
              </span>
              <CardMedia
                component="img"
                image={config.infra_manag_logo || CFFLogo}
                alt=""
                className="logo card1 "
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} className="td_bar">&nbsp;</Grid>
      </Grid>
      <Grid className="body">
        <Grid container>
          <Grid xs={12}>
          <Switch>
            <Route path="/gears" {...props} component={(props)=><GearsComponent {...props} handleTabChange={handleTabChange} changeUrl={changeUrl}/>} />
            <Route path="/telegrams" {...props} component={Messages} />
            <Route path="/monitoring" {...props} component={MonitoringListComponent} />
            <Route path="/journal" {...props} component={JournalListComponent} />
            <Route path="/configuration" {...props} component={Management} />
            <Route path="/admin"><Admin /></Route>
          </Switch>
          </Grid>
        </Grid>
      </Grid>
               
      {/* Footer Section */}
      <Grid className="home_footer">
        <Grid container>
          <Grid item xs={2}>
            <CardMedia component="img" image={FooterImage} alt="" />
          </Grid>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12}>
                <Typography component="p" className={classes.p}>
                  {t("VERSION")} {process.env.REACT_APP_OLM_VERSION} | <Link1 className={classes.Link} data-testid="logout" onClick={() => openNewWindow(`/about/`, 800, 600)}>{t('about')}</Link1>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Preference Language Window */}
      {showModal && (
        <PreferenceComponent
          handleChangePassword={handleChangePassword}
          handleSaveChanges={handleSaveChanges}
          langVal={lang}
          open={open}
          handleClose={handleClose}
        />
      )}
        {showChangePasswordModal && <ChangePasswordComponent />}
    </Grid>
  );
};

// Tab Section Area
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Grid>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


//Styles Area
const useStyle = makeStyles({
  AppBar: {
    "&.MuiPaper-root": {
      position: "relative !important",
      top: "20px !important",
    }
  },
  Link: {
    "&.MuiTypography-root": {
      color: "#1976d2",
      fontWeight: "bold",
      textDecoration: "none",
      "&:hover": {
        color: "#FFAB19",
        fontWeight: "bold",
        cursor: "pointer",
      },
    },
  },
  tabs: {
    width: "600px",
    borderRadius: "10px 10px 0px 0px",
    marginLeft: "10px",
    marginTop: "15px",
    "& .MuiTab-root": {
      display: "block",
      color: "white",
      backgroundColor: "#2F3D92",
      margin: "5px 5px 0px 0px",
      borderRadius: "6px 6px 0px 0px",
      fontWeight: "bold",
      textTransform: "capitalize",
      "&:hover": {
        color: "#FFAB19 !important",
      },
    },
    "& .Mui-selected": {
      color: "#2F3D92 !important",
      backgroundColor: "#D0DCF9",
      fontWeight: "bold",
    },
  },
  p: {
    "&.MuiTypography-root": {
      position: 'relative',
      padding: '5px',
      float: 'right',
    },
  },
  Image: {
    "&.MuiCardMedia-root": {
      width: "1%",
    },
  },
});

export default HomePageComponent;