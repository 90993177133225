// React and Redux declarations
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import GearsListComponent from "./GearsList";
import GearComponent from "./Gear";

const GearsComponent = (props) => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <GearsListComponent />
            </Route>
            <Route exact path={`${path}/:gear_id`}>
                <GearComponent />
            </Route>
        </Switch>
    );
};

export default GearsComponent;