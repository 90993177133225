// React and Redux declarations
import React, { Fragment } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import TelegramListComponent from "./TelegramList";
import TelegramDetailsComponent from "./TelegramDetails";

const MessagesComponent = (props) => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <TelegramListComponent />
            </Route>
            <Route exact path={`${path}/:telegram_id`}>
                <TelegramDetailsComponent />
            </Route>
        </Switch>
    );
};

export default MessagesComponent;