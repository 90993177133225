// React and Redux declarations
import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { Grid, Box, Button, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import BackButton from "../common/BackButton";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { TelegramAPI } from "../../apis/TelegramAPI";
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import 'moment-timezone';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                
                    <>{children}</>
                
            )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TelegramDetailsComponent = () => {
    const classes = useStyle();
    const { t } = useTranslation();
    const params = useParams();

    const [telegram, setTelegram] = useState()

    // Get user data
    useEffect(() => {
        TelegramAPI.get({telegram_id: params.telegram_id}, true).then((response) => {
            if (response.data !== null) {
                setTelegram(response.data)
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    // New Window Open
    const openNewWindow = () => {
        popup(`/telegrams/comments/${telegram.id}`, 800, 300);
    };

    const [downloadError, setDownloadError] = useState(false)

    const dwTelegram = (id) => {
        TelegramAPI.download(id, true).then(
            (response) => {
                console.log(response)
                if (response !== undefined) {
                    const file = new Blob([response], {type: 'application/xml'});
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = telegram.name;
                    link.click();
                } else {
                    setDownloadError(true)
                    console.error('No download')
                }
            }
        )
    }
    const [tab, setTab] = useState(0)

    const handleChange = (event, newValue) => {
        setTab(newValue);
      };

    return (
        telegram ? <Box>
            <Typography component="h1" className="font-2rem">
                {t("message_details")} {telegram.id}
            </Typography>
            <Grid className="action_panel">
                {downloadError && (
                    <Typography component="p" className="error_msg">
                        {t('download_failure')}
                    </Typography>
                )}
                {!telegram.deleted && (
                <Button
                    type="button"
                    className={classes.button}
                    onClick={() => dwTelegram(telegram.id)}
                >
                    {t("user_download_xml_file")}
                </Button>
                )}
                <Button
                    type="button"
                    onClick={openNewWindow}
                    className={classes.button}
                    data-testid="comment"
                >
                    {t("comments")}
                </Button>
            </Grid>
            <Grid className="content_panel">
                <Typography component="h1">{t("adduser_h1_info")}</Typography>
                <Grid container>
                    <Grid item xs={5}>
                        {/* 1 */}
                        <Grid container className='min_height p_10'>
                            <Grid item xs={5} >
                                <b>{t("message_telegram")}</b>
                            </Grid>
                            <Grid item xs={7}>
                                {telegram.id}
                            </Grid>
                        </Grid>
                        {/* 2 */}
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={5} >
                                <b>{t("message_dt_receipt")}</b>
                            </Grid>
                            <Grid item xs={7}><Moment format="DD/MM/YYYY HH:mm:ss">{telegram.received_on}</Moment></Grid>
                        </Grid>
                        {/* 3 */}
                        <Grid container className="min_height p_10">
                            <Grid item xs={5} >
                                <b>{t("sncf_number")}</b>
                            </Grid>
                            <Grid item xs={7}>{telegram.gear && telegram.gear.sncf_number}</Grid>
                        </Grid>
                        <Grid container className="min_height bg_color p_10">
                            <Grid item xs={5} >
                                <b>{t("uic_id")}</b>
                            </Grid>
                            <Grid item xs={7}>{telegram.gear && telegram.gear.uic_id}</Grid>
                        </Grid>
                        <Grid container className="min_height p_10">
                            <Grid item xs={5} >
                                <b>{t("trainset_number")}</b>
                            </Grid>
                            <Grid item xs={7}>{telegram.gear && telegram.gear.trainset_number}</Grid>
                        </Grid>
                        <Grid container className="min_height bg_color p_10">
                            <Grid item xs={5} >
                                <b>{t("sent")}</b>
                            </Grid>
                            <Grid item xs={7}>
                                {
                                    telegram.sent ? <SendIcon color="success" /> : <ClearIcon color="error" />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        (telegram.messages.length > 0) && <Grid item xs={5} style={{paddingLeft: '20px'}}>
                            <Grid container className='min_height p_10'>
                                <Grid item xs={5} >
                                    <b>{t("version")}</b>
                                </Grid>
                                <Grid item xs={7}>
                                    {telegram.messages[0].version}
                                </Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={5} >
                                    <b>{t("telegram_details_train_no")}</b>
                                </Grid>
                                <Grid item xs={7}>{telegram.messages[0].train_number}</Grid>
                            </Grid>
                            <Grid container className="min_height p_10">
                                <Grid item xs={5} >
                                    <b>{t("message_serial")}</b>
                                </Grid>
                                <Grid item xs={7}>{telegram.messages[0].line_number}</Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={5} >
                                    <b>{t("message_engine_number")}</b>
                                </Grid>
                                <Grid item xs={7}>{telegram.messages[0].vehicle_number}</Grid>
                            </Grid>
                            <Grid container className="min_height p_10">
                                <Grid item xs={5} >
                                    <b>{t("message_cabin")}</b>
                                </Grid>
                                <Grid item xs={7}>{telegram.messages[0].cab}</Grid>
                            </Grid>
                            <Grid container className='min_height bg_color p_10'>
                                <Grid item xs={5} >
                                    <b>{t("message_timestamp")}</b>
                                </Grid>
                                <Grid item xs={7}>
                                    {
                                        _.map(telegram.messages, (msg, i) => <div>
                                            {`Message ${i + 1}`} - <Moment format="DD/MM/YYYY HH:mm:ss" tz="Europe/Paris">{msg.timestamp}</Moment>
                                        </div>)
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {
                (telegram.messages.length > 0) && <Box>
                    <Tabs
                        className={`${classes.tabs} admin-nav`}
                        TabIndicatorProps={{ style: { display: "none" } }}
                        value={tab}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {
                            _.map(telegram.messages, (msg, i) => {
                                return <Tab
                                    key={`msg_tab_${i}`}
                                    label={`Message ${i + 1}`}
                                    className="bg-blue c-white bdr-style-tab text-trans nav-tab"
                                    {...a11yProps(i)}
                                ></Tab>
                            })
                        }
                    </Tabs>
                    <Box className="box_tabs"></Box>
                    <Box className="frame_border">
                        {
                            _.map(telegram.messages, (msg, i) => {
                                return <TabPanel
                                    value={tab}
                                    index={i}
                                    key={`msg_tabpanel_${i}`}
                                >
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Grid container className="min_height p_10">
                                                <Grid item xs={5} >
                                                    <b>{t("message_data_id")}</b>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    {msg.msg_type}
                                                </Grid>
                                            </Grid>
                                            <Grid container className='min_height bg_color p_10'>
                                                <Grid item xs={5} >
                                                    <b>{t("message_time")}</b>
                                                </Grid>
                                                <Grid item xs={7}>{msg.hour}</Grid>
                                            </Grid>
                                            <Grid container className="min_height p_10">
                                                <Grid item xs={5} >
                                                    <b>{t("message_minut")}</b>
                                                </Grid>
                                                <Grid item xs={7}>{msg.minute}</Grid>
                                            </Grid>
                                            <Grid container className='min_height bg_color p_10'>
                                                <Grid item xs={5} >
                                                    <b>{t("message_nidc")}</b>
                                                </Grid>
                                                <Grid item xs={7}>{msg.nid_c || t('nid_c_x')}</Grid>
                                            </Grid>
                                            <Grid container className="min_height p_10">
                                                <Grid item xs={5} >
                                                    <b>{t("message_nidbg")}</b>
                                                </Grid>
                                                <Grid item xs={7}>{msg.nid_bg}</Grid>
                                            </Grid>
                                            {
                                                msg.msg_type === 'STOEST' ?
                                                    <Grid container className='min_height bg_color p_10'>
                                                        <Grid item xs={5} >
                                                        <b>GK</b>
                                                        </Grid>
                                                        <Grid item xs={7}>{msg.marker_gk}</Grid>
                                                    </Grid>
                                                :
                                                    null
                                            }
                                            <Grid container className={msg.msg_type === 'STOEST' ? 'min_height p_10' : 'min_height bg_color p_10'}>
                                                <Grid item xs={5} >
                                                    <b>{t("message_err_no")}</b>
                                                </Grid>
                                                <Grid item xs={7}>{msg.error_number}</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            })
                        }
                    </Box>
                </Box>
            }
            <BackButton url={'/telegrams'} text={t("messages_back")} />
        </Box> : null
    );
};

//Styles Area
const useStyle = makeStyles({
    Link: {
        "&:hover": {
            textDecoration: "none",
        },
    },
    Image: {
        "&.MuiCardMedia-root": {
            width: "20px",
            height: "20px",
        },
    },
    Box: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        "&.MuiButton-root": {
            backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
            color: "black",
            fontWeight: "bold",
            border: "1px solid",
            borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
            marginRight: "5px",
            width: "auto"
        },
    },
    tabs: {
        width: "100%",
        borderRadius: "10px 10px 0px 0px",
        "& .MuiTab-root": {
            whiteSpace: "nowrap",
            display: "block",
            color: "white",
            backgroundColor: "#2F3D92",
            margin: "5px 5px 0px 0px",
            borderRadius: "6px 6px 0px 0px",
            textTransform: "capitalize",
            fontWeight: "bold",
            "&:hover": {
                color: "#FFAB19 !important",
            },
            "&:first-child": {
                marginLeft: '0 !important',
            }
        },
        "& .Mui-selected": {
            color: "#2F3D92 !important",
            backgroundColor: "#D0DCF9",
            fontWeight: "bold",
        },
        "& .MuiTabs-scrollButtons.Mui-disabled": {
            opacity: .3
        }
    },
});

/*
 * Opens a popup window and shows given page within
 */
function popup(target, width, height) {
    let top = (window.screen.height - height) / 2;
    let left = (window.screen.width - width) / 2;
    window.open(
        target,
        "_blank",
        "width=" +
        width +
        ",height=" +
        height +
        ",toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=1,left=" +
        left +
        ",top=" +
        top
    );
}

export default TelegramDetailsComponent;
