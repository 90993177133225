// React and Redux declarations
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import { Grid, Box, Typography } from "@mui/material";
import EnhancedDataGridTableComponent from "../common/EnhancedDataGridTable";
import CustomFilterComponent from "../common/CustomFilter";
import useFilters from "../common/CommonFilters";
import { setLocalStorage, getLocalStorage } from "../common/LocalStorage";
import { LogAPI } from "../../apis/LogAPI";

const JournalListComponent = () => {
  const { t } = useTranslation();

  // Load All Message list
  const [allJournalRows, setAllJournalListRows] = useState([]);
  const [filters, setFilters] = useState(null)

  const localStateName = 'logListState'
  const storedState = getLocalStorage(localStateName);

  // Items for the backend pagination system
  const [totalRows, setTotalRows] = useState(0)
  const [selectedFilters, setSelectedFilters] = useState(storedState && storedState.selectedFilters || { rows: [] })
  const [page, setPage] = useState(storedState && storedState.page || 1)
  const [perPage, setPerPage] = useState(storedState && storedState.perPage || 10)
  const [orderBy, setOrderBy] = useState(storedState && storedState.orderBy || '-id')
  // Function called by the filters component
  const genericDispatcher = (selectedFilters) => {
    setPage(1)
    setLocalStorage(localStateName, selectedFilters, 1, perPage, orderBy)
    setSelectedFilters(selectedFilters)
    getLogs(selectedFilters, 1, perPage, orderBy);
  };
  // Function called by the table elements (page, per page, order actions)
  const callback = (page, rowsPerPage, orderBy) => {
    setPage(page)
    setPerPage(rowsPerPage)
    setOrderBy(orderBy)
    setLocalStorage(localStateName, selectedFilters, page, rowsPerPage, orderBy)
    getLogs(selectedFilters, page, rowsPerPage, orderBy);
  }

  const getLogs = (selectedFilters, page, rowsPerPage, orderBy) => {
    LogAPI.list({selectedFilters, page, rowsPerPage, orderBy})
      .then((response) => {
        if (response.data !== null) {
          setTotalRows(response.total_rows)
          setAllJournalListRows(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getFilters = () => {
    LogAPI.filtersList()
      .then((response) => {
        if (response !== null) {
          const journalFilters = useFilters.journalFilters
          journalFilters.Message.options = _.map(response.data.messages, msg => {
            return {
              val: msg,
              name: msg
            }
          })
          journalFilters.equipment_id.options = _.map(response.data.equipments, equipment => {
            return {
              val: equipment,
              name: equipment.toUpperCase()
            }
          })
          journalFilters.Username.options = _.map(response.data.usernames, equipment => {
            return {
              val: equipment,
              name: equipment
            }
          })
          setFilters(journalFilters)
        } else {
          setFilters(useFilters.journalFilters)
        }
      })
      .catch((error) => {
        setFilters(useFilters.journalFilters)
        console.log(error);
      });
  };
  useEffect(() => {
    getFilters()
    getLogs(selectedFilters, page, perPage, orderBy);
  }, []);

  const headCells = [
    { id: "gravity", label: t("monitoring_status") },
    { id: "message", label: t("monitoring_msg") },
    { id: "gravity", label: t("monitoring_gravity") },
    { id: "date", label: t("monitoring_d_o_hours")},
    { id: "description", label: t("monitoring_description")},
  ];
  
  return (
    <Box>
      <Typography component="h1" className="font-2rem">{t("Journal")}</Typography>
      <Box className="content_block">
        <Grid className="content_panel">
          {filters && <CustomFilterComponent
            allFilters={filters}
            genericDispatcher={genericDispatcher}
            module_name={'Journal'}
            initData={selectedFilters || undefined}
          />}
        </Grid>
        <EnhancedDataGridTableComponent
          storedState={storedState}
          allListRows={allJournalRows}
          headCells={headCells}
          flagID={5}
          totalRows={totalRows}
          callback={callback}
          page={page}
          setPage={setPage}
        />
      </Box>
    </Box>
  );
};

export default JournalListComponent;
