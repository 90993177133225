// React and Redux declarations
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { CSVLink } from "react-csv";
// import form related actions and declarations
import { Grid, Box, Typography, Button } from "@mui/material";
import EnhancedDataGridTableComponent from "../common/EnhancedDataGridTable";
import CustomFilterComponent from "../common/CustomFilter";
import useFilters from "../common/CommonFilters";
import { setLocalStorage, getLocalStorage } from "../common/LocalStorage";
import { GearAPI } from "../../apis/GearAPI";

const GearsListComponent = (props) => {
  const { t } = useTranslation();
  // call styles for controls
  const classes = useStyle();
  // Load All Gears list
  const [allGearRows, setAllGearRows] = useState([]);
  const [allGearDownloadData, setAllGearDownloadData] = useState([]);

  const localStateName = 'gearListState'
  const storedState = getLocalStorage(localStateName);

  // Items for the backend pagination system
  const [totalRows, setTotalRows] = useState(0)
  const [selectedFilters, setSelectedFilters] = useState(storedState && storedState.selectedFilters || { rows: [] })
  const [page, setPage] = useState(storedState && storedState.page || 1)
  const [perPage, setPerPage] = useState(storedState && storedState.perPage || 10)
  const [orderBy, setOrderBy] = useState(storedState && storedState.orderBy || '-id')
  // Function called by the filters component
  const genericDispatcher = (selectedFilters) => {
    setPage(1)
    setLocalStorage(localStateName, selectedFilters, 1, perPage, orderBy)
    setSelectedFilters(selectedFilters)
    getGears(selectedFilters, 1, perPage, orderBy);
  };
  // Function called by the table elements (page, per page, order actions)
  const callback = (page, rowsPerPage, orderBy) => {
    setPage(page)
    setPerPage(rowsPerPage)
    setOrderBy(orderBy)
    setLocalStorage(localStateName, selectedFilters, page, rowsPerPage, orderBy)
    getGears(selectedFilters, page, rowsPerPage, orderBy);
  }
  const headCellswithoutAction = [
    { key: "equipment_id", label: t('header_lang_tab_engins'), id: 'equipment_id' },
    { key: "fleetname", label: t('config_fleet_name'), id: 'fleet__fleetname' },
    { key: "is_active", label: t('config_act_olm'), id: 'config__is_active' },
    { key: "nidc_value", label: t("nidc_ranges"), labelDesc: '[min ; max]', id: 'config__nidc_value' },
    { key: "gutMlowTh", label: t("config_lower_limit"), id: 'config__gutMlowTh' },
    { key: "gutMupTh", label: t("config_upper_limit"), id: 'config__gutMupTh' },
    { key: "msgNumber", label: t("config_nomsg"), id: 'config__msgNumber' },
    { key: "configFileVersion", label: t("cfg_version"), id: 'config__configFileVersion' },
    { key: "server", label: t("target_server"), id: 'config__server__server_type' },
    { key: "certificate_file", label: t("certificate_present"), id: 'config__server__certificate' },
    { key: "forcing", label: t("config_forcing"), id: 'config__forcing' },
  ];

  const getGears = (selectedFilters, page, rowsPerPage, orderBy) => {
    GearAPI.list({selectedFilters, page, rowsPerPage, orderBy}, true)
      .then((response) => {
        if (response.data !== null) {
          setTotalRows(response.total_rows)
          setAllGearRows(response.data);
          const data = response.data.map((data, id) => {
            return {
                "equipment_id": `• ${data.equipment_id}\n• ${data.uic_id}\n• ${data.sncf_number}`,
                "fleetname": data.fleet && data.fleet.fleetname,
                "is_active": data.config && data.config.is_active  ? 'true' : 'false',
                "nidc_value": data.config && data.config.nidc_value.map((data, index) => {
                  return `${++index}. ${t('lower')} = ${data['lower']}, ${t('upper')} = ${data['upper']}\n`
                }),
                "gutMlowTh": data.config && data.config.gutMlowTh,
                "gutMupTh": data.config && data.config.gutMupTh,
                "msgNumber": data.config && data.config.msgNumber,
                "configFileVersion": data.config && data.config.configFileVersion,
                "is_registered": data.is_registered,
                "server": (data.config && data.config.server && data.config.server 
                          ? (data.config.server.server_type === "0" || data.config.server.server_type === 0) 
                            ? t("flag2_integ_server") : t("flag2_prod_server"): ''),
                "serverLogin": data.config && data.config.server && data.config.server.user_name,
                "password": data.config && data.config.server && data.config.server.password,
                "certificate_file": data.config && data.config.server && data.config.server.certificate ? data.config.server.certificate.split('/')[data.config.server.certificate.split('/').length -1] : t('missing_certificate'),
                "forcing": data.config && data.config.forcing
            }
          });
          setAllGearDownloadData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getGears(selectedFilters, page, perPage, orderBy);
  }, []);

  // Delete the Gear
  const [isDelete, setDelete] = useState("");

  const handleDeleteGear = (rowItemID) => {
    let text = t("delete_vehicle_confirmation")
    if(confirm(text) == true) {
      GearAPI.delete(rowItemID).then((response) => {
        if (response !== undefined) {
          setDelete(response);
        }
        getGears(selectedFilters, page, perPage, orderBy);
      });
    }
  };

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const headCells = (
    (
      currentUser &&
      currentUser.role &&
      currentUser.role.length &&
      (currentUser.role.includes('Admin') || currentUser.role.includes('Configurator'))
    ) ? [{ key: 'Action', label: t('action'), id: 'action'}].concat(headCellswithoutAction) : [].concat(headCellswithoutAction))

  return (
    <Box>
        <Fragment>
          <Typography component="h1" className="font-2rem">{t("home_lang_tab_engins")}</Typography>
          <Grid className="action_panel">
            {Object.keys(allGearRows).length > 0 && (
              <CSVLink
                headers={([{ key: "is_registered", label: t("is_registered") }]).concat(headCellswithoutAction)}
                data={allGearDownloadData}
                className={classes.Link}
                separator=";"
                filename="exportEngins.csv"
              >
                <Button type="button" className={classes.button}>
                  {t("user_download_file")}
                </Button>
              </CSVLink>
            )}
          </Grid>
          <Box className="content_block" data-testid="gearlist">
            <Grid className="content_panel">
              <CustomFilterComponent
                allFilters={useFilters.gearListFilters}
                genericDispatcher={genericDispatcher}
                module_name={'Gears'}
                initData={selectedFilters || undefined}
              />
            </Grid>
            {isDelete !== "" && (
                <Typography component="p" className={isDelete['success'] ? "success_msg": "error_msg"}>
                  {isDelete.message}
                </Typography>
              )}
            <EnhancedDataGridTableComponent
              storedState={storedState}
              allListRows={allGearRows}
              headCells={headCells}
              flagID={2}
              handleDeleteGear={handleDeleteGear}
              changeURL={props.changeUrl}
              callback={callback}
              totalRows={totalRows}
              page={page}
              setPage={setPage}
            />
          </Box>
        </Fragment>
    </Box>
  );
};

//Styles Area
const useStyle = makeStyles({
  Link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  button: {
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 5%, #E2E9F9 70%)",
      color: "black",
      fontWeight: "bold",
    },
  },
});

export default GearsListComponent;