import { createTheme } from '@mui/material/styles'

export const olmTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'unset',
                },
            },
        },
    },
});