import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FooterImage from "../../static/images/fy_logo.png";

const About = () => {
    // call styles for controls
    const classes = useStyles();
    // Call i18n translate function
    const { t } = useTranslation();
    return (
    <Box className="wrapper">
        <Box className="content_panel">
            <Typography component="h1">{t("about_title")}</Typography>
            <Box className="content_panel">
                <Typography component="h1">{t("version_info")}</Typography>
                <Grid container>
                    <Grid xs={12}>
                        <span className={classes.Label}> {t("curr_ver")}:</span>
                        <span className={classes.value}> {process.env.REACT_APP_OLM_VERSION}</span>
                    </Grid>
                    <Grid xs={12}>
                        <span className={classes.Label}> {t("init_date")}:</span>
                        <span className={classes.value}> {process.env.REACT_APP_INSTALL_DATE || ''}</span>
                    </Grid>
                </Grid>
                <Box className="content_panel">
                <Typography component="h1">{t("ver_log")}</Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className={classes.versionBox}><b>2.0.11</b> - Corrections apportées aux évènements journalisés, aux filtrages, aux labels. Gestion des NID_C étrangers à la Suisse. Correction plage avec NID_C unique. Exclusivité des rôles utilisateurs. Correction sur la gestion de l'expiration des messages. Application de la vérification de disponibilité cyclique interface WEB CFF.</Box>
                        <Box className={classes.versionBox}><b>2.0.10</b> - Correction des filtres, de l'affichage des dates au format ISO et de traduction de labels ; gestion des requêtes WSN en erreur</Box>
                        <Box className={classes.versionBox}><b>2.0.9</b> - Ajout d'un timeout de 2s sur les requêtes WSN</Box>
                        <Box className={classes.versionBox}><b>2.0.8</b> - Retrait du numéro de flotte dans les requêtes WSN ; correction de l'envoi des télégrammes</Box>
                        <Box className={classes.versionBox}><b>2.0.7</b> - Gestion des réémissions des requêtes WSN</Box>
                        <Box className={classes.versionBox}><b>2.0.6</b> - Correction de la date de réception sur la page monitoring pour prendre en compte les deux interfaces MQTT et WSN ; correction des en-tête HTTP pour utiliser les bons identifiants engin</Box>
                        <Box className={classes.versionBox}><b>2.0.5</b> - Amélioration des erreurs affichées dans les formulaires et des traductions ; gestion du serveur d'identité SNCF ; correction du format du fichier de configuration ATESS</Box>
                        <Box className={classes.versionBox}><b>2.0.4</b> - Correction de la fonction de configuration</Box>
                        <Box className={classes.versionBox}><b>2.0.3</b> - Correction des échanges WSN : healthcheck et telediagnostic</Box>
                        <Box className={classes.versionBox}><b>2.0.2</b> - Intégration de l'interface de communication WSN</Box>
                        <Box className={classes.versionBox}><b>2.0.1</b> - Correction des entêtes de réponse MQTT</Box>
                        <Box className={classes.versionBox}><b>1.2.0</b> - Mise à jour des télégrammes, support avancé des échanges par MQTT, autres correctifs</Box>
                        <Box className={classes.versionBox}><b>1.1.1</b> - Révision de la version BL0102_v1.1.0</Box>
                        <Box className={classes.versionBox}><b>1.1.0</b> - Mises à jour de l'interface utilisateur. Améliorations du back-end. Corrections de bogues.</Box>
                        <Box className={classes.versionBox}><b>1.0.0</b> - Version initiale</Box>
                    </Grid>
                </Grid>
            </Box>
            </Box>
            <Box className="content_panel">
                <Typography component="h1">{t("links_n_downloads")}</Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <a href='https://www.wabteccorp.com/' target='_blank' className={classes.Link}>
                        {t("faively_links")}
                        </a>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Grid className="home_footer">
            <Grid container>
            <Grid item xs={2}>
                <CardMedia component="img" image={FooterImage} alt="" />
            </Grid>
            <Grid item xs={10}>
                <Grid container>
                <Grid item xs={12}>
                    <p component="p" className={classes.p}>
                        {t("about_footer_details")}
                    </p>
                </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    </Box>)
}

//Styles Area
const useStyles = makeStyles({
    button: {
      "&.MuiButton-root": {
        backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
        color: "black",
        textTransform: "capitalize",
        fontWeight: "bold",
        border: "1px solid",
        borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
        margin: "5px",
        width: "auto"
      },
    },
    hr: {
      "&.MuiTypography-root": {
        marginTop: "10px",
      },
    },
    Label: {
          color: "black",
          fontWeight: "bold",
          padding: "0px",
          lineHeight: "1.5em",
          position: "relative",
      },
      value: {
          color: "black",
          padding: "0px",
          lineHeight: "1.5em",
          position: "relative",
      },
      Link: {
          color: "#1976d2",
          fontWeight: "bold",
          textDecoration: "none",
          "&:hover": {
            color: "#FFAB19",
            fontWeight: "bold",
            cursor: "pointer",
        },
      },
      p: {
        position: "relative",
        top: "9px",
        textAlign: "right",
        color: "black",
        fontWeight: "bold"
      },
      versionBox: {
        marginBottom: '5px'
      }
  });

export default About;