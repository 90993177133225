import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.REACT_APP_URL}/api/v1/`.replace("undefined/", "");

const login = (username, password) => {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  return axios.post(API_URL + "users/login/", formData).then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("configOLM", JSON.stringify(response.data.config));
    }
    return response.data;
  });
};

const forgot = (username) => {
  let formData = new FormData();
  formData.append("username", username);

  return axios
    .post(API_URL + "users/forgot-password/", formData)
    .then((response) => {
      return response.data;
    });
};

const logout = (refreshToken) => {
  const formLogData = new FormData();
  formLogData.append("refresh", refreshToken);

  return axios
    .post(API_URL + "users/logout/", formLogData, { headers: authHeader() })
    .then((response) => {
      localStorage.setItem("userlogout", JSON.stringify(response.data));
      return response.data;
    });
};

const allUserList = (selectedFilters, page, rowsPerPage, orderBy) => {
  return axios
    .get(
      `${API_URL}users/allUserList/??page=${page}&per_page=${rowsPerPage}&order_by=${orderBy}&filters=${JSON.stringify(selectedFilters)}`,
      { headers: authHeader() }
    )
    .then((response) => {
      localStorage.setItem("allUserList", JSON.stringify(response.data));
      return response.data;
    });
};

const changePassword = (oldPassword, newPassword, confirmPassword) => {
  const formCData = new FormData();
  formCData.append("old_password", oldPassword);
  formCData.append("new_password", newPassword);
  formCData.append("confirm_password", confirmPassword);

  return axios
    .put(API_URL + "users/change-password/", formCData, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const updateConfig = (data) => {
  const formCData = new FormData();
  
  Object.entries(data).forEach(element => {
    formCData.append(element[0], element[1]);
  });

  return axios
    .put(API_URL + "management/update_config/", formCData, {
      headers: authHeader(),
    })
    .then((response) => {
      localStorage.setItem("configOLM", JSON.stringify(response.data.config));
      return response.data;
    });
};

const allrolelist = () => {
  return axios
    .get(API_URL + "users/role_list/", { headers: authHeader() })
    .then((response) => {
      localStorage.setItem("allrolelist", JSON.stringify(response.data));
      return response.data;
    });
};

const getUser = (user_id) => {
  return axios
    .get(
      API_URL + `users/user/${user_id}/`,
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};

const userUpdate = (
  first_name,
  last_name,
  email,
  role,
  is_active,
  user_id
) => {
  let formRData = new FormData();
  formRData.append("first_name", first_name);
  formRData.append("last_name", last_name);
  formRData.append("email", email);
  role.map(id => {
    formRData.append("role", id);
  });
  formRData.append("is_active", is_active);

  return axios
    .put(API_URL + `users/userUpdateAPI/${user_id}/`, formRData, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const userDelete = (rowID) => {
  return axios
    .delete(API_URL + "users/userDeleteAPI/?pk_ids=" + rowID, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const register = (
  username,
  first_name,
  last_name,
  email,
  role,
  is_active
) => {
  let formRData = new FormData();
  formRData.append("username", username);
  formRData.append("first_name", first_name);
   formRData.append("last_name", last_name);
  formRData.append("email", email);
  role.map(id => {
    formRData.append("role", id);
  });
  formRData.append("is_active", is_active);
  
  return axios
    .post(API_URL + "users/register/", formRData, { headers: authHeader() })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const createFleet = (txtFleetName, txtSoftIdNo, txtVersion, fleet_id) => {
  let formRData = new FormData();
  formRData.append("fleetname", txtFleetName);
  formRData.append("sw_identification_number", txtSoftIdNo);
  formRData.append("version", txtVersion);
  if (isNaN(fleet_id)) {
    return axios
      .post(API_URL + "fleets/fleet/", formRData, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
    } else {
      return axios
      .put(API_URL + `fleets/fleet/${fleet_id == undefined && parseInt(fleet_id) != NaN? '' : fleet_id}/`, formRData, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
    });
    }
};

const getFleetlist = (selectedFilters, fleet_id, page, rowsPerPage, orderBy) => {
  let url = `${API_URL}fleets/getFleetlistAPI/`
  url += (fleet_id == undefined && parseInt(fleet_id) != NaN) ? '' : `${fleet_id}/`
  url += `?`
  url += page ? `page=${page}&` : ''
  url += rowsPerPage ? `per_page=${rowsPerPage}&` : ''
  url += orderBy ? `order_by=${orderBy}&` : ''
  url += `filters=${JSON.stringify(selectedFilters)}`
  return axios
    .get(
      url,
      { headers: authHeader() }
    )
    .then((response) => {
      localStorage.setItem("getFleetlist", JSON.stringify(response.data));
      return response.data;
    });
};

const getGearlist = (selectedFilters, fleet_id, gear_id, page, rowsPerPage, orderBy) => {
  let url = `${API_URL}gears/gearList/?`
  url += fleet_id ? `fleet_id=${fleet_id}&` : ''
  url += gear_id ? `gear_id=${gear_id}&` : ''
  url += page ? `page=${page}&` : ''
  url += rowsPerPage ? `per_page=${rowsPerPage}&` : ''
  url += orderBy ? `order_by=${orderBy}&` : ''
  url += `filters=${JSON.stringify(selectedFilters)}`
  return axios
    .get(
      url,
      { headers: authHeader() }
    )
    .then((response) => {
      localStorage.setItem("getGearlist", JSON.stringify(response.data));
      return response.data;
    });
};

const getConfig = (selectedFilters) => {
  return axios
    .get(
      API_URL + "management/config/",
      { headers: authHeader() }
    )
    .then((response) => {
      localStorage.setItem("configOLM", JSON.stringify(response.data));
      return response.data;
    });
};

const createGear = (
  equipment_id,
  uic_id,
  sncf_number,
  enginType,
  selectedValue
) => {
  let formGData = new FormData();
  formGData.append("equipment_id", equipment_id);
  formGData.append("uic_id", uic_id);
  formGData.append("sncf_number", sncf_number);
  formGData.append("_type", enginType);
  formGData.append("fleet", selectedValue);

  return axios
    .post(API_URL + "gears/createGearAPI/", formGData, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const gearDelete = (rowID) => {
  return axios
    .delete(API_URL + "gears/gear_delete/?pk_ids=" + rowID, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const createConfigureOLM = (
  equipment_id,
  uic_id,
  sncf_number,
  is_registered,
  is_active,
  fleet,
  nidc_value,
  gutMlowTh,
  gutMupTh,
  msgNumber,
  configFileVersion,
  forcing,
  server_type,
  config_file_name,
  gear_id
) => {
  let formGData = new FormData();
  formGData.append("equipment_id", equipment_id);
  formGData.append("uic_id", uic_id);
  formGData.append("sncf_number", sncf_number);
  formGData.append("is_registered", is_registered);
  formGData.append("is_active", is_active);
  formGData.append("fleet", fleet);
  nidc_value.map(data => {
    formGData.append("nidc_value", JSON.stringify(data));
  });
  formGData.append("gutMlowTh", gutMlowTh);
  formGData.append("gutMupTh", gutMupTh);
  formGData.append("msgNumber", msgNumber);
  formGData.append("configFileVersion", configFileVersion);
  formGData.append("forcing", forcing);
  formGData.append("server_type", server_type);
  formGData.append("config_file_name", config_file_name);

  if (isNaN(gear_id)){
    return axios
    .post(API_URL + "configuration/createConfigurationAPI/", formGData, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  } else {
    return axios
    .put(API_URL + `configuration/createConfigurationAPI/${gear_id == undefined && parseInt(gear_id) != NaN? '' : gear_id}/`, formGData, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }
  
};

const getMessage = (message_id) => {
  return axios
    .get(
      API_URL + `message/message_details/${message_id}/`,
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};

const getMessagesList = (selectedFilters, page, rowsPerPage, orderBy) => {
  return axios
    .get(
      `${API_URL}message/MessagesListAPI/?page=${page}&per_page=${rowsPerPage}&order_by=${orderBy}&filters=${JSON.stringify(selectedFilters)}`,
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};

const getTelegram = (telegram_id) => {
  return axios
    .get(
      API_URL + `message/details/${telegram_id}/`,
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};

const getTelegramsList = (selectedFilters, page, rowsPerPage, orderBy) => {
  return axios
    .get(
      `${API_URL}message/telegrams/?page=${page}&per_page=${rowsPerPage}&order_by=${orderBy}&filters=${JSON.stringify(selectedFilters)}`,
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};

const getHeartbeatsList = (selectedFilters, page, rowsPerPage, orderBy) => {
  return axios
    .get(
      `${API_URL}message/HeartbeatsListAPI/?page=${page}&per_page=${rowsPerPage}&order_by=${orderBy}&filters=${JSON.stringify(selectedFilters)}`,
      { headers: authHeader() }
    )
    .then((response) => {
      localStorage.setItem("getHeartbeatsList", JSON.stringify(response.data));
      return response.data;
    });
};

const createComment = (txtComment, id) => {
  let formCData = new FormData();
  formCData.append("comment", txtComment);
  return axios
    .post(`${API_URL}message/comment/${id}/`, formCData, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const getCommentsList = (selectedFilters, id) => {
  return axios
    .get(
      API_URL +
        `message/comment/${id}?filters=` +
        JSON.stringify(selectedFilters),
      { headers: authHeader() }
    )
    .then((response) => {
      localStorage.setItem("getCommentsList", JSON.stringify(response.data));
      return response.data;
    });
};

const downloadTelegram = async (id) => {
  return axios
  .get(API_URL + `message/downloadTelegram/${id}/`, { headers: authHeader() })
  .then((response) => {
    return response.data;
  });
};


const server = async (
  serverLogin,
  serverPass,
  serverUrl,
  olmServer,
  certificate
) => {
  let formGData = new FormData();
  formGData.append("user_name", serverLogin);
  formGData.append("password", serverPass);
  formGData.append("server_url", serverUrl);
  if (certificate) {
    formGData.append("certificate", certificate);
  }
  formGData.append("server_type", olmServer);

  return axios
    .post(API_URL + `configuration/fileUploadingAPI/${olmServer}/`, formGData, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const getServerDetails = async (server) => {
  return axios
  .get(API_URL + `configuration/fileUploadingAPI/${server == '0' || server== '1' ? server : ''}/`, { headers: authHeader() })
  .then((response) => {
    localStorage.setItem("uploadedFiles", JSON.stringify(response.data));
    return response.data;
  });
};

const getJournalList = (selectedFilters, page, rowsPerPage, orderBy) => {
  let url = `${API_URL}journal/journal/?page=${page}&per_page=${rowsPerPage}&order_by=${orderBy}&filters=${JSON.stringify(selectedFilters)}&lang=${localStorage.getItem('language') ? localStorage.getItem('language') : 'fr'}`
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      localStorage.setItem("getJournalList", JSON.stringify(response.data));
      return response.data;
    });
};

const messageDelete = (rowID) => {
  return axios
    .delete(API_URL + `message/message_delete/?pk_ids=${rowID}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};

const telegramDelete = (rowID) => {
  return axios
    .delete(API_URL + `message/telegram_delete/?pk_ids=${rowID}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    });
};


const createFilter = (
  name,
  module_name,
  _filters
) => {
  
  let formGData = new FormData();
  formGData.append("name", name);
  formGData.append("module_name", module_name);
  formGData.append("_filters", JSON.stringify(_filters));

  return axios
    .post(API_URL + "fleets/filter/", formGData, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("filter", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const updateFilter = (
  name,
  module_name,
  _filters,
  id
) => {
  let formGData = new FormData();
  formGData.append("name", name);
  formGData.append("module_name", module_name);
  formGData.append("_filters", JSON.stringify(_filters));

  return axios
    .put(API_URL + `fleets/updateFilter/${id}/`, formGData, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("filter", JSON.stringify(response.data));
      }
      return response.data;
    });
};


const getFilterList = (
  module_name
) => {
  return axios
    .get(API_URL + `fleets/filter/?module_name=${module_name}`, { headers: authHeader() })
    .then((response) => {
      localStorage.setItem("getFilterList", JSON.stringify(response.data));
      return response.data;
    });
};

const deleteCertificate = (
  server_type
) => {
  return axios
    .delete(API_URL + `configuration/deleteCertificate/${server_type}`, { headers: authHeader() })
    .then((response) => {
      localStorage.setItem("deleteCertificate", JSON.stringify(response.data));
      return response.data;
    });
};

const authService = {
  register,
  login,
  logout,
  forgot,
  allUserList,
  changePassword,
  allrolelist,
  getUser,
  userUpdate,
  userDelete,
  createFleet,
  getFleetlist,
  getGearlist,
  getConfig,
  updateConfig,
  createGear,
  gearDelete,
  createConfigureOLM,
  getMessage,
  getMessagesList,
  getTelegram,
  getTelegramsList,
  getHeartbeatsList,
  messageDelete,
  telegramDelete,
  createComment,
  server,
  getJournalList,
  createFilter,
  updateFilter,
  getFilterList,
  getServerDetails,
  deleteCertificate,
  getCommentsList,
  downloadTelegram
};

export default authService;
