// React and Redux declarations
import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from '@mui/icons-material/Edit';
import Moment from "react-moment";
// import form related actions and declarations
import {
    Box,
    Grid,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TableContainer,
    Table,
    TableBody,
    TablePagination,
    Pagination,
    Typography,
    CardMedia,
    Checkbox,
    Button,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import InfoIcon from "../../static/images/event_info.png";
import WarnIcon from "../../static/images/event_warn.png";
//Call the Icon Images
import RedIcon from "../../static/images/red.png";
import AmberIcon from "../../static/images/orange.png";
import GreenIcon from "../../static/images/green.png";
import GrayIcon from "../../static/images/gray.png";
import usePagination from "./Pagination";
import { BrowserRouter as Router, useRouteMatch, Route, Link as Link1 } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import EditUsersComponent from "../admin/users/EditUser";

const EnhancedDataGridTableComponent = (props) => {
    const { path } = useRouteMatch();
    // call styles for controls
    const classes = useStyle();
    const { t } = useTranslation();
    // Pagination Start
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("id");
    // const [page, setPage] = useState(props.storedState && props.storedState.page || 1);
    const [ord, setOrd] = useState(props.storedState && props.storedState.orderBy || '-id');
    const [rowsPerPage, setRowsPerPage] = useState(props.storedState && props.storedState.perPage || 10);
    const ActionComponentDisabled = () => <span />;
    // select page Options
    const selectedPage = [10, 20, 50, 100];
    const createSortHandler = (property) => () => {
        const isAsc = orderBy === property && order === "asc";
        const orderByDir = isAsc ? `-${property}` : property
        props.setPage(1)
        props.callback(1, rowsPerPage, orderByDir)
        setOrd(orderByDir)
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const count = Math.ceil(props.totalRows / rowsPerPage);
    const _DATA = usePagination(props.allListRows, rowsPerPage);
    const handleChangePage = (event, newPage) => {
        props.callback(newPage, rowsPerPage, ord)
        props.setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        props.callback(props.page, event.target.value, ord)
        setRowsPerPage(event.target.value);
    };
    // Checkbox Bulk Delete
    const [checkedList, setCheckedList] = useState([]);
    const [itemsChecked, setItemsChecked] = useState(false);
    const handleCheckboxClick = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckedList([...checkedList, value * 1]);
            setItemsChecked(checked);
        } else {
            setCheckedList(checkedList.filter((item) => item != value));
            setItemsChecked(checked);
        }
    };
    const selectItem = (e) => {
        const { checked } = e.target;
        const collection = [];
        if (checked) {
            for (const category of props.allListRows) {
                collection.push(category.id);
            }
        }
        setCheckedList(collection);
        setItemsChecked(checked);
    };
    
    const currentUser = JSON.parse(localStorage.getItem("user"));

    const removalOfsortingData = [
        t('action'),
        t("server_config_param"),
        t('description'),
        t("admin_download_roles")
    ];
  
    return (
        <Box>
            <TableContainer>
                {props.flagID === 0 && checkedList.length > 0 && (currentUser.superuser || currentUser.role.includes('Admin')) && (
                <Button
                    type="button"
                    className={classes.button}
                    onClick={() => props.handleDeleteUser(checkedList)}
                >
                    {t("Delete")}
                </Button>
                )}
                {props.flagID === 1 && checkedList.length > 0 && currentUser.superuser && (
                <Button
                    type="button"
                    className={classes.button}
                    onClick={() => props.handleDeleteFleet(checkedList)}
                >
                    {t("Delete")}
                </Button>
                )}
                {props.flagID === 2 && !props.fleetList && checkedList.length > 0 && (currentUser.superuser || currentUser.role.includes('Admin') || currentUser.role.includes('Configurator')) && (
                <Button
                    type="button"
                    className={classes.button}
                    onClick={() => props.handleDeleteGear(checkedList)}
                >
                    {t("Delete")}
                </Button>
                )}
                {props.flagID === 3 && checkedList.length > 0 && currentUser.superuser && (
                <Button
                    type="button"
                    className={classes.button}
                    onClick={() => props.handleDeleteTelegram(checkedList)}
                >
                    {t("Delete")}
                </Button>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            {props.flagID === (0) && (currentUser && currentUser.role && currentUser.role.length	
                                    && (currentUser.superuser || currentUser.role.includes('Admin'))) && (
                                <TableCell align="center" padding="checkbox" className={classes.TableCheck}>
                                    <Checkbox
                                        indeterminate={
                                        checkedList.length > 0 &&
                                        checkedList.length < props.allListRows.length
                                        }
                                        checked={itemsChecked}
                                        onClick={(e) => selectItem(e)}
                                    />
                                </TableCell>
                            )}
                            {props.flagID === (1) && currentUser && currentUser.superuser && (
                                <TableCell align="center" padding="checkbox" className={classes.TableCheck}>
                                    <Checkbox
                                        indeterminate={
                                        checkedList.length > 0 &&
                                        checkedList.length < props.allListRows.length
                                        }
                                        checked={itemsChecked}
                                        onClick={(e) => selectItem(e)}
                                    />
                                </TableCell>
                            )}
                            {props.flagID === (2) && !props.fleetList && currentUser && (currentUser.superuser || currentUser.role.includes('Admin') || currentUser.role.includes('Configurator')) && (
                                <TableCell align="center" padding="checkbox" className={classes.TableCheck}>
                                    <Checkbox
                                        indeterminate={
                                        checkedList.length > 0 &&
                                        checkedList.length < props.allListRows.length
                                        }
                                        checked={itemsChecked}
                                        onClick={(e) => selectItem(e)}
                                    />
                                </TableCell>
                            )}
                            {props.flagID === (3) && currentUser && currentUser.superuser && (
                                <TableCell align="center" padding="checkbox" className={classes.TableCheck}>
                                    <Checkbox
                                        indeterminate={
                                        checkedList.length > 0 &&
                                        checkedList.length < props.allListRows.length
                                        }
                                        checked={itemsChecked}
                                        onClick={(e) => selectItem(e)}
                                    />
                                </TableCell>
                            )}
                            {props.headCells.map((headCell, i) => (
                                !removalOfsortingData.includes(headCell.label) ?
                                <TableCell align="center"
                                    className={classes.TableHead}
                                    key={`${headCell.id}_${i}`}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                        </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                : <TableCell align="center" className={classes.TableHead}><Typography style={{color: '#c9c9c9'}}>{headCell.label}{headCell.labelDesc ? <><br/>{headCell.labelDesc}</> : null }</Typography></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        _DATA.currentData().map((row, index) => {
                            // Calculate Days from Telegram Date
                            var dtTelegram = moment(row.datetime, "YYYY-MM-DD");
                            var dtCurrent = moment().toDate();
                            var dt_Current = moment(dtCurrent, "YYYY-MM-DD");
                            var diffDays = dt_Current.diff(dtTelegram, "days");
                            return (
                            <TableRow
                                key={index}
                                className={`${index % 2 ? "row_even" : "row_odd"}`}
                            >
                                {props.flagID === 0 && row.role != null && (
                                <Fragment>
                                    {(currentUser && currentUser.role && currentUser.role.length	
                                    && (currentUser.superuser || currentUser.role.includes('Admin'))) && <TableCell align="center" padding="checkbox">
                                        <Checkbox
                                            item={row}
                                            value={row.id}
                                            checked={checkedList.includes(row.id)}
                                            onChange={handleCheckboxClick}
                                        />
                                    </TableCell>}
                                    <TableCell align="center">
                                        <Link1 className={classes.Link} to={`${path}/editUser/${row.id}`}>{row.username}</Link1>
                                    </TableCell>
                                    <TableCell align="center"><Typography>{`${row.last_name} ${row.first_name != null ? row.first_name : ''}`}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{row.email}</Typography></TableCell>
                                    <TableCell align="center"><Moment format="DD/MM/YYYY HH:mm:ss">{row.created_at}</Moment></TableCell>
                                    <TableCell align="center"><Typography>{row.is_active ? '' : <Moment format="DD/MM/YYYY HH:mm:ss">{row.updated_at}</Moment>}</Typography></TableCell>
                                    <TableCell align="center">
                                        {
                                            row["role"].map(role => {
                                                return <Typography>
                                                    {`•   ${t(role.name.replace(' ', '_').toLowerCase())}`}
                                                </Typography>
                                            })
                                        }
                                    </TableCell>
                                    <Route path={`${path}/editUser/id`} render={({path}) => <EditUsersComponent />}/>
                                </Fragment>
                                )}
                                {props.flagID === 1 && (
                                <Fragment>
                                    {currentUser && currentUser.superuser && <TableCell align="center" padding="checkbox">
                                    <Checkbox
                                        item={row}
                                        value={row.id}
                                        checked={checkedList.includes(row.id)}
                                        onChange={handleCheckboxClick}
                                    />
                                    </TableCell>}
                                    <TableCell align="center" padding="checkbox edit_icon">
                                        <Link1 to={`${path}/editFleet/${row.id}`}>
                                            <EditIcon
                                                item={row}
                                                value={row.id}
                                            />
                                        </Link1>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Link1 to={`${path}/${row.id}`}>
                                            {row.fleetname}
                                        </Link1>
                                    </TableCell>
                                    <TableCell align="center"><Typography>{row.sw_identification_number}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{row.version}</Typography></TableCell>
                                </Fragment>
                                )}
                                {/* Vehicles details of Gear List Page */}
                                {props.flagID === 2 && row.equipment_id != null &&(
                                <Fragment>
                                    {currentUser && (currentUser.superuser || currentUser.role.includes('Admin') || currentUser.role.includes('Configurator')) && !props.fleetList && <TableCell align="center" padding="checkbox">
                                        <Checkbox
                                            item={row}
                                            value={row.id}
                                            checked={checkedList.includes(row.id)}
                                            onChange={handleCheckboxClick}
                                        />
                                    </TableCell>}
                                    {(!props.fleetList && currentUser && currentUser.role && currentUser.role.length && (currentUser.role.includes('Admin') || currentUser.role.includes('Configurator'))) ? 
                                        <TableCell align="center" padding="checkbox">
                                            <Link1
                                                to={`/admin/gears/edit/${row.id}`}
                                            >
                                                <EditIcon
                                                    item={row}
                                                    value={row.id}
                                                />
                                            </Link1>
                                        </TableCell> : ''}
                                    <TableCell align="center" style={{overflow: "hidden", whiteSpace: "nowrap"}}>
                                        <Link1
                                            className={classes.Link}
                                            to={`/gears/${row.id}`}
                                        >
                                            {`• ${t('equipment_id')} : ${row && row.equipment_id}`}
                                        </Link1>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('uic_id')} :`}</b> {`${row && row.uic_id}`}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('sncf_number')} :`}</b> {`${row && row.sncf_number}`}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('trainset_number')} :`}</b> {`${row && row.trainset_number}`}
                                        </Typography>
                                    </TableCell>
                                    {
                                        !props.fleetList && <TableCell align="center">
                                            {
                                                (currentUser.role.includes('Admin') || currentUser.role.includes('Configurator')) ? <Link1 className={classes.Link}
                                                    to={`/admin/fleets/${row.fleet && row.fleet.id}`}
                                                >
                                                    {row  && row.fleet != undefined && row.fleet.fleetname != undefined ? row.fleet.fleetname : ''}
                                                </Link1> : <Typography>
                                                    {row  && row.fleet != undefined && row.fleet.fleetname != undefined ? row.fleet.fleetname : ''}
                                                </Typography>
                                            }
                                        </TableCell>
                                    }
                                    <TableCell align="center">
                                        <Typography>
                                            {row.config && row.config.is_active
                                            ? t("gear_activated")
                                            : t("gear_inactivated")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {row && row.config && row.config.nidc_value ? row.config.nidc_value.map((data, index) => {
                                            return <>
                                                <Typography>
                                                    {`[${data && data.lower} ; ${data && data.upper}]`}
                                                </Typography>
                                            </>
                                            }) : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>{row && row.config && row.config.gutMlowTh ? row.config.gutMlowTh : ''}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>{row && row.config && row.config.gutMupTh ? row.config.gutMupTh : ''}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>{row && row.config && row.config.msgNumber ? row.config.msgNumber : ''}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>{row && row.config && row.config.configFileVersion ? row.config.configFileVersion : ''}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                    { 
                                        row.config && row.config.server && <Typography>
                                            {
                                                (row.config.server.server_type === "0" || row.config.server.server_type === 0) ?
                                                t("flag2_integ_server")
                                                :
                                                t("flag2_prod_server")
                                            }
                                            </Typography>
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            row && row.config&& row.config.server && row.config.server.certificate ?
                                            <FileCopyIcon className={classes.downImage} />
                                            :
                                            <Typography component="p" className="error_msg">
                                                {t("missing_certificate")}
                                            </Typography>
                                        }
                                    </TableCell>
                                    {!props.fleetList && <TableCell align="center">
                                        {
                                            row && row.config && (
                                                <Typography>
                                                    {row.config && row.config.forcing === true ? t("gear_activated") : t("gear_inactivated")}
                                                </Typography>
                                            )
                                        }
                                    </TableCell>}
                                </Fragment>
                                )}
                                {props.flagID === 3 &&(
                                <Fragment>
                                    {currentUser && currentUser.superuser && <TableCell align="center" padding="checkbox">
                                    <Checkbox
                                        item={row}
                                        value={row.id}
                                        checked={checkedList.includes(row.id)}
                                        onChange={handleCheckboxClick}
                                    />
                                    </TableCell>}
                                    <TableCell align="center">
                                        <Link1
                                            className={classes.Link}
                                            to={`telegrams/${row.id}`}
                                        >
                                            {row.id}
                                        </Link1>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Link1
                                            className={classes.Link}
                                            to={`/gears/${row.gear.id}`}
                                        >
                                            {`• ${t('equipment_id')} : ${row.equipment_id ? row.equipment_id : row.gear && row.gear.equipment_id ? row.gear.equipment_id : ''}`}
                                        </Link1>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('uic_id')} :`}</b> {`${row.uic_id ? row.uic_id : row.gear && row.gear.uic_id ? row.gear.uic_id : ''}`}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('sncf_number')} :`}</b> {`${row.sncf_number ? row.sncf_number : row.gear && row.gear.sncf_number ? row.gear.sncf_number : ''}`}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('trainset_number')} :`}</b> {`${row.trainset_number ? row.trainset_number : row.gear && row.gear.trainset_number ? row.gear.trainset_number : ''}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center"><Moment format="DD/MM/YYYY HH:mm:ss">{row.received_on}</Moment></TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {row.messages.length}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center"><Typography>{_.join(_.map(row.messages, (msg) => msg.error_number), ', ')}</Typography></TableCell>
                                    <TableCell align="center">
                                        {
                                            row.sent ? <SendIcon color="success" /> : <ClearIcon color="error" />
                                        }
                                    </TableCell>
                                </Fragment>
                                )}
                                {props.flagID === 4 && (
                                <Fragment>
                                    <TableCell align="center" style={{overflow: "hidden", whiteSpace: "nowrap"}}>
                                        <Link1
                                            className={classes.Link}
                                            to={`/gears/${row.gear.id}`}
                                        >
                                            {`• ${t('equipment_id')} : ${row.equipment_id ? row.equipment_id : row.gear && row.gear.equipment_id ? row.gear.equipment_id : ''}`}
                                        </Link1>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('uic_id')} :`}</b> {`${row.uic_id ? row.uic_id : row.gear && row.gear.uic_id ? row.gear.uic_id : ''}`}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('sncf_number')} :`}</b> {`${row.sncf_number ? row.sncf_number : row.gear && row.gear.sncf_number ? row.gear.sncf_number : ''}`}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            <b>{`• ${t('trainset_number')} :`}</b> {`${row.trainset_number ? row.trainset_number : row.gear && row.gear.trainset_number ? row.gear.trainset_number : ''}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.datetime && <Moment format="DD/MM/YYYY HH:mm:ss">{row.datetime}</Moment>}
                                    </TableCell>
                                    <TableCell align="center">
                                        {(diffDays > 10 && (
                                            <CardMedia
                                                component="img"
                                                image={RedIcon}
                                                alt=""
                                                className={classes.Image}
                                            />
                                        )) ||
                                            (diffDays < 4 && (
                                            <CardMedia
                                                component="img"
                                                image={GreenIcon}
                                                alt=""
                                                className={classes.Image}
                                            />
                                            )) ||
                                            (diffDays <= 10 && (
                                            <CardMedia
                                                component="img"
                                                image={AmberIcon}
                                                alt=""
                                                className={classes.Image}
                                            />
                                            )) ||
                                            (isNaN(diffDays) && (
                                            <CardMedia
                                                component="img"
                                                image={GrayIcon}
                                                alt=""
                                                className={classes.Image}
                                            />
                                            ))}
                                    </TableCell>
                                </Fragment>
                                )}
                                {props.flagID === 5 && (
                                <Fragment>
                                    <TableCell align="center" style={{width: '50px'}}
                                    >
                                        <img src={row.gravity.toLowerCase() !== 'information' ? WarnIcon: InfoIcon} />
                                    </TableCell>
                                    <TableCell align="center"><Typography>{row.message}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{row.gravity}</Typography></TableCell>
                                    <TableCell align="center"><Typography><Moment format="DD/MM/YYYY HH:mm:ss">{row.date}</Moment></Typography></TableCell>
                                    <TableCell align="center"><Typography>{row.description}</Typography></TableCell>
                                </Fragment>
                                )}
                                {props.flagID === 6 && (
                                <Fragment>
                                    <TableCell align="center"><Typography>{row.comment}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{row.created_at}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{row.created_by && row.created_by.username && row.created_by.username}</Typography></TableCell>
                                </Fragment>
                                )}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container className="filter_show_container">      
                <TablePagination
                    className={classes.MuiDataGrid}
                    rowsPerPageOptions={selectedPage}
                    component="div"
                    count={props.totalRows}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={t("common_lang_list_show")}
                    labelDisplayedRows={({ from, to, count }) => `${t('table_entries_showing')} ${from} ${t('table_entries_to')} ${to} ${t('table_entries_of')} ${count} ${t('table_entries_entries')}`}
                    page={count <= 0 ? 0 : props.page - 1}
                    onPageChange={handleChangePage}
                    ActionsComponent={ActionComponentDisabled}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div style={{padding: '10px 20px', marginLeft: 'auto'}}>
                    <Pagination count={count} page={props.page} onChange={handleChangePage} style={{textAlign: 'right'}}/>
                </div>
            </Grid>
        </Box>
    );
};

//Styles Area
const useStyle = makeStyles({
    TableHead: {
        backgroundColor: "#2F3D92",
        "&.MuiTableCell-root": {
            color: "white",
            border: "1px solid",
            innerHeight: "0rem",
            padding: "5px 7px ",
            textAlign: "center"
        },
    },
    TableCheck: {
        backgroundColor: "#2F3D92",
        "&.MuiTableCell-root": {
            color: "white",
            border: "1px solid",
            innerHeight: "0rem",
        },
    },
    MuiDataGrid: {
        minWidth: "5%",
        "& .MuiInputBase-input": {
            backgroundColor: "#FFFF",
            border: "1px solid #2F3D92",
        },
    },
    Link: {
        "&.MuiTypography-root": {
            color: "#1976d2",
            textDecoration: "none",
            "&:hover": {
                color: "#FFAB19",
                fontWeight: "bold",
                cursor: "pointer",
            },
        },
    },
    Image: {
        "&.MuiCardMedia-root": {
            width: "20px",
            height: "20px",
            margin: "auto"
        },
    },
    downImage: {
        "&.MuiSvgIcon-root": {
            width: "80%",
            color: "#2F3D92",
        },
    },
    button: {
        "&.MuiButton-root": {
            backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
            color: "black",
            fontWeight: "bold",
            border: "1px solid",
            borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
            margin: "1px",
            minWidth: "25px",
        },
    }
});

export default EnhancedDataGridTableComponent;