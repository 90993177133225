// React and Redux declarations
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';
// import form related actions and declarations
import {
  Button,
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  CardMedia,
  List,
  ListItemButton,
  Collapse,
  Divider
} from "@mui/material";
import { parseISO } from 'date-fns'; 
import HorizontalRuleSharpIcon from "@mui/icons-material/HorizontalRuleSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import { Formik, Form } from "formik";
// Image process
import FilterDeleteImage from "../../static/images/icon_delete.png";
import useValidation from "../common/ValidationSchema";
import { FilterAPI } from "../../apis/FilterAPI";
import _ from 'lodash'

const CustomFilterComponent = (props) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const [rdoMatchValue, setRadioMatchValue] = useState("matchAll");

    const handleRadioMatchChange = (event) => {
        const rows = [...state.rows]
        const matchall = event.target.value == 'matchAll' ? true : false;
        setState({ rows, matchall }, setRadioMatchValue(event.target.value));
    };

    const [isLoading, setLoading] = useState(false);

    // get filter data
    const getFilters = (module_name) => {
        FilterAPI.get(module_name)
        .then((response) => {
            if (response) {
                setAllSelectedFilters([t("common_lang_nosave")].concat(response.data))
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const [defaultOperatorSelectedFor, setDefaultOperatorSelector] = useState({
        operators: {},
    });

    // Function to build the operators based on rows
    const getOperators = (rows) => {
        const operators = {};

        _.map(rows, (row, idx) => {
            if (props.allFilters[row.column].type === 'select') {
                rows[idx]['options'] = props.allFilters[row.column].options
            }
            operators[idx] = !operators[idx] ? {} : operators[idx]
            operators[idx]['operators'] = props.allFilters[row.column].operators;
            operators[idx]['type'] = props.allFilters[row.column].type;
        })
        setDefaultOperatorSelector({ operators });
    }

    useEffect(() => {
        getFilters(props.module_name);

        // Create the operators in case of a initial data provided
        if (props.initData && _.has(props.initData, 'matchall')) {
            getOperators(props.initData.rows)
            const rows = [...state.rows]
            const matchall = props.initData.matchall
            setState(
                { rows, matchall },
                setRadioMatchValue(props.initData.matchall ? 'matchAll' : 'matchAny')
            );
        }
    }, []);

    const [filterName, setFilterName] = useState("")
    const handleSaveFilter = async (values, { setStatus, resetForm }) => {
        const module_name = props.module_name;
        const name = filterName;
        const id = value.id;
        if (filterName == '') {
            useValidation.validateCustomFilter(t);
            return
        }
        const validFilters = {
            rows: state.rows.filter(row => row.type !== 'all'),
            matchall: state.matchall
        }
        setLoading(true);
        const req = value.id !== undefined ? FilterAPI.update({name, module_name, _filters: validFilters}, id) : FilterAPI.create({name, module_name, _filters: validFilters})
        req.then((response) => {
            resetForm({});
            setLoading(false);
            if (response && response.success) {
                getFilters(props.module_name);
                setStatus({
                    sent: true,
                    success: response.success,
                    msg: response.message,
                });
                (value._filters !== undefined && value._filters.matchall) && rdoMatchValue == 'matchAll' ? setRadioMatchValue('matchAll') : setRadioMatchValue('matchAny');
            } else {
                setStatus({
                    sent: true,
                    success: false,
                    msg: t("filter_error"),
                });
            }
        })
        .catch((error) => {
            console.log(error)
            setLoading(false);
            setStatus({
                sent: true,
                success: false,
                msg: error.message,
            });
        });
    };

    // Save API Calling using dispatch function
    const [value, setValue] = useState(t("common_lang_nosave"));
    const [allSelectedFilters, setAllSelectedFilters] = React.useState([t("common_lang_nosave")]);

    const [state, setState] = useState((props.initData && props.initData.rows.length > 0) ? props.initData : {
        rows: [
            {
                column: "All",
                operator: "",
                searchVal: "",
                type: "all"
            },
        ],
        matchall: true
    });

    const [dtValue, setdtValue] = useState(null);
    // Adding search rows
    const handleAddRow = () => {
        const item = {
            column: "All",
            operator: "",
            searchVal: "",
            type: "all"
        };
        (state.rows.length < 5) && setState({ rows: [...state.rows, item], matchall: rdoMatchValue == 'matchAll' ? true : false });
    };

    const deleteFilter = (val) => {
        if (confirm(t('delete_filter_question')) == true) {
            FilterAPI.delete(val.id, props.module_name)
            .then((response) => {
                if (response) {
                    setAllSelectedFilters([t("common_lang_nosave")].concat(response.data))
                    setFilterName('')
                    setValue(allSelectedFilters[0])
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

    const handleDataChange = (idx, additionalOperator) => (e) => {
        const { name, value } = e.target;
        const rows = [...state.rows];
        rows[idx].invalid = false
        // Additional operator is used for Select cases
        if (additionalOperator) {
            rows[idx]['operator'] = additionalOperator;
            rows[idx]['searchVal'] = value;
        } else {
            rows[idx][name] = value;
        }
        const matchall = rdoMatchValue == 'matchAll' ? true : false;
        const operators = { ...defaultOperatorSelectedFor.operators };

        if (name === "column") {
            rows[idx]['searchVal'] = ''
            rows[idx]['type'] = props.allFilters[value].type

            if (props.allFilters[value].type === 'select') {
                rows[idx]['options'] = props.allFilters[value].options
            }
            operators[idx] = !operators[idx] ? {} : operators[idx]
            operators[idx]['operators'] = props.allFilters[value].operators;
            operators[idx]['type'] = props.allFilters[value].type;

            if (props.allFilters[value].type !== 'all') {
                rows[idx]['operator'] = Object.values(operators[idx].operators)[0];
            }

            if (props.allFilters[value].type === 'select') {
                rows[idx]['searchVal'] = props.allFilters[value].options[0].val
            }
            
            setDefaultOperatorSelector({ operators });
        }
        setState({
            rows,
            matchall
        });
    };

    const handleRemoveSpecificRow = (idx) => () => {
        const rows = [...state.rows];
        const matchall = rdoMatchValue == 'matchAll' ? true : false;
        rows.splice(idx, 1);

        // Handle the empty row case
        if (rows.length === 0) {
            rows.push({
                column: "All",
                operator: "",
                searchVal: "",
                type: "all"
            })
        }
        getOperators(rows)
        setState({ rows, matchall });
    };

    const [filterMsg, setFilterMsg] = useState(false);
    const handleFilter = () => {
        const rows = validateData(state.rows)
        setState({ rows, matchall: state.matchall });
        const validFilters = {
            rows: _.map(state.rows.filter(row => !row.invalid), row => {
                const {options, ...rest} = row
                return rest
            }),
            matchall: state.matchall
        }
        props.genericDispatcher(validFilters);
    };
    const validateData = (rows) => {
        const data = rows.map((item, id) => {
            if (item.type == 'all' || item["column"] === "" || item["operator"] == "" || item["searchVal"] == "") {
                item.invalid = true
            }
            return item
        });
        return data;
    };
    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    const handleDateChange = (id, value) => {
        const rows = [...state.rows];
        rows[id]['searchVal'] = moment(value).format('YYYY-MM-DDTHH:mm:ss');
        const matchall = rdoMatchValue == 'matchAll' ? true : false;
        setState({
            rows,
            matchall
        }, setdtValue(value));
    }
    
    const handleFilterChange = (e) => {
        setValue(e.target.value);

        if (_.isObject(e.target.value)) {
            setFilterName(e.target.value.name);
            const operators = { ...defaultOperatorSelectedFor.operators };
            e.target.value._filters !== undefined && e.target.value._filters.rows.map((data, id) => {
                operators[id] = props.allFilters[data.column];
            });
            setDefaultOperatorSelector({ operators });
            const data = JSON.parse(JSON.stringify(e.target.value._filters));
            data.defaultForm = true;
            e.target.value._filters !== undefined ? setState({... data}): '';
            setRadioMatchValue(e.target.value._filters == undefined || e.target.value._filters.matchall ? 'matchAll' : 'matchAny');
        } else {
            setFilterName('');
            setDefaultOperatorSelector({
                operators: {},
            });
            setState({
                rows: [
                    {
                        column: "All",
                        operator: "",
                        searchVal: "",
                        type: "all"
                    },
                ],
                matchall: true
            })
        }
    }

    return (
        <List component="nav" sx={{ padding: 0 }}>
            <ListItemButton className={classes.ListButton} onClick={handleClick}>
                <Typography component="h1" sx={{paddingBottom: 0}}>
                    {open ? <HorizontalRuleSharpIcon /> : <AddSharpIcon />}
                    <Box className="filterIcon">{t("common_lang_filters")}</Box>
                </Typography>
            </ListItemButton>
            
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box className={classes.filterLabel}>{t("common_lang_save")}</Box>
                    <Box>
                        <Select
                            value={value}
                            onChange={handleFilterChange}
                            className="custom_main_select"
                            disabled={!(allSelectedFilters.length > 1)}
                        >
                            {allSelectedFilters.map((item, idx) => (
                                <MenuItem key={idx} value={item}>
                                    {item.name ? item.name : item}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box>
                        {
                            _.isObject(value) && <CardMedia
                                component="img"
                                image={FilterDeleteImage}
                                alt="Delete Icon"
                                className={classes.Image}
                                onClick={() => deleteFilter(value)}
                            />
                        }
                    </Box>
                </Box>
                <Divider sx={{margin: '5px 0'}} />
                <Box>
                    <RadioGroup
                        row
                        value={(value._filters !== undefined && value._filters.matchall) && rdoMatchValue == 'matchAll' ? 'matchAll' : rdoMatchValue}
                        onChange={handleRadioMatchChange}
                    >
                        <FormControlLabel
                            value="matchAll"
                            control={<Radio />}
                            label={t("common_lang_matchall")}
                        />
                        <FormControlLabel
                            value="matchAny"
                            control={<Radio />}
                            label={t("common_lang_matchany")}
                        />
                    </RadioGroup>
                </Box>
                <Box>
                    {state.rows.map((data, idx) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }} key={idx}>
                            <CardMedia
                                component="img"
                                image={FilterDeleteImage}
                                alt="Delete Icon"
                                className={classes.Image}
                                onClick={handleRemoveSpecificRow(idx)}
                            />
                            <Select
                                className="custom_main_select"
                                name="column"
                                data-testid="column"
                                value={data.column}
                                onChange={handleDataChange(idx)}
                            >
                                {Object.keys(props.allFilters).map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {t(item.toLowerCase())}
                                    </MenuItem>
                                ))}
                            </Select>
                            {
                                
                                state.rows[idx]["column"] !== "" && state.rows[idx]["type"] !== "all" &&
                                Object.keys(defaultOperatorSelectedFor.operators).length && (
                                    state.rows[idx]["type"] === "select" ? <Select
                                        className="custom_main_select"
                                        data-testid="custom_sub_select"
                                        value={state.rows[idx]["searchVal"]}
                                        onChange={handleDataChange(idx, state.rows[idx]["operator"])}
                                        name="operator"
                                    >
                                        {state.rows[idx].options.map((item) => (
                                            <MenuItem
                                                key={item.val}
                                                value={item.val}
                                            >
                                                {t(item.name.replace(' ', '_'))}
                                            </MenuItem>
                                        ))}
                                    </Select> : <Select
                                        className="custom_main_select"
                                        data-testid="custom_sub_select"
                                        value={state.rows[idx]["operator"]}
                                        onChange={handleDataChange(idx)}
                                        name="operator"
                                    >
                                        {Object.keys(defaultOperatorSelectedFor.operators[idx].operators).length && Object.keys(
                                            defaultOperatorSelectedFor.operators[idx].operators
                                        ).map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={
                                                    defaultOperatorSelectedFor.operators[idx].operators[item]
                                                }
                                            >
                                                {t(item.toLowerCase())}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )
                            }
                            {state.rows[idx]["type"] !== "date" ? (
                                ["all", "select"].includes(state.rows[idx]["type"]) ? (
                                    null
                                ) : (
                                    <TextField
                                        style={{
                                        display:
                                            state.rows[idx]["column"] !== "" ? "" : "none",
                                        }}
                                        type="text"
                                        name="searchVal"
                                        value={state.rows[idx]["searchVal"]}
                                        onChange={handleDataChange(idx)}
                                        className={classes.Textbox}
                                    />
                                )
                            ) : (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={parseISO(state.rows[idx].searchVal ? state.rows[idx].searchVal : null)}
                                        inputFormat="dd/MM/yyyy"
                                        name="searchVal"
                                        onChange={
                                            (newValue) => {
                                                handleDateChange(idx, newValue)
                                            }
                                        }
                                        className={classes.Textbox}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            )}
                            {
                                data.invalid && <Typography className={`${classes.filterError} error_msg`} component="span">
                                    {data.type === 'all' ? ((state.rows.length > 1) ? t('custom_filter_all_error') : '') : t("custom_filter_novalue")}
                                </Typography>
                            }
                        </Box>
                    ))}
                </Box>
                <Box className="filter_button">
                    {
                        (state.rows.length < 5) && <Button
                            type="button"
                            onClick={handleAddRow}
                            className={classes.button}
                        >
                            {t("custom_filter_criteria")}
                        </Button>
                    }
                </Box>
                <Divider sx={{margin: '5px 0'}} />
                <Box className="save_filter" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ marginRight: '10px' }}>{t("common_lang_select_saved_filter")}</Box>
                    <Formik
                        initialValues={{ txtSave: filterName }}
                        value={filterName}
                        onSubmit={handleSaveFilter}
                    >
                        {({ handleChange, status, values, errors }) => (
                        <Form>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    type="text"
                                    name="txtSave"
                                    value={filterName}
                                    onChange={(e) => setFilterName(e.target.value)}
                                    data-testid="txtSave"
                                    className={classes.Textbox}
                                    sx={{ marginRight: '10px' }}
                                />
                                <Button
                                    type="submit"
                                    data-testid="submit"
                                    className={classes.button}
                                    disabled={isLoading ? true : false}
                                >
                                    {t("custom_filter_save_button")}
                                </Button>
                                {status && (
                                    <Box>
                                        <Typography
                                            component="p"
                                            className={`${
                                                status.sent && status.success ? "success_msg" : "error_msg"
                                            }`}
                                            sx={{marginLeft: '10px'}}
                                        >
                                            {status.msg}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Form>
                        )}
                    </Formik>
                </Box>
                <Divider sx={{margin: '5px 0'}} />
                <Box className="filter_button">
                    <Button
                        type="button"
                        onClick={() => handleFilter()}
                        className={classes.button}
                    >
                        {t("common_lang_filters")}
                    </Button>
                </Box>
            </Collapse>
        </List>
    );
};

// Styles Area
const useStyle = makeStyles({
    filterLabel: {        
        "&.MuiBox-root": {
            marginRight: '10px'
        }
    },
    filterError: {
        "&.MuiTypography-root": {
            marginLeft: '5px',
            fontWeight: 'bold'
        }
    },
    Link: {
        "&.MuiLink-root": {
            textDecoration: "none",
        },
    },
    button: {
        "&.MuiButton-root": {
            backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
            color: "black",
            fontWeight: "bold",
            border: "1px solid",
            borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
            margin: 0,
        },
    },
    tabs: {
        width: "400px",
        borderRadius: "10px 10px 0px 0px",
        "& .MuiTab-root": {
            whiteSpace: "nowrap",
            display: "block",
            color: "white",
            backgroundColor: "#2F3D92",
            margin: "5px 5px 0px 0px",
            borderRadius: "6px 6px 0px 0px",
            textTransform: "capitalize",
            fontWeight: "bold",
            "&:hover": {
                color: "#FFAB19 !important",
            },
        },
        "& .Mui-selected": {
            color: "#2F3D92 !important",
            backgroundColor: "#D0DCF9",
            fontWeight: "bold",
        },
    },
    MuiDataGrid: {
        minWidth: "5%",
        "& .MuiInputBase-input": {
            backgroundColor: "#FFFF",
            border: "1px solid #2F3D92",
        },
    },
    hr: {
        "&.MuiTypography-root": {
            marginTop: "10px",
        },
    },
    Image: {
        "&.MuiCardMedia-root": {
            width: "15px",
            position: "relative",
            cursor: 'pointer',
            dispaly: 'inline-block',
            marginRight: '10px'
        },
    },
    Textbox: {
        "&.MuiTextField-root": {
            backgroundColor: "#FFFFFF",
            width: "150px",
            marginTop: "0px",
        },
        "& .MuiInputBase-input": {
            padding: "5px 5px",
        },
        "&.MuiOutlinedInput-root": {
            marginTop: "0px",
        },
    },
    dtPicker: {
        "&.PrivatePickersSlideTransition-root css-dhopo2": {
            minHeight: "195px",
        },
    },
    ListButton: {
        "&.MuiListItemButton-root": {
            display: "list-item",
            padding: 0,
        },
    },
});

export default CustomFilterComponent;
