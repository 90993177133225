// React and Redux declarations
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  CircularProgress
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import form related actions and declarations
import { Formik, Form, Field, ErrorMessage } from "formik";
import useValidation from "../../common/ValidationSchema";
import DeleteIcon from '@mui/icons-material/Delete';
import { ServerAPI } from "../../../apis/ServerAPI";

const ServersOLMComponent = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [initialValues, setInitData] = React.useState({
    serverLogin: "",
    serverPass: "",
    serverUrl: "",
    timeout: "",
    frequency: ""
  });

  const [fetching, setFetching] = React.useState(false)
  const timeoutRef = React.useRef(null);

  const [olmServer, setolmServer] = useState("0");
  const selectedServerChange = (event) => {
    setolmServer(event.target.value);
    getServerDetail(event.target.value);
  };
  const [warningMsg, setwarningMsg] = useState("");
  const [reqMsg, setReqMsg] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [fileName, setFileName] = useState("");
  const onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      setwarningMsg(t("config_warning"));
    } else {
      setCertificate(accepted[0]);
      setwarningMsg("");
    }
  };

  const getServerDetail = (server) => {
    ServerAPI.get(server)
    .then((response) => {
      if (response && response.data.length) {
          if (response.ongoing_task) {
            setFetching(true)
            // setTimeout(() => {
            //   setFetching(false)
            // }, response.data[0]['timeout'] * 1000)
            getStatus(response.ongoing_task)
          }
          setInitData({
            serverUrl: response.data[0]['server_url'],
            serverPass: response.data[0]['password'],
            serverLogin: response.data[0]['user_name'],
            timeout: response.data[0]['timeout'],
            frequency: response.data[0]['frequency'],
          });
          response.data[0].certificate ? setFileName(response.data[0]['certificate'].split('/').at(-1)) : setFileName("");
      } else {
        setInitData({
          serverUrl: "",
          serverPass: "",
          serverLogin: "",
          timeout: "",
          frequency: ""
        });
        setFileName("")
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const getStatus = (taskId) => {
    ServerAPI.get_status(taskId).then((response) => {
      if (response.task_status === 'PENDING' || response.task_status === 'RETRY') {
        if (response.task_status === 'RETRY') {
          if (response.task_message) {
            setReqMsg({
              error: true,
              message: response.task_message
            })
          }
        }
        timeoutRef.current = setTimeout(() => {
          getStatus(taskId);
        }, 1000);
      } else if (response.task_status === 'SUCCESS') {
        setFetching(false)
        if (_.inRange(response.task_result, 400, 599)) {
            setReqMsg({
                error: true,
                message: response.task_message
            })
        } else if (response.task_result === 200) {
            setReqMsg({
                error: false,
                message: response.task_message
            })
        } else {
            setReqMsg({
                error: false,
                message: response.task_message || t(`Basic authentication ended with code: ${response.task_result}`)
            })
        }
      } else {
        setFetching(false)
        setReqMsg({
            error: true,
            message: response.task_message || t('Basic authentication check could not be performed')
        })
      }
    })
  }

  const handleServer = async (values, { setStatus, resetForm }) => {
    setFetching(true)
    setStatus({})
    setReqMsg(null)
    values["serverType"] = olmServer;
    values["certFile"] = certificate;
    const { serverLogin, serverPass, serverUrl, timeout, frequency } = values;
    
    // setTimeout(() => {
    //   setFetching(false)
    // }, timeout * 1000)
    if (!certificate && !fileName) {
      setwarningMsg(t("config_warning"));
    }
    ServerAPI.create_update(
      {
        serverLogin,
        serverPass,
        serverUrl,
        olmServer,
        certificate,
        timeout,
        frequency
      }
    )
    .then((response) => {
      getStatus(response.task)
      if (response !== undefined) {
        setStatus({
          sent: true,
          msg: response.message,
        });
      }
    })
    .catch((error) => {
      setFetching(false)
      setStatus({
        sent: true,
        msg: error.message,
      });
    });
  };

  const [deleteMsg, setDeleteMsg] = React.useState(null)

  const deleteCertificates = async (server_type) => {
    ServerAPI.delete(server_type)
    .then((response) => {
      if (response && response.success) {
        setDeleteMsg({
          sent: true,
          success: response.success,
          msg: response.message,
        });
        setFileName('')
      } else {
        const errMsg = response.error ? response.error.message : response.message
        setDeleteMsg({
              success: false,
              msg: errMsg,
          });
      }
    })
    .catch((error) => {
      setDeleteMsg({
          success: false,
          msg: t('gear_delete_err_message')
      });
    });
  };

  useEffect(() => {
    getServerDetail(olmServer);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Box className="frame_border">
      <Typography component="h1" className="font-2rem">{t("config_file")}</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={useValidation.validateServers(t)}
        enableReinitialize={true}
        onSubmit={handleServer}
      >
        {({ handleChange, status, values, errors }) => (
          <Form data-testid="form">
            <Grid className="content_panel">
              {status && (
                <Typography
                  component="p"
                  className={`${status.sent ? "success_msg" : "error_msg"}`}
                >
                  {status.msg}
                </Typography>
              )}
              {warningMsg && (
                <Typography component="p" className="error_msg">
                  {warningMsg}
                </Typography>
              )}
              {reqMsg && (
                <Typography component="p" className={reqMsg.error ? "error_msg" : "success_msg"}>
                  {`${reqMsg.message}`}
                </Typography>
              )}
              <Grid container>
                  <Grid item xs={5}>
                    <Grid container className='min_height p_10'>
                      <Grid item xs={5}>
                        <b className='p_t_7'>{t("config_choice_server")} <span className='asterisk'> {'*'} </span></b>
                      </Grid>
                      <Grid item xs={7}>
                        <Select
                          value={olmServer}
                          data-testid="olmServer"
                          onChange={selectedServerChange}
                          className="select_edit_style"
                          disabled={fetching}
                        >
                          {selectedServer.map(({ id, name }, index) => (
                            <MenuItem key={index} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                          </Select>
                      </Grid>
                    </Grid>
                    <Grid container className='min_height bg_color p_10'>
                      <Grid item xs={5}>
                        <b className='p_t_7'>{t("label_username_en_fr")} <span className='asterisk'> {'*'} </span></b>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          name="serverLogin"
                          data-testid="serverLogin"
                          value={values.serverLogin}
                          onChange={handleChange}
                          type="text"
                          className="input_txt"
                          disabled={fetching}
                        />
                        <ErrorMessage component="div" className="error_msg" name="serverLogin" />
                      </Grid>
                    </Grid>
                    <Grid container className='min_height p_10'>
                      <Grid item xs={5} >
                        <b className='p_t_7'>{t("config_password")} <span className='asterisk'> {'*'} </span></b>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          name="serverPass"
                          data-testid="serverPass"
                          value={values.serverPass}
                          onChange={handleChange}
                          type="password"
                          className="input_txt"
                          disabled={fetching}
                        />
                        <ErrorMessage component="div" className="error_msg" name="serverPass" />
                      </Grid>
                    </Grid>
                    <Grid container className='min_height bg_color p_10'>
                      <Grid item xs={5} >
                        <b className='p_t_7'>{t("config_url")} <span className='asterisk'> {'*'} </span></b>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          name="serverUrl"
                          data-testid="serverUrl"
                          value={values.serverUrl}
                          onChange={handleChange}
                          type="text"
                          className="input_txt"
                          disabled={fetching}
                        />
                        <ErrorMessage component="div" className="error_msg" name="serverUrl" />
                      </Grid>
                    </Grid>
                    <Grid container className='min_height p_10'>
                      <Grid item xs={5} >
                        <b className='p_t_7'>{t("timeout")} ({t("seconds")}) <span className='asterisk'> {'*'} </span></b>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          name="timeout"
                          data-testid="timeout"
                          value={values.timeout}
                          onChange={handleChange}
                          type="text"
                          className="input_txt"
                          disabled={fetching}
                        />
                        <ErrorMessage component="div" className="error_msg" name="timeout" />
                      </Grid>
                    </Grid>
                    <Grid container className='min_height bg_color p_10'>
                      <Grid item xs={5} >
                        <b className='p_t_7'>{t("frequency")} ({t("seconds")}) <span className='asterisk'> {'*'} </span></b>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          name="frequency"
                          data-testid="frequency"
                          value={values.frequency}
                          onChange={handleChange}
                          type="text"
                          className="input_txt"
                          disabled={fetching}
                        />
                        <ErrorMessage component="div" className="error_msg" name="frequency" />
                      </Grid>
                    </Grid>
                    <Grid container className='min_height p_10'>
                        <Grid item xs={5} >
                          <b className='p_t_7'>{t("config_certificate")} <span className='asterisk'> {'*'} </span></b>
                        </Grid>
                        <Grid item xs={7}>
                          <Dropzone
                            className="upload_label_olm_server1"
                            data-testid="drop-input"
                            files={certificate}
                            accept=".cer"
                            onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
                            disabled={fetching}
                          >
                            {t("config_choose_file")}
                          </Dropzone>
                          {(certificate || fileName) && (
                            <Grid className="server_msg" style={{width: '100%'}}>
                              <Typography component="p" className="success_msg">
                                {
                                  certificate ?
                                    certificate.name + t("config_upload_success")
                                  :
                                    fileName
                                }
                              </Typography>
                              <DeleteIcon className={classes.deleteImg} onClick={() => deleteCertificates(olmServer)}/>
                            </Grid>
                          )}
                          {deleteMsg ? <Typography component="p" className={deleteMsg.success ? 'success_msg' : 'error_msg'}>{deleteMsg.msg}</Typography> : null}
                        </Grid>
                  </Grid>
                  <Grid xs={7}></Grid>
                </Grid>
              </Grid>              
              <Grid container alignItems="center" sx={{marginTop: '10px'}}>
                <Button
                  type="submit"
                  data-testid="submit"
                  disabled={fetching}
                  className={classes.button}
                >
                  {t("modifier_password_en_fr")}
                </Button>
                {fetching && <CircularProgress className={classes.loading} size={20} />}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

//Styles Area
const useStyle = makeStyles({
  deleteImg: {
      "&.MuiSvgIcon-root": {
          color: "red",
          cursor: "pointer"
        }
  },
  button: {
    width: "200px",
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      marginLeft: "0px",
    },
  },
  loading: {
    marginLeft: 20
  }
});

const selectedServer = [
  { id: "0", name: "Integration" },
  { id: "1", name: "Production" },
];

export default ServersOLMComponent;
