import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    Grid,
    Box,
    Button,
    Select,
    MenuItem,
    Typography,
    CircularProgress,
    Divider,
    Checkbox,
    ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useValidation from "../../common/ValidationSchema";
import BackButton from "../../common/BackButton";
import Moment from "react-moment";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { UserAPI } from "../../../apis/UserAPI";
import { ProfileAPI } from "../../../apis/ProfileAPI";
import { AuthAPI } from "../../../apis/AuthAPI";


const EditUsersComponent = () => {
    const params = useParams();
    // Call styles for controls
    const classes = useStyle();
    const [currentEdituser, setCurrentEditUser] = useState({});
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');

    const history = useHistory()

    // Role List process
    const [roleRows, setRoleRows] = useState([]);

    useEffect(() => {
        ProfileAPI.list(true).then((response) => {
            if (response !== null) {
                let jsonData = response.data;
                setRoleRows(_.map(jsonData, role => {
                    return {
                        id: role.id,
                        name: t(role.name.replace(' ', '_').toLowerCase())
                    }
                }));
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const handleUpdate = async (values, { setStatus }) => {
        setStatus({})
        UserAPI.update(
            {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                role: [values.role],
                is_active: values.is_active,
                user_id: values.id,
                username: values.username
            },
            values.id,
            true)
        .then((response) => {
            if (response !== undefined) {
                setStatus({
                    sent: true,
                    success: response.success,
                    msg: response.message,
                });
            }
        }).catch((error) => {
            setStatus({
                sent: true,
                success: false,
                msg: error.message,
            });
        });
    };

    // Get user data
    useEffect(() => {
        UserAPI.get({user_id: params.user_id}).then((response) => {
            if (response.data !== null) {
                response.data.role = response.data.role[0].id
                setCurrentEditUser(response.data)
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const [isLoading, setLoading] = useState(false);
    const [mStatus, setmStatus] = useState(false);
    // ForgotPassword API Calling using dispatch function
    const handleForgotPassword = async () => {
        const username = currentEdituser.username;
        setLoading(true);
        AuthAPI.forgot(username)
        .then((response) => {
            setLoading(false);
            setmStatus({
                sent: true,
                msg: response.message,
            });
        })
        .catch((error) => {
            setLoading(false);
            setmStatus({
                sent: false,
                msg: error.user,
            });
        });
    };

    return (
        <Box className="frame_border">
            <Typography component="h1" className="font-2rem">{t("hightlight_Admin_Edit_Area")}</Typography>
            <Formik
                enableReinitialize={true}
                initialValues={currentEdituser}
                onSubmit={handleUpdate}
                validationSchema={useValidation.validateEditUsers(t)}
            >
                {({ handleChange, status, values, errors }) => (
                <Form>
                    {
                        !status && action && <Typography component="p" className={`success_msg`}>{t('user_added_success')}</Typography>
                    }
                    {status && (
                    <Typography
                        component="p"
                        className={`${status.sent && status.success ? "success_msg" : "error_msg"}`}
                    >
                        {status.msg}
                    </Typography>
                    )}
                    {mStatus && (
                    <Typography
                        component="p"
                        className={`${mStatus.sent ? "success_msg" : "error_msg"}`}
                    >
                        {mStatus.msg}
                    </Typography>
                    )}
                    <Grid className="content_panel">
                        <Grid className="head_table">
                            <Grid container>
                                <Grid item xs={5}>
                                    <Grid container className='min_height p_10'>
                                        <Grid item xs={4} >
                                            <b className='p_t_7'>{t("label_username_en_fr")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Field
                                                name="username"
                                                data-testid="username"
                                                value={values.username}
                                                onChange={handleChange}
                                                type="text"
                                                className="input_txt"
                                            />
                                            <ErrorMessage component="div" className="error_msg" name="username" />
                                        </Grid>
                                    </Grid>
                                    <Grid container className='min_height bg_color p_10'>
                                        <Grid item xs={4} >
                                            <b className='p_t_7'>{t("label_firstname_en_fr")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Field
                                                name="first_name"
                                                data-testid="first_name"
                                                value={values.first_name}
                                                onChange={handleChange}
                                                type="text"
                                                className="input_txt"
                                            />
                                            <ErrorMessage component="div" className="error_msg" name="first_name" />
                                        </Grid>
                                    </Grid>
                                    <Grid container className='min_height p_10'>
                                        <Grid item xs={4} >
                                            <b className='p_t_7'>{t("label_lastname_en_fr")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Field
                                                name="last_name"
                                                data-testid="last_name"
                                                value={values.last_name}
                                                onChange={handleChange}
                                                type="text"
                                                className="input_txt"
                                            />
                                            <ErrorMessage component="div" className="error_msg" name="last_name" />
                                        </Grid>
                                    </Grid>
                                    <Grid container className='min_height bg_color p_10'>
                                        <Grid item xs={4} >
                                            <b className='p_t_7'>{t("adduser_label_email")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Field
                                                name="email"
                                                data-testid="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                type="text"
                                                className="input_txt"
                                            />
                                            <ErrorMessage component="div" className="error_msg" name="email" />
                                        </Grid>
                                    </Grid>
                                    <Grid container className='min_height p_10'>
                                        <Grid item xs={4} >
                                            <b className='p_t_7'>{t("label_createdate_en_fr")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Moment className="p_t_7" format="DD/MM/YYYY HH:mm:ss">{values.created_at}</Moment>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='min_height bg_color p_10'>
                                        <Grid item xs={4} >
                                            <b className='p_t_7'>{t("label_activestatus_en_fr")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                name="is_active"
                                                value={values.is_active || false}
                                                data-testid="is_active"
                                                onChange={handleChange}
                                                className="select_edit_style"
                                            >
                                            {selectedfnOLM.map(({ value, name }, index) => (
                                                <MenuItem key={index} value={value}>
                                                    {t(name)}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='min_height p_10'>
                                        <Grid item xs={4} >
                                            <b className='p_t_7'>{t("profiles_en_fr")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                value={values.role || ''}
                                                onChange={handleChange}
                                                name="role"
                                                className="select_edit_style"
                                            >
                                            {roleRows.map((profile) => (
                                                <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
                                            ))}
                                            </Select><br />
                                            <ErrorMessage component="div" className="error_msg" name="role" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7}></Grid>
                            </Grid>
                            {/* Button process */}
                            <Grid>
                                <Button
                                    type="submit"
                                    data-testid="submit"
                                    className={classes.button}
                                >
                                    {t("modifier_password_en_fr")}
                                </Button>
                            </Grid>
                            <Divider />
                            <Grid className="edit_forgot_btn">
                                <Box>
                                    {isLoading ? (
                                    <Button
                                        disabled
                                        className={classes.button}
                                    >
                                        <CircularProgress size={8} />
                                        {t("forgot_lang_sendmail")}
                                    </Button>
                                    ) : (
                                    <Button
                                        onClick={handleForgotPassword}
                                        className={classes.button}
                                    >
                                        {t("forgot_lang_reset")}
                                    </Button>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
                )}
            </Formik>
            <BackButton url={'/admin/users'} text={t("back_to_administration")} />
        </Box>
    );
};

//Styles Area
const useStyle = makeStyles({
    Textbox: {
        "&.MuiTextField-root": {
            backgroundColor: "#FFFFFF",
            width: "150px",
            marginLeft: "10px",
        },
        "& .MuiInputBase-input": {
            padding: "5px",
        },
    },
    button: {
        "&.MuiButton-root": {
            backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
            color: "black",
            fontWeight: "bold",
            border: "1px solid",
            borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
            margin: "5px",
        },
    },
    Link: {
        "&:hover": {
            textDecoration: "none",
        },
    },
    Image: {
        "&.MuiCardMedia-root": {
            width: "20px",
            height: "20px",
        },
    },
    Box: {
        display: 'flex',
        alignItems: 'center'
    },
});

const selectedfnOLM = [
    { value: true, name: "active" },
    { value: false, name: "inactive" },
];

export default EditUsersComponent;
