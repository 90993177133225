import { Redirect, Route } from 'react-router-dom'

function ProtectedRoute({ children, ...rest }) {
    const currentUser = JSON.parse(localStorage.getItem("user"));

    return (
        !rest.superuser ? <Route
            {...rest}
            render={() => {
                return currentUser && (rest.roles ? (rest.roles.some(r=> currentUser.role.indexOf(r) >= 0) || currentUser.superuser) : true) ? (
                    children
                ) : (
                    <Redirect to="/" />
                );
            }}
        /> : <Route
            {...rest}
            render={() => {
                return currentUser && currentUser.superuser ? (
                    children
                ) : (
                    <Redirect to="/" />
                );
            }}
        />
    );
}

export default ProtectedRoute