import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationFR from "./assets/locales/fr/translation.json";
import translationEN from "./assets/locales/en/translation.json";

const fallbackLng = ["fr"];
const availableLanguages = ["fr", "en", "de"];
const config = JSON.parse(localStorage.getItem('configOLM'))

const resources = config?.multi_language ? {
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
} : {
  fr: {
    translation: translationFR
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
