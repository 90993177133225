export const setLocalStorage = (name, selectedFilters, page, perPage, orderBy) => {
    localStorage.removeItem(name)
    localStorage.setItem(name, JSON.stringify({
        selectedFilters,
        page,
        perPage,
        orderBy
    }));
}

export const getLocalStorage = (name) => {
    const storedData = localStorage.getItem(name);
    return storedData ? JSON.parse(storedData) : null;
  };

export const clearLocalStorage = (name) => {
    localStorage.removeItem(name);
  };
