import { api } from "./config/axiosConfig"
import { defineCancelApiObject } from "./config/axiosUtils"

export const ServerAPI = {
    get: async function (server, cancel = false) {
        const url = `configuration/fileUploadingAPI/${server == '0' || server== '1' ? server : ''}/`
        const response = await api.request({
            url: url,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    create_update: async function (data, cancel = false) {
        const url = `/configuration/fileUploadingAPI/${data.olmServer}/`
        const formGData = new FormData();
        formGData.append("user_name", data.serverLogin);
        formGData.append("password", data.serverPass);
        formGData.append("server_url", data.serverUrl);
        formGData.append("timeout", data.timeout);
        formGData.append("frequency", data.frequency);
        if (data.certificate) {
            formGData.append("certificate", data.certificate);
        }
        formGData.append("server_type", data.olmServer);

        const response = await api.request({
            url: url,
            method: "POST",
            data: formGData,
            signal: cancel ? cancelApiObject[this.list.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    delete: async function (server_type, cancel = false) {
        const url = `configuration/deleteCertificate/${server_type}`
        const response = await api.request({
            url: url,
            method: "DELETE",
            signal: cancel ? cancelApiObject[this.list.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    get_status: async function (task_id, cancel = false) {
        const url = `configuration/get_status/${task_id}/`
        const response = await api.request({
            url: url,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

const cancelApiObject = defineCancelApiObject(ServerAPI)