// React and Redux declarations
import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import { Grid, Button, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { CSVLink } from "react-csv";
import EnhancedDataGridTableComponent from "../../common/EnhancedDataGridTable";
import CustomFilterComponent from "../../common/CustomFilter";
import useFilters from "../../common/CommonFilters";
import { setLocalStorage, getLocalStorage } from "../../common/LocalStorage";
import { Link, useRouteMatch } from "react-router-dom";
import { UserAPI } from "../../../apis/UserAPI";

const UsersListComponent = (props) => {
    const { path } = useRouteMatch();
    const classes = useStyle();
    const { t } = useTranslation();

    const headCells = [
        { label: t("admin_download_user_name"), id: "username" },
        { label: t("admin_download_full_name"), id: "last_name"},
        { label: t("admin_download_email"), id: "email" },
        { label: t("admin_download_created_on"), id: "created_at"},
        { label: t("admin_download_deactivation_date"), id: "updated_at"},
        { label: t("admin_download_roles"), id: "role" },
    ];

    const localStateName = 'userListState'
    const storedState = getLocalStorage(localStateName);
    
    // All user list API call
    const [alluserRows, setAllUserRows] = useState([]);
    const [downloadList, setDownloadList] = useState([])

    // Items for the backend pagination system
    const [totalRows, setTotalRows] = useState(0)
    const [selectedFilters, setSelectedFilters] = useState(storedState && storedState.selectedFilters || { rows: [] })
    const [page, setPage] = useState(storedState && storedState.page || 1)
    const [perPage, setPerPage] = useState(storedState && storedState.perPage || 10)
    const [orderBy, setOrderBy] = useState(storedState && storedState.orderBy || '-id')

    // Function called by the filters component
    const genericDispatcher = (selectedFilters) => {
        setPage(1)
        setLocalStorage(localStateName, selectedFilters, 1, perPage, orderBy)
        setSelectedFilters(selectedFilters)
        getUsers(selectedFilters, 1, perPage, orderBy);
    };
    // Function called by the table elements (page, per page, order actions)
    const callback = (page, rowsPerPage, orderBy) => {
        setPage(page)
        setPerPage(rowsPerPage)
        setOrderBy(orderBy)
        setLocalStorage(localStateName, selectedFilters, page, rowsPerPage, orderBy)
        getUsers(selectedFilters, page, rowsPerPage, orderBy);
    }

    const getUsers = (selectedFilters, page, rowsPerPage, orderBy) => {
        UserAPI.list({selectedFilters, page, rowsPerPage, orderBy})
        .then((response) => {
            if (response.data !== null) {
                setTotalRows(response.total_rows)
                let data = response.data.map(item => {
                    return {
                        'id': item.id,
                        'name': `${item.first_name} ${item.last_name != null ? item.last_name : ''}`,
                        'username': item.username,
                        'email': item.email,
                        'created_at': item.created_at,
                        'updated_at': item.is_active ? '' : item.updated_at,
                        'role': item.role.map(_data => {
                            return _data.name
                        }).join()
                    }
                });
                setDownloadList(data);
                setAllUserRows(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        getUsers(selectedFilters, page, perPage, orderBy);
    }, []);

    // Delete the User
    const [isDelete, setDelete] = useState("");
    const handleDeleteUser = (rowItemID) => {
        let text = t("delete_user_confirmation")
        if (confirm(text) == true) {
            UserAPI.delete(rowItemID).then((response) => {
                if (response !== undefined) {
                    setDelete(response);
                }
                getUsers(selectedFilters, page, perPage, orderBy);
            });
        }
    };

    // CSV File Data
    const headers = [
        { label: t("admin_download_id"), key: "id"},
        { label: t("admin_download_full_name"), key: "name"},
        { label: t("admin_download_user_name"), key: "username" },
        { label: t("admin_download_email"), key: "email" },
        { label: t("admin_download_created_on"), key: "created_at"},
        { label: t("admin_download_deactivation_date"), key: "updated_at"},
        { label: t("admin_download_roles"), key: "role" },
    ];

    return (
        <Box>
            <Fragment>
                <Box className="frame_border">
                    <Grid className="action_panel">
                        <Link style={{ textDecoration: 'none'}} to={`${path}/addUser`}>
                            <Button
                                type="button"
                                className={classes.button}
                            >
                                {t("admin_lang_adduser")}
                            </Button>
                        </Link>
                        <CSVLink
                            data={downloadList}
                            headers={headers}
                            className={classes.Link}
                            separator=";"
                            filename="exportUtilisateurs.csv"
                        >
                            <Button type="button" className={classes.button}>
                                {t("user_download_file")}
                            </Button>
                        </CSVLink>
                    </Grid>
                    <Grid className="content_panel">
                        <CustomFilterComponent
                            data-testid="customFiler"
                            allFilters={useFilters.userListFilters}
                            genericDispatcher={genericDispatcher}
                            module_name={'Users'}
                            initData={selectedFilters || undefined}
                        />
                    </Grid>
                    {isDelete !== "" && (
                        <Typography component="p" className={isDelete['success'] ? "success_msg": "error_msg"}>
                            {isDelete.message}
                        </Typography>
                    )}
                    <EnhancedDataGridTableComponent
                        storedState={storedState}
                        allListRows={alluserRows}
                        headCells={headCells}
                        flagID={0}
                        handleDeleteUser={handleDeleteUser}
                        totalRows={totalRows}
                        callback={callback}
                        page={page}
                        setPage={setPage}
                    />
                </Box>
            </Fragment>
        </Box>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
    >
        {value === index && (
        <Box
            sx={{
                margin: "6px 6px 6px 6px",
            }}
        >
            {children}
        </Box>
        )}
    </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

//Styles Area
const useStyle = makeStyles({
    Link: {
        marginLeft: "20px",
        "&:hover": {
            textDecoration: "none!important",
        },
    },
    button: {
        "&.MuiButton-root": {
            backgroundImage: "linear-gradient(to bottom, #FFFFFF 5%, #E2E9F9 70%)",
            color: "black",
            fontWeight: "bold",
            textDecoration: 'none',
        },
    },
    tabs: {
        width: "400px",
        borderRadius: "10px 10px 0px 0px",
        "& .MuiTab-root": {
            whiteSpace: "nowrap",
            display: "block",
            color: "white",
            backgroundColor: "#2F3D92",
            margin: "5px 5px 0px 0px",
            borderRadius: "6px 6px 0px 0px",
            textTransform: "capitalize",
            fontWeight: "bold",
            "&:hover": {
            color: "#FFAB19 !important",
            },
        },
        "& .Mui-selected": {
            color: "#2F3D92 !important",
            backgroundColor: "#D0DCF9",
            fontWeight: "bold",
        },
    },
});
 
export default UsersListComponent;