import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, FormControlLabel, Switch } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ConfigAPI } from "../../apis/ConfigAPI";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const FileInput = ({t, name, classes, setFieldValue, initialValues}) => {
  return <><Button
    variant="contained"
    className={classes.button}
    component="label"
    startIcon={<CloudUploadIcon />}
  >
    {t("upload_file")}
    <input
      onChange={(event) => {
        setFieldValue(name, event.target.files[0]);
      }}
      name={name}
      type="file"
      hidden
      accept="image/png, image/jpeg"
    />
  </Button>
  {
    initialValues[name] && <img className={classes.image} src={initialValues[name]} />
  }
  </>
}

export default (props) => {
  console.log(props)
  const classes = useStyle();
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const getConfiguration = () => {
    ConfigAPI.get()
    .then((response) => {
      setInitialValues(response)
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const handleUpdateConfig = async (values, { setStatus }) => {
    const formData = new FormData();

    _.forEach(values, (val, key) => {
      if (['customer_logo', 'infra_manag_logo'].includes(key)) {
        if (val instanceof File) {
          formData.append(key, val);
        }
      } else {
        formData.append(key, val);
      }
    })
    
    ConfigAPI.update(formData)
    .then((response) => {
      setStatus({
        sent: true,
        msg: response.message,
      });
      setInitialValues(response.config)
    })
    .catch((error) => {
      setStatus({
        sent: true,
        msg: error.message,
      });
    });
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    initialValues ? <Grid className="content_panel">
      <Typography component="h1">{t("application_management")}</Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpdateConfig}
      >
        {({ handleChange, setFieldValue, status, values, errors }) => (
          <Form>
            {status && (
              <Typography
                component="p"
                className={`${status.sent ? "success_msg" : "error_msg"}`}
              >
                {status.msg}
              </Typography>
            )}
            {errors && (
              <Typography component="p" className="error_msg">
                {errors.msg}
              </Typography>
            )}
            <Grid xs={6}>
              <Grid container className='min_height p_10'>
                  <Grid item xs={6} >
                      <b className='p_t_7'>{t("multiple_languages")}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <Field as={Switch} onChange={handleChange} type="checkbox" name="multi_language" />
                  </Grid>
              </Grid>
              <Grid container className='min_height bg_color p_10'>
                  <Grid item xs={6} >
                    <b className='p_t_7'>{t("customer_logo")}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <FileInput
                      name="customer_logo"
                      t={t}
                      classes={classes}
                      setFieldValue={setFieldValue}
                      initialValues={initialValues}
                    ></FileInput>
                  </Grid>
              </Grid>
              <Grid container className='min_height p_10'>
                  <Grid item xs={6} >
                    <b className='p_t_7'>{t("infra_manag_logo")}</b>
                  </Grid>
                  <Grid item xs={6}>
                    <FileInput
                      name="infra_manag_logo"
                      t={t}
                      classes={classes}
                      setFieldValue={setFieldValue}
                      initialValues={initialValues}
                    ></FileInput>
                  </Grid>
              </Grid>
            </Grid>
            <Button
              type="submit"
              data-testid="submit"
              className={classes.button}
            >
                {t("btn_update_en_fr")}
            </Button>
          </Form>
        )}
      </Formik>
    </Grid> : null
  );
};

//Styles Area
const useStyle = makeStyles({
  button: {
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: "5px",
    },
  },
  image: {
    height: 30,
    width: 'auto',
    verticalAlign: 'middle'
  }
});
