function useFilters() {
    // User List
    const userListFilters = {
        All: {
            type: "all",
        },
        label_username_en_fr: {
            type: "string",
            operators: {
                startsWith: "username__istartswith",
                endsWith: "username__endswith",
                equalTo: "username",
                contains: "username__icontains",
            }
        },
        FirstName: {
            type: "string",
            operators: {
                startsWith: "first_name__istartswith",
                endsWith: "first_name__endswith",
                equalTo: "first_name",
                contains: "first_name__icontains",
            }
        },
        LastName: {
            type: "string",
            operators: {
                startsWith: "last_name__istartswith",
                endsWith: "last_name__endswith",
                equalTo: "last_name",
                contains: "last_name__icontains",
            }
        },
        Email: {
            type: "string",
            operators: {
                startsWith: "email__istartswith",
                endsWith: "email__endswith",
                equalTo: "email",
                contains: "email__icontains",
            }
        },
        CreatedOn: {
            type: "date",
            operators: {
                "isAfter": "created_at__gt",
                "equalTo": "created_at__date",
                "isBefore": "created_at__lt",
            }
        },
        activation_status: {
            type: "select",
            operators: {
                isActive: "is_active"
            },
            options: [
                {
                    val: 'True',
                    name: 'active'
                },
                {
                    val: 'False',
                    name: 'inactive'
                },
            ]
        },
        Roles: {
            type: "select",
            operators: {
                roleId: "role__id",
            },
            options: [
                {
                    val: 2,
                    name: 'olm_user'
                },
                {
                    val: 3,
                    name: 'configurator'
                },
                {
                    val: 1,
                    name: 'admin'
                }
            ]
        },
    };

    //Fleet List
    const fleetListFilters = {
        All: {
            type: "all",
        },
        FleetName: {
            type: "string",
            operators: {
                startsWith: "fleetname__istartswith",
                endsWith: "fleetname__endswith",
                equalTo: "fleetname",
                contains: "fleetname__icontains",
            }
        },
        SoftwareNo: {
            type: "string",
            operators: {
                startsWith: "sw_identification_number__istartswith",
                endsWith: "sw_identification_number__endswith",
                equalTo: "sw_identification_number",
                contains: "sw_identification_number__icontains",
            }
        },
        Version: {
            type: "string",
            operators: {
                startsWith: "version__istartswith",
                endsWith: "version__endswith",
                equalTo: "version",
                contains: "version__icontains",
            }
        },
        CreatedOn: {
            type: "date",
            operators: {
                "isAfter": "added_on__gt",
                "equalTo": "added_on__date",
                "isBefore": "added_on__lt",
            }
        },
    };

    // Gear List
    const gearListFilters = {
        All: {
            type: "all",
        },
        equipment_id: {
            type: "string",
            operators: {
                startsWith: "equipment_id__istartswith",
                endsWith: "equipment_id__endswith",
                equalTo: "equipment_id",
                contains: "equipment_id__icontains",
            }
        },
        uic_id: {
            type: "string",
            operators: {
                startsWith: "uic_id__istartswith",
                endsWith: "uic_id__endswith",
                equalTo: "uic_id",
                contains: "uic_id__icontains",
            }
        },
        sncf_number: {
            type: "string",
            operators: {
                startsWith: "sncf_number__istartswith",
                endsWith: "sncf_number__endswith",
                equalTo: "sncf_number",
                contains: "sncf_number__icontains",
            }
        },
        trainset_number: {
            type: "string",
            operators: {
                startsWith: "trainset_number__istartswith",
                endsWith: "trainset_number__endswith",
                equalTo: "trainset_number",
                contains: "trainset_number__icontains",
            }
        },
        FleetName: {
            type: "string",
            operators: {
                startsWith: "fleet__fleetname__istartswith",
                endsWith: "fleet__fleetname__endswith",
                equalTo: "fleet__fleetname",
                contains: "fleet__fleetname__icontains",
            }
        },
        OLM_Active_status: {
            type: "select",
            operators: {
                isActive: "config__is_active",
            },
            options: [
                {
                    val: 'True',
                    name: 'yes'
                },
                {
                    val: 'False',
                    name: 'no'
                },
            ]
        },        
        config_5beach_nidc: {
            type: "nidc",
            operators: {
                "isAfter": "following",
                "equalTo": "same",
                "isBefore": "preceding",
            }
        },
        Config_Lower_limit: {
            type: "string",
            operators: {
                "isAfter": "config__gutMlowTh__gt",
                "equalTo": "config__gutMlowTh",
                "isBefore": "config__gutMlowTh__lt",
            }
        },
        Config_Upper_limit: {
            type: "string",
            operators: {
                "isAfter": "config__gutMupTh__gt",
                "equalTo": "config__gutMupTh",
                "isBefore": "config__gutMupTh__lt",
            }
        },
        Config_NoMSG: {
            type: "string",
            operators: {
                "isAfter": "config__msgNumber__gt",
                "equalTo": "config__msgNumber",
                "isBefore": "config__msgNumber__lt",
            }
        },
        CFG_Version: {
            type: "string",
            operators: {
                startsWith: "config__configFileVersion__istartswith",
                endsWith: "config__configFileVersion__endswith",
                equalTo: "config__configFileVersion",
                contains: "config__configFileVersion__icontains",
            }
        },
        target_server: {
            type: "select",
            operators: {
                serverType: "config__server__server_type",
            },
            options: [
                {
                    val: '0',
                    name: 'integration'
                },
                {
                    val: '1',
                    name: 'production'
                },
            ]
        },
        Config_Forcing: {
            type: "select",
            operators: {
                forcing: "config__forcing",
            },
            options: [
                {
                    val: 'True',
                    name: 'yes'
                },
                {
                    val: 'False',
                    name: 'no'
                },
            ]
        },
        Unknown: {
            type: "select",
            operators: {
                forcing: "unknown",
            },
            options: [
                {
                    val: 'True',
                    name: 'yes'
                },
                {
                    val: 'False',
                    name: 'no'
                },
            ]
        },
    };

    // Message List
    const messageListFilters = {
        All: {
            type: "all",
        },
        TelegramID: {
            type: "string",
            operators: {
                startsWith: "id__istartswith",
                endsWith: "id__endswith",
                equalTo: "id",
                contains: "id__icontains",
            }
        },
        equipment_id: {
            type: "string",
            operators: {
                startsWith: "gear__equipment_id__istartswith",
                endsWith: "gear__equipment_id__endswith",
                equalTo: "gear__equipment_id",
                contains: "gear__equipment_id__icontains",
            }
        },
        uic_id: {
            type: "string",
            operators: {
                startsWith: "gear__uic_id__istartswith",
                endsWith: "gear__uic_id__endswith",
                equalTo: "gear__engine_id",
                contains: "gear__uic_id__icontains",
            }
        },
        sncf_number: {
            type: "string",
            operators: {
                startsWith: "gear__sncf_number__istartswith",
                endsWith: "gear__sncf_number__endswith",
                equalTo: "gear__sncf_number",
                contains: "gear__sncf_number__icontains",
            }
        },
        trainset_number: {
            type: "string",
            operators: {
                startsWith: "gear__trainset_number__istartswith",
                endsWith: "gear__trainset_number__endswith",
                equalTo: "gear__trainset_number",
                contains: "gear__trainset_number__icontains",
            }
        },
        NIDC: {
            type: "string",
            operators: {
                startsWith: "nid_c__istartswith",
                endsWith: "nid_c__endswith",
                equalTo: "nid_c",
                contains: "nid_c__icontains",
            }
        },
        NIDBG: {
            type: "string",
            operators: {
                startsWith: "nid_bg__istartswith",
                endsWith: "nid_bg__endswith",
                equalTo: "nid_bg",
                contains: "nid_bg__icontains",
            }
        },
        ErrorNo : {
            type: "string",
            operators: {
                "isAfter": "error_number__gt",
                "equalTo": "error_number",
                "isBefore": "error_number__lt",
            }
        },
        CreatedOn: {
            type: "date",
            operators: {
                "isAfter": "created_at__gt",
                "equalTo": "created_at__date",
                "isBefore": "created_at__lt",
            }
        },
    };

    const telegramListFilters = {
        All: {
            type: "all",
        },
        TelegramID: {
            type: "string",
            operators: {
                startsWith: "id__istartswith",
                endsWith: "id__endswith",
                equalTo: "id",
                contains: "id__icontains",
            }
        },
        equipment_id: {
            type: "string",
            operators: {
                startsWith: "gear__equipment_id__istartswith",
                endsWith: "gear__equipment_id__endswith",
                equalTo: "gear__equipment_id",
                contains: "gear__equipment_id__icontains",
            }
        },
        uic_id: {
            type: "string",
            operators: {
                startsWith: "gear__uic_id__istartswith",
                endsWith: "gear__uic_id__endswith",
                equalTo: "gear__engine_id",
                contains: "gear__uic_id__icontains",
            }
        },
        sncf_number: {
            type: "string",
            operators: {
                startsWith: "gear__sncf_number__istartswith",
                endsWith: "gear__sncf_number__endswith",
                equalTo: "gear__sncf_number",
                contains: "gear__sncf_number__icontains",
            }
        },
        trainset_number: {
            type: "string",
            operators: {
                startsWith: "gear__trainset_number__istartswith",
                endsWith: "gear__trainset_number__endswith",
                equalTo: "gear__trainset_number",
                contains: "gear__trainset_number__icontains",
            }
        },
        FleetName: {
            type: "string",
            operators: {
                startsWith: "gear__fleet__fleetname__istartswith",
                endsWith: "gear__fleet__fleetname__endswith",
                equalTo: "gear__fleet__fleetname",
                contains: "gear__fleet__fleetname__icontains",
            }
        },
        Config_NoMSG: {
            type: "number",
            operators: {
                greaterThan: 'count__gt',
                equalTo: 'count',
                lowerThan: 'count__lt',
            }
        },
        ErrorNo : {
            type: "string",
            operators: {
                "isAfter": "messages__error_number__gt",
                "equalTo": "messages__error_number",
                "isBefore": "messages__error_number__lt",
            }
        },
        telegram_received_on: {
            type: "date",
            operators: {
                "isAfter": "received_on__gt",
                "equalTo": "received_on__date",
                "isBefore": "received_on__lt",
            }
        },
        telegram_timestamp: {
            type: "date",
            operators: {
                "isAfter": "messages__timestamp__gt",
                "equalTo": "messages__timestamp__date",
                "isBefore": "messages__timestamp__lt",
            }
        },
        telegram_sent_status: {
            type: "select",
            operators: {
                sent: "sent",
            },
            options: [
                {
                    val: 'True',
                    name: 'sent'
                },
                {
                    val: 'False',
                    name: 'rejected'
                },
            ]
        },
    };

    // Monitoring List
    const monitoringListFilters = {
        All: {
            type: "all",
        },
        equipment_id: {
            type: "string",
            operators: {
                startsWith: "gear__equipment_id__istartswith",
                endsWith: "gear__equipment_id__endswith",
                equalTo: "gear__equipment_id",
                contains: "gear__equipment_id__icontains",
            }
        },
        uic_id: {
            type: "string",
            operators: {
                startsWith: "gear__uic_id__istartswith",
                endsWith: "gear__uic_id__endswith",
                equalTo: "gear__engine_id",
                contains: "gear__uic_id__icontains",
            }
        },
        sncf_number: {
            type: "string",
            operators: {
                startsWith: "gear__sncf_number__istartswith",
                endsWith: "gear__sncf_number__endswith",
                equalTo: "gear__sncf_number",
                contains: "gear__sncf_number__icontains",
            }
        },
        trainset_number: {
            type: "string",
            operators: {
                startsWith: "gear__trainset_number__istartswith",
                endsWith: "gear__trainset_number__endswith",
                equalTo: "gear__trainset_number",
                contains: "gear__trainset_number__icontains",
            }
        },
        LastComm: {
            type: "date",
            operators: {
                "isAfter": "datetime__gt",
                "equalTo": "datetime__date",
                "isBefore": "datetime__lt",
            }
        },
    };

    const journalFilters = {
        All: {
            type: "all",
        },
        Message: {
            type: "select",
            operators: {
                message: "message",
            },
            options: []
        },
        Gravity: {
            type: "select",
            operators: {
                gravity: "gravity",
            },
            options: [
                {
                    val: 'Information',
                    name: 'information'
                },
                {
                    val: 'Error',
                    name: 'error'
                },
            ]
        },
        Date: {
            type: "date",
            operators: {
                "isAfter": "date__gt",
                "equalTo": "date__date",
                "isBefore": "date__lt",
            }
        },
        Username: {
            type: "select",
            operators: {
                username: ["created_by__username", "additional_data__username", "additional_data__current_user_name"]
            },
            operators: {
                startsWith: ["created_by__username__istartswith", "additional_data__username__istartswith"],
                endsWith: "created_by__username__endswith",
                equalTo: "created_by__username",
                contains: "created_by__username__icontains",
            }
        },
        equipment_id: {
            type: "select",
            operators: {
                gear: "additional_data__gear"
            },
            options: []
        },
        target_server_cff: {
            type: "select",
            operators: {
                server_type: "additional_data__server_type",
            },
            options: [
                {
                    val: 'Integration',
                    name: 'Integration'
                },
                {
                    val: 'Production',
                    name: 'Production'
                },
            ]
        }
    };

    return {
        userListFilters,
        fleetListFilters,
        gearListFilters,
        messageListFilters,
        telegramListFilters,
        monitoringListFilters,
        journalFilters,
    };
}

export default useFilters();