// React and Redux declarations
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { makeStyles } from "@mui/styles";
import useValidation from "../../common/ValidationSchema";
import BackButton from "../../common/BackButton";
import { FleetAPI } from "../../../apis/FleetAPI";

const FleetFormComponent = () => {
    const classes = useStyle();
    const history = useHistory();
    const params = useParams();
    const { t } = useTranslation();
    const [initialValues, setInitialValues] = useState({
        fleetname: "",
        sw_identification_number: "",
        version: "",
    });

    const fleet_id = params.fleet_id;

    const getFleet = () => {
        FleetAPI.get({ fleet_id })
        .then((response) => {
            if (response.data !== null) {
                setInitialValues({
                    fleetname: response.data[0]['fleetname'],
                    sw_identification_number: response.data[0]['sw_identification_number'],
                    version: response.data[0]['version']
                })
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        fleet_id ? getFleet() : "";
    }, [])

    // Add Fleets process
    const [isLoading, setLoading] = useState(false);
    const handleFleetsRegister = async (values, { setStatus, resetForm }) => {
        setStatus({})
        setLoading(true);
        // Update case
        if (fleet_id) {
            FleetAPI.update(values, fleet_id, true)
            .then((response) => {
                setLoading(false);
                if (response !== undefined) {
                    setStatus({
                        sent: true,
                        msg: response.message,
                    });
                } else {
                    setStatus({
                        sent: false,
                        msg: t("add_fleet_error_message"),
                    });
                }
            }).catch((error) => {
                setLoading(false);
                setStatus({
                    sent: true,
                    msg: error.message,
                });
            });
        } else {
            // Create case
            FleetAPI.create(values, true)
            .then((response) => {
                setLoading(false);
                if (response !== undefined) {
                    setStatus({
                        sent: true,
                        msg: response.message,
                    });
                    setTimeout(() => {
                        history.push(
                            '/admin/fleets/' + response.fleet_id
                        )
                    }, 1000)
                } else {
                    setStatus({
                        sent: false,
                        msg: t("add_fleet_error_message"),
                    });
                }
            }).catch((error) => {
                setLoading(false);
                setStatus({
                    sent: true,
                    msg: error.message,
                });
            });
        }
    };

    return (
        <Box className="frame_border">
            <Typography component="h1" className="font-2rem">
                {fleet_id ? `${t("hightlight_Fleet_Edit_Area")} ${initialValues.fleetname}` : t("hightlight_Fleet_Add_Area")}
            </Typography>
            <Formik
                initialValues={{...initialValues}}
                enableReinitialize={true}
                validationSchema={useValidation.validateAddFleets(t)}
                onSubmit={handleFleetsRegister}
                
            >
                {({ handleChange, status, values, errors }) => (
                <Form data-testid="form" aria-invalid="true">
                    {status && (
                        <Typography
                            component="p"
                            className={`${status.sent ? "success_msg" : "error_msg"}`}
                        >
                            {status.msg}
                        </Typography>
                    )}
                    <Grid className="content_panel">
                        <Grid className="head_table">
                            <Grid container>
                                <Grid item xs={12} md={8} lg={6}>
                                    <Grid container className='min_height p_10'>
                                        <Grid item xs={5} >
                                            <b className='p_t_7'>{t("admin_fleet_name")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Field
                                                name="fleetname"
                                                data-testid="fleetname"
                                                value={values.fleetname || ""}
                                                onChange={handleChange}
                                                type="text"
                                                className="input_txt"
                                            />
                                            <ErrorMessage component="div" className="error_msg" name="fleetname" />
                                        </Grid>
                                    </Grid>
                                    <Grid container className='min_height bg_color p_10'>
                                        <Grid item xs={5} >
                                            <b className='p_t_7'>{t("fleet_soft_id")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Field
                                                name="sw_identification_number"
                                                data-testid="sw_identification_number"
                                                value={values.sw_identification_number || ""}
                                                onChange={handleChange}
                                                type="text"
                                                className="input_txt"
                                            />
                                            <ErrorMessage component="div" className="error_msg" name="sw_identification_number" />
                                        </Grid>
                                    </Grid>
                                    <Grid container className='min_height p_10'>
                                        <Grid item xs={5} >
                                            <b className='p_t_7'>{t("fleet_version")} <span className='asterisk'> {'*'} </span></b>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Field
                                                name="version"
                                                data-testid="version"
                                                value={values.version || ""}
                                                onChange={handleChange}
                                                type="text"
                                                className="input_txt"
                                            />
                                            <ErrorMessage component="div" className="error_msg" name="version" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7}></Grid>
                            </Grid> 
                        </Grid>
                    </Grid>
                    {isLoading ? (
                    <Button disabled className={classes.button}>
                        <CircularProgress size={8} />
                        {t("adduser_button_save")}
                    </Button>
                    ) : (
                    <Button
                        type="submit"
                        data-testid="submit"
                        className={classes.button}
                    >
                        {t("custom_filter_save_button")}
                    </Button>
                    )}
                </Form>
                )}
            </Formik>
            <BackButton url={'/admin/fleets'} text={t("back_to_administration")} />
        </Box>
    );
};

//Styles Area
const useStyle = makeStyles({
  button: {
    width: "120px",
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: 0,
    },
  },
});

export default FleetFormComponent;
