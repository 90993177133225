// React and Redux declarations
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ChangePasswordComponent from "../common/ChangePassword";
// import form related actions and declarations
import {
  Modal,
  Box,
  Grid,
  Select,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// const [showPreferenceModal, setShowPreferenceModal] = useState(false);

const PreferenceComponent = (props) => {
  // call styles for controls
  const classes = useStyles();
  const config = JSON.parse(localStorage.getItem('configOLM'))
  // Call i18n translate function
  const { t } = useTranslation();

  // Language Options
  const languageMap = {
    fr: { label: t("lang_fr"), dir: "ltr", active: true },
    // en: { label: t("lang_en"), dir: "ltr", active: true },
    // de: { label: t("lang_ge"), dir: "ltr", active: false },
  };
  // Language Change Section
  const [langvalue, setSelectvalue] = useState("en");
  const handleSelectChange = (event) => {
    localStorage.setItem("language", JSON.stringify(event.target.value));
    localStorage.setItem("lang_refresh", true);
    setSelectvalue(event.target.value);
  };
  // const handleChangePassword = () => {
  // console.log('@here');
  //   setShowModalComponent(true);
  //   setOpen(true);
  // };


  return (
    <Modal open={props.open}  backdrop="static">
      <Box sx={modalBox}>
        <Grid>
          <Grid>
            <Typography component="h1">{t("home_lang_pref")}</Typography>
            <Typography component="p" className="success_msg">
              {props.langVal}
            </Typography>
            <Grid className="content_panel">
              { config.multi_language && t("label_language") }
              { config.multi_language && <Select
                data-testid="language"
                onChange={handleSelectChange}
                value={langvalue}
                className="select_preference"
              >
                {Object.keys(languageMap)?.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    onClick={() => {
                      i18next.changeLanguage(item);
                    }}
                  >
                    {languageMap[item].label}
                  </MenuItem>
                ))}
              </Select> }
              <Grid>
                <Button
                  type="button"
                  onClick={props.handleChangePassword}
                  className={classes.button}
                >
                  {t("change_password_en_fr")}
                </Button>
              </Grid>
            </Grid>
            {
              config.multi_language && <Button
                type="button"
                data-testid="saveChanges"
                onClick={props.handleSaveChanges}
                className={`${classes.button} ${classes.saveButton}`}
              >
                {t("modifier_password_en_fr")}
              </Button>
            }
            <Button
              type="button"
              onClick={props.handleClose}
              className={classes.button}
            >
              {t("common_lang_cancel")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

//Styles Area
const useStyles = makeStyles({
  button: {
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: 0,
    },
  },
  saveButton: {
    "&.MuiButton-root": {
      marginRight: '10px'
    }
  }
});

const modalBox = {
  position: "absolute",
  top: "20px",
  right: "20px",
  bgcolor: "background.paper",
  border: "2px solid #D0DCF9",
  boxShadow: 24,
  outline: 'none',
  padding: '10px 20px',
};

export default PreferenceComponent;
