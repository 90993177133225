import * as Yup from "yup";

function useValidation() {
  
  // Addusers
  const validateAddUsers = (t) => Yup.object().shape({
    first_name: Yup.string().required(t("user_validation_first_name_required")),
    last_name: Yup.string().required(t("user_validation_last_name_required")),
    email: Yup.string()
      .email(t("user_validation_invalid_email"))
      .required(t("user_validation_email_required")),
    username: Yup.string().required(t("user_validation_username_required")),
    role: Yup.string().required(t("user_validation_role_required")),
  });

  // Edit user
  const validateEditUsers = (t) => Yup.object().shape({
    first_name: Yup.string().required(t("user_validation_first_name_required")),
    last_name: Yup.string().required(t("user_validation_last_name_required")),
    username: Yup.string().required(t("user_validation_username_required")),
    role: Yup.string().required(t("user_validation_role_required")),
    email: Yup.string()
      .email(t("user_validation_invalid_email"))
      .required(t("user_validation_email_required")),
  });

  // ChangePassword
  const validateChangePassword = (t) => Yup.object().shape({
    oldPassword: Yup.string().required(t("change_password_old_pass_required")),
    newPassword: Yup.string().required(t("change_password_new_pass_required")),
    confirmPassword: Yup.string().required(t("change_password_confirm_pass_required")),
  });

  //CustomFilter
  const validateCustomFilter = (t) => Yup.object().shape({
    txtSave: Yup.string().required(
      t("validate_custom_filter")
    ),
  });

  //ForgotPassword
  const validateForgotPassword = (t) => Yup.object().shape({
    username: Yup.string().required(t("validate_custom_password")),
  });

  //Login
  const validateLogin = (t) => Yup.object().shape({
    username: Yup.string().required(t("validate_login_username")),
    password: Yup.string().required(t("validate_login_password")),
  });

  //ServersOLM
  const validateServers = (t) => Yup.object().shape({
    serverLogin: Yup.string().required(t("validate_servers_login")).max(12, t("validate_servers_login_max")),
    serverPass: Yup.string().required(t("validate_servers_password")).max(30, t("validate_servers_password_max")),
    serverUrl: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter valid URL!"
      )
      .required("URL is required!"),
    timeout: Yup.number().integer().min(1).required(),
    frequency: Yup.number().integer().min(60).required()
  });

  Yup.addMethod(Yup.array, 'nidc', function (message) {
    return this.test('sorted', message, function (list) {
        return list[0] <= list[1]
    });
  });

  // OLM Configuration
  const validateOLMConfiguration = (t) => Yup.object().shape({
    equipment_id: Yup.string()
      .required(t("validate_olm_config_equipment_id_required"))
      .min(2, t("validate_olm_config_equipment_id_min_2"))
      .max(12, t("validate_olm_config_equipment_id_max_12")),
    uic_id: Yup.string()
      .required(t("validate_olm_config_uic_id_required"))
      .min(2, t("validate_olm_config_uic_id_min_2"))
      .max(12, t("validate_olm_config_uic_id_max_12")),
    sncf_number: Yup.string()
      .required(t("validate_olm_config_motor_id_required"))
      .min(1, t("validate_olm_config_motor_id_min_2"))
      .max(6, t("validate_olm_config_motor_id_max_6")),
    trainset_number: Yup.string()
      .required(t("trainset_number_required"))
      .min(1, t("trainset_number_min_1"))
      .max(4, t("trainset_number_max_4")),
    fleet: Yup.string()
      .required(t("validate_olm_config_fleet_required"))
      .matches(/^[0-9]+$/, t("validate_olm_config_invalid_fleet")),
    gutMlowTh: Yup.number()
      .min(1, t('validate_1_200')).max(200, t('validate_1_200'))
      .required(t("validate_olm_config_gutm_lw_required")),
    gutMupTh: Yup.number()
      .min(1, t('validate_1_200')).max(200, t('validate_1_200'))
      .required(t("validate_olm_config_gutm_up_required")),
    msgNumber: Yup.number()
      .min(1, t('validate_olm_max_msg'))
      .max(50, t('validate_olm_max_msg'))
      .required(t("validate_olm_config_msg_required")),
    configFileVersion: Yup.string()
      .required(t("validate_olm_config_version_required"))
      .matches(
        /^(?!0\d)(?:\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(?!0\d)(?:\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(?!0\d)(?:\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/,
        t("validate_olm_config_version_valid_config")
      ),
    config_file_name: Yup.string()
      .required(t("validate_olm_config_server_req_file_name"))
      .matches(/[\w-]+\.\w+/, t("validate_olm_config_server_valid_file_name"))
      .min(3, t("validate_olm_config_server_len_file_name"))
      .max(34, t("validate_olm_config_server_len_file_name")),
    nidc_value: Yup.array().min(1).of(
      Yup.array().min(2).of(
        Yup.number().min(1, t('nidc_min_value')).max(1023, t('nidc_max_value'))
      ).nidc(`${t('nidc_min_max')}\n`)
    )
  });

  // AddFleets
  const validateAddFleets  = (t) => Yup.object().shape({
    fleetname: Yup.string().required(t("validate_add_fleets_fleet_name")),
    sw_identification_number: Yup.string().required(t("validate_add_fleets_sw_no")),
    version: Yup.string().required(t("validate_olm_config_version_required"))
    .matches(
      /^([0-9]{1}[.]{1}[0-9]{1}[.]{1}[0-9]{1})$/,
      t("validate_olm_config_version_valid_config")
    ),
  });

  //AddGears
  const validateAddGears = (t) =>Yup.object().shape({
    equipment_id: Yup.string()
      .required(t("validate_olm_config_equipment_id_required"))
      .min(2, t("validate_olm_config_equipment_id_min_2"))
      .max(8, t("validate_olm_config_equipment_id_max_8")),
    uic_id: Yup.string()
      .required(t("validate_olm_config_uic_id_required"))
      .min(2, t("validate_olm_config_uic_id_min_2"))
      .max(8, t("validate_olm_config_uic_id_max_8")),
    sncf_number: Yup.string()
      .required(t("validate_olm_config_motor_id_required"))
      .min(2, t("validate_olm_config_motor_id_min_2"))
      .max(8, t("validate_olm_config_motor_id_max_8")),
    enginType: Yup.string().required(t("validate_olm_config_engine_type_required")),
  });

  //Comments
  const validateComments = (t) => Yup.object().shape({
    comment: Yup.string().required(t("validate_comments")),
  });

  return {
    validateAddUsers,
    validateEditUsers,
    validateChangePassword,
    validateCustomFilter,
    validateForgotPassword,
    validateLogin,
    validateServers,
    validateAddFleets,
    validateAddGears,
    validateComments,
    validateOLMConfiguration,
  };
}

export default useValidation();
