export default function authHeader() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
        // Header
        return {
            'Authorization': 'Bearer ' + user.token,
            "content-type": "application/json"
        };
    } else {
        return {};
    }
}