import { api } from "./config/axiosConfig"
import { defineCancelApiObject } from "./config/axiosUtils"

export const ProfileAPI = {
    list: async function (cancel = false) {
        const url = `/users/role_list/`
        const response = await api.request({
            url: url,
            method: "GET",
            signal: cancel ? cancelApiObject[this.list.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}

const cancelApiObject = defineCancelApiObject(ProfileAPI)