import React, { useState, useEffect, Fragment } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Box,
  ListItemText,
  Button,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useValidation from "../../common/ValidationSchema";
import { useHistory } from 'react-router-dom';
import BackButton from "../../common/BackButton";
import { ProfileAPI } from "../../../apis/ProfileAPI";
import { UserAPI } from "../../../apis/UserAPI";

const AddUsersComponent = () => {
    // call styles for controls
    const classes = useStyle();
    // language area
    const { t } = useTranslation();
    const history = useHistory();

    const initialValues = {
        username: "",
        first_name: "",
        last_name:"",
        email: "",
        is_active: 1,
        role: []
    };

    // Role List process
    const [roleRows, setRoleRows] = useState([]);
    const getRoleList = () => {
        ProfileAPI.list(true)
        .then((response) => {
            if (response !== null) {
                let jsonData = response.data;
                setRoleRows(_.map(jsonData, role => {
                    return {
                        id: role.id,
                        name: t(role.name.replace(' ', '_').toLowerCase())
                    }
                }));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    useEffect(() => {
        getRoleList()
    }, []);

    // Register process
    const [isLoading, setLoading] = useState(false);

    const handleRegister = async (values, { setStatus, resetForm }) => {
        setLoading(true);
        UserAPI.create({
                username: values.username,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                role: [values.role],
                is_active: values.is_active,
            }
        ).then((response) => {
            setLoading(false);
            if (response !== null) {
                const message = response.message
                setStatus({
                    sent: true,
                    success: true,
                    msg: t(message),
                });
                setTimeout(() => {
                    history.push('/admin/users/editUser/' + response.id + '?action=post-create')
                }, 1000)
            } else {
                setStatus({
                    sent: true,
                    success: false,
                    msg: t(response.data),
                });
                getRoleList()
            }
        }).catch((error) => {
            setLoading(false);
            setStatus({
                sent: true,
                msg: t(error.message),
            });
            getRoleList()
        });
  };

  return (
    <Box className="frame_border">
        <Typography component="h1" className="font-2rem">{t("hightlight_Admin_Add_Area")}</Typography>
        <Formik
            initialValues={initialValues}
            validationSchema={useValidation.validateAddUsers(t)}
            onSubmit={handleRegister}
        >
            {({ handleChange, status, values, errors }) => (
            <Form data-testid="form">
                {status && (
                <Typography
                    component="p"
                    className={`${status.sent && status.success ? "success_msg" : "error_msg"}`}
                >
                    {status.msg}
                </Typography>
                )}
                <Grid className="content_panel">
                <Grid className="head_table">
                    <Grid container>
                    <Grid item xs={5}>
                        <Grid container className='min_height p_10'>
                        <Grid item xs={4} >
                            <b className='p_t_7'>{t("label_username_en_fr")} <span className='asterisk'> {'*'} </span></b>
                        </Grid>
                        <Grid item xs={8}>
                            <Field
                                name="username"
                                data-testid="username"
                                value={values.username}
                                onChange={handleChange}
                                type="text"
                                className="input_txt"
                            />
                            <ErrorMessage component="div" className="error_msg" name="username" />
                        </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                        <Grid item xs={4} >
                            <b className='p_t_7'>{t("label_firstname_en_fr")} <span className='asterisk'> {'*'} </span></b>
                        </Grid>
                        <Grid item xs={8}>
                            <Field
                                name="first_name"
                                data-testid="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                type="text"
                                className="input_txt"
                            />
                            <ErrorMessage component="div" className="error_msg" name="first_name" />
                        </Grid>
                        </Grid>
                        <Grid container className='min_height p_10'>
                        <Grid item xs={4} >
                            <b className='p_t_7'>{t("label_lastname_en_fr")} <span className='asterisk'> {'*'} </span></b>
                        </Grid>
                        <Grid item xs={8}>
                            <Field
                                name="last_name"
                                data-testid="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                type="text"
                                className="input_txt"
                            />
                            <ErrorMessage component="div" className="error_msg" name="last_name" />
                        </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                        <Grid item xs={4} >
                            <b className='p_t_7'>{t("adduser_label_email")} <span className='asterisk'> {'*'} </span></b>
                        </Grid>
                        <Grid item xs={8}>
                            <Field
                                name="email"
                                data-testid="email"
                                value={values.email}
                                onChange={handleChange}
                                type="text"
                                className="input_txt"
                            />
                            <ErrorMessage component="div" className="error_msg" name="email" />
                        </Grid>
                        </Grid>
                        <Grid container className='min_height p_10'>
                        <Grid item xs={4} >
                            <b className='p_t_7'>{t("label_activestatus_en_fr")} <span className='asterisk'> {'*'} </span></b>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                name="is_active"
                                value={values.is_active}
                                data-testid="is_active"
                                onChange={handleChange}
                                className="select_edit_style"
                            >
                                {selectedfnOLM.map((item) => (
                                    <MenuItem data-testid="options" key={item.id} value={item.id} >
                                        {t(item.name)}
                                    </MenuItem>
                            ))}
                            </Select>
                        </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={4} >
                                <b className='p_t_7'>{t("profiles_en_fr")} <span className='asterisk'> {'*'} </span></b>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    value={values.role || ''}
                                    onChange={handleChange}
                                    name="role"
                                    className="select_edit_style"
                                >
                                {roleRows.map((profile) => (
                                    <MenuItem key={profile.id} value={profile.id}>{profile.name}</MenuItem>
                                ))}
                                </Select><br />
                                <ErrorMessage component="div" className="error_msg" name="role" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7}></Grid>

                    </Grid>
                    <Grid>
                    {isLoading ? (
                        <Button
                            disabled
                            className={classes.button}
                        >
                        <CircularProgress size={8} />
                            {t("adduser_button_save")}
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            data-testid="submit"
                            className={`mt_5 ${classes.button}`}
                        >
                            {t("modifier_password_en_fr")}
                        </Button>
                    )}
                    </Grid>
                </Grid>
                </Grid>
            </Form>
            )}
        </Formik>
        <BackButton url={'/admin/users'} text={t("back_to_administration")} />
    </Box>
  );
};

//Styles Area
const useStyle = makeStyles({
  Textbox: {
    "&.MuiTextField-root": {
      backgroundColor: "#FFFFFF",
      width: "150px",
      marginLeft: "10px",
    },
    "& .MuiInputBase-input": {
      padding: "5px",
    },
  },
  button: {
    minWidth: "200px",
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: 0,
      marginTop: 5
    },
  },
});

const selectedfnOLM = [
  { id: 1, name: "active" },
  { id: 0, name: "inactive" },
];

export default AddUsersComponent;
