import {
    Box,
    // Link
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PrevIcon from "../../static/images/previous_on.png";
import { Link } from "react-router-dom";

const useStyle = makeStyles({
    Image: {
        "&.MuiCardMedia-root": {
            width: "20px",
            height: "20px",
        },
    },
    Box: {
        display: 'flex',
        alignItems: 'center'
    },
});

export default (props) => {
    const classes = useStyle();
    return <Box className={`content_panel ${classes.Box}`}>
        <img
            src={PrevIcon}
            alt=""
            className={classes.Image}
        />
        <Link to={props.url}>{props.text}</Link>
    </Box>
}