// React and Redux declarations
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import { Grid, Button, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// To Call the navigation components
import EnhancedDataGridTableComponent from "../../common/EnhancedDataGridTable";
// Image process
import CustomFilterComponent from "../../common/CustomFilter";
import useFilters from "../../common/CommonFilters";
import { setLocalStorage, getLocalStorage } from "../../common/LocalStorage";
import { useRouteMatch, Link } from "react-router-dom";
import { FleetAPI } from "../../../apis/FleetAPI";

const FleetsListComponent = (props) => {
    const { path } = useRouteMatch();
    // call styles for controls
    const classes = useStyle();
    const { t } = useTranslation();

    const headCells = [
        {id: 'Action', label: t('action')},
        { id: "fleetname", label: t("admin_fleet_name") },
        { id: "sw_identification_number", label: t("admin_fleet_sw_id_no") },
        { id: "version", label: t("admin_fleet_version") },
    ];

    const localStateName = 'fleetListState'
    const storedState = getLocalStorage(localStateName);

    /// get all Fleets list
    const [allfleetRows, setAllFleetRows] = useState([]);

    // Items for the backend pagination system
    const [totalRows, setTotalRows] = useState(0)
    const [selectedFilters, setSelectedFilters] = useState(storedState && storedState.selectedFilters || { rows: [] })
    const [page, setPage] = useState(storedState && storedState.page || 1)
    const [perPage, setPerPage] = useState(storedState && storedState.perPage || 10)
    const [orderBy, setOrderBy] = useState(storedState && storedState.orderBy || '-id')
    // Function called by the filters component
    const genericDispatcher = (selectedFilters) => {
        setPage(1)
        setSelectedFilters(selectedFilters)
        setLocalStorage(localStateName, selectedFilters, 1, perPage, orderBy)
        getFleets(selectedFilters, 1, perPage, orderBy);
    };
    // Function called by the table elements (page, per page, order actions)
    const callback = (page, rowsPerPage, orderBy) => {
        setPage(page)
        setPerPage(rowsPerPage)
        setOrderBy(orderBy)
        setLocalStorage(localStateName, selectedFilters, page, rowsPerPage, orderBy)
        getFleets(selectedFilters, page, rowsPerPage, orderBy);
    }

    const getFleets = (selectedFilters, page, rowsPerPage, orderBy) => {
        FleetAPI.list({selectedFilters, page, rowsPerPage, orderBy}, true)
        .then((response) => {
            if (response !== null) {
                // Set the total rows used for the table
                setTotalRows(response.total_rows)
                setAllFleetRows(response.data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        getFleets(selectedFilters, page, perPage, orderBy);
    }, []);

    // Delete fleet
    const [isDelete, setDelete] = useState("");
    const handleDeleteFleet = (rowItemID) => {
        let text = t("delete_fleet_confirmation")
        if (confirm(text) == true) {
            FleetAPI.delete(rowItemID).then((response) => {
                if (response !== undefined) {
                    setDelete(response);
                }
                getFleets(selectedFilters, page, perPage, orderBy);
            });
        }
    };

    return (
        <Box className="frame_border">
            <Fragment>
                <Grid className="action_panel">
                    <Link style={{ textDecoration: 'none'}} to={`${path}/addFleet`}>
                        <Button
                            type="button"
                            className={classes.button}
                            whiteSpace="nowrap"
                        >
                            {t("fleet_add_button")}
                        </Button>
                    </Link>
                </Grid>
                <Grid className="content_panel">
                    <CustomFilterComponent
                        allFilters={useFilters.fleetListFilters}
                        genericDispatcher={genericDispatcher}
                        module_name={'Fleets'}
                        initData={selectedFilters || undefined}
                    />
                </Grid>
                {isDelete !== "" && (
                    <Typography component="p" className={isDelete['success'] ? "success_msg": "error_msg"}>
                        {isDelete.message}
                    </Typography>
                )}
                <EnhancedDataGridTableComponent
                    storedState={storedState}
                    allListRows={allfleetRows}
                    headCells={headCells}
                    handleDeleteFleet={handleDeleteFleet}
                    flagID={1}
                    totalRows={totalRows}
                    callback={callback}
                    page={page}
                    setPage={setPage}
                />
            </Fragment>
        </Box>
    );
};

//Styles Area
const useStyle = makeStyles({
  button: {
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: 0,
      whiteSpace: "nowrap",
      textDecoration: 'none',
      padding: "6px 20px",
    },
  },
});

export default FleetsListComponent;
