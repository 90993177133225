// React and Redux declarations
import React from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import {
  Grid,
  Box,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from 'react-router-dom'
// Image process
import FooterImage from "../../static/images/fy_logo.png";

const NotFoundComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid xs={10} style={{height: '140px'}}>
            <Grid className="top_container">
                <Grid className="container">
                    <Grid className="content_panel">
                        <Typography component="h1">{t("ATESS_SERVER")}</Typography>
                        <Grid className="login_content">
                            <Grid container direction="row" alignItems="stretch" >
                                <Grid item xs={12} className="login_pane">
                                    <Typography component="h1">
                                        {t('404_error')}
                                    </Typography>
                                    <Box className={classes.notFound}>{t('page_not_found')}</Box>
                                    <Box style={{ textAlign: 'center'}}>
                                        <Link style={{ textDecoration: 'none'}} to={`/gears`}>
                                            <Button
                                                type="button"
                                                className={classes.button}
                                            >
                                                {t("return_to_the_home_page")}
                                            </Button>
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="footer_area">
                        <Grid container>
                            <Grid item xs={2}>
                                <CardMedia component="img" image={FooterImage} alt="" />
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography component="p" className={classes.p}>
                                        {t("VERSION")} {process.env.REACT_APP_OLM_VERSION}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

//Styles Area
const useStyles = makeStyles({
    button: {
        width: "250px",
        "&.MuiButton-root": {
            backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
            color: "black",
            fontWeight: "bold",
            border: "1px solid",
            borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
            margin: "1px",
        },
    },
    p: {
        "&.MuiTypography-root": {
            position: "relative",
            padding: "5px",
            float: "right"
        },
    },
    Image: {
        "&.MuiCardMedia-root": {
            width: "175px",
            margin: "5px 0",
        },
    },
    
    Link: {
        "&.MuiTypography-root": {
        color: "#1976d2",
        fontWeight: "bold",
        textDecoration: "none",
        "&:hover": {
            color: "#FFAB19",
            fontWeight: "bold",
            cursor: "pointer",
        },
        },
    },
    Label: {
        "&.MuiFormLabel-root": {
            color: "black",
            fontWeight: "bold",
            margin: "1px 0px 0px 10px",
            padding: "0px",
            lineHeight: "1.5em",
            position: "relative",
            top: "5px",
        },
    },
    notFound: {
        fontSize: '36px',
        textAlign: 'center',
        color: '#2F3D92',
        marginBottom: '20px'
    },
    notFoundSm: {
        fontSize: '20px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    logo: {
        "&.MuiCardMedia-root": {
            width: '300px',
            maxWidth: '100%',
            margin: '50px auto'
        }
    }
});

export default NotFoundComponent;