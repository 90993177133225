// React and Redux declarations
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import {
  Grid,
  FormLabel,
  Link as Link1,
  Button,
  Typography,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { Formik, Field, Form } from "formik";
import { makeStyles } from "@mui/styles";
// Image process
import FooterImage from "../../static/images/fy_logo.png";
import PreferenceComponent from "../common/Preference";
import SncfLogo from "../../static/images/logo_sncf.png";
import SbbCffFfsLogo from "../../static/images/logo_SBB_CFF_FFS.png"
import { ConfigAPI } from "../../apis/ConfigAPI";
// To Call the navigation components
import ForgotPasswordComponent from "./ForgotPassword";
import useValidation from "../common/ValidationSchema";
import ChangePasswordComponent from "./ChangePassword";
import { AuthAPI } from "../../apis/AuthAPI";

const LoginComponent = (props) => {
  // call styles for controls
  const classes = useStyles();
  // Call i18n translate function
  const { t } = useTranslation();

  const [config, setConfig] = useState();
  const [loadingConfig, setLoadingConfig] = useState(true);
  
  const getConfiguration = () => {
    ConfigAPI.get()
    .then((response) => {
      setConfig(response)
      setLoadingConfig(false)
    })
    .catch((error) => {
      console.log(error);
      setLoadingConfig(false)
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const handleModalWindow = (e) => {
    setShowModal(true);
  };
  const [open, setOpen] = useState(true);
  const [openPreference, setOpenPreference] = useState(true);
  const handlePreferenceWindow = () => {
    setShowPreferenceModal(true);
    // setOpen(false);
  };
  const [lang, setLang] = useState("");
  const handleSaveChanges = (e) => {
    setLang(t("msg_PreferenceSaveChanges"));
    setTimeout(function () {
      setOpen(false);
    }, 1000);
    window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
  const handleChangePassword = (e) => {
    setShowChangePasswordModal(true);
  };

  // Login API Calling using dispatch function
  const [isLoading, setLoading] = useState(false);
  const handleLogin = async (values, { setStatus, resetForm }) => {
    setStatus({})
    const { username, password } = values;
    setLoading(true);
    AuthAPI.login({ username, password })
      .then((response) => {
        resetForm({});
        setLoading(false);
        if (response) {
          if (response.last_login == null){
              handleChangePassword();
              localStorage.setItem("user", JSON.stringify(response));
          } else {
            localStorage.setItem("user", JSON.stringify(response));
            localStorage.setItem("configOLM", JSON.stringify(response.config));
            props.history.push("/gears");
          }
        } else {
          setStatus({
            sent: false,
            msg: response.message,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setStatus({
          sent: false,
          msg: error.message,
        });
      });
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    <Grid xs={10} style={{height: '140px'}}>

    <Grid className="top_container">
      <Grid className="container">
        <Grid className="content_panel">
          <Typography component="h1">{t("ATESS_SERVER")}</Typography>
          <Grid className="login_content">
            <Grid container direction="row" alignItems="stretch" >
              <Grid item container direction="column" alignItems="center" xs>
                <Grid item xs>
                  {!loadingConfig && <CardMedia
                    component="img"
                    image={(config && config.customer_logo) || (!loadingConfig && SncfLogo)}
                    alt="SCNF logo"
                    className={classes.Image}
                  />}
                </Grid>
                <Grid item xs>
                  {!loadingConfig && <CardMedia
                    component="img"
                    image={(config && config.infra_manag_logo) || (!loadingConfig && SbbCffFfsLogo)}
                    alt="SBB CFF FFS logo"
                    className={classes.Image}
                  />}
                </Grid>
              </Grid>

              <Grid item xs={8} className="login_pane">
                <Typography component="h1">
                  {t("login_lang_login")}
                </Typography>
                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                  }}
                  validationSchema={useValidation.validateLogin(t)}
                  onSubmit={handleLogin}
                >
                  {({ handleChange, status, values, errors, touched }) => (
                    <Form data-testid="form">
                      {status && (
                        <Typography
                          component="p"
                          className={`${
                            status.sent ? "success_msg" : "error_msg"
                          }`}
                        >
                          {status.msg}
                        </Typography>
                      )}
                      {errors && (
                        <Typography component="p" className="error_msg">
                          {errors.username || (touched.password && errors.password)}
                        </Typography>
                      )}
                        <Grid container>
                          <Grid xs={3}>
                            <FormLabel className={classes.Label}>
                              {t("login_lang_username")}
                            </FormLabel>
                          </Grid>
                          <Grid xs={9} className="login_field_align">
                            <Field
                              name="username"
                              data-testid="username"
                              value={values.username}
                              onChange={handleChange}
                              type="text"
                              className="login_field"
                            />
                          </Grid>
                          <Grid xs={3}>
                            <FormLabel className={classes.Label}>
                              {t("login_lang_pass")}
                            </FormLabel>
                          </Grid>
                          <Grid xs={9} className="login_field_align">
                            <Field
                              name="password"
                              data-testid="password"
                              value={values.password}
                              onChange={handleChange}
                              type="password"
                              className="login_field"
                            />
                          </Grid>
                        </Grid>
                     
                      <Grid className="button_area">
                        {isLoading ? (
                          <Button
                            disabled
                            className={classes.button}
                          >
                            <CircularProgress size={10} />
                            {t("LOG_IN")}
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            data-testid="submit"
                            className={classes.button}
                          >
                            {t("login_lang_login")}
                          </Button>
                        )}
                        <Button
                          onClick={handleModalWindow}
                          className={classes.button}
                        >
                          {t("forgot_lang_forgot")}
                        </Button>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="footer_area">
          <Grid container>
            <Grid item xs={2}>
              <CardMedia component="img" image={FooterImage} alt="" />
            </Grid>
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography component="p" className={classes.p}>
                  {t("VERSION")} {process.env.REACT_APP_OLM_VERSION}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      {/* Forgot Password Window */}
      {showModal && <ForgotPasswordComponent />}
      {showChangePasswordModal && <ChangePasswordComponent />}
    </Grid>
      {/* Preference Language Window */}
      {showPreferenceModal && (
      <PreferenceComponent
        handleChangePassword={handleChangePassword}
        handleSaveChanges={handleSaveChanges}
        langVal={lang}
        open={openPreference}
        handleClose={handleClose}
      />
      )}
    </Grid>
  );
};

//Styles Area
const useStyles = makeStyles({
  button: {
    width: "250px",
    "&.MuiButton-root": {
      backgroundImage: "linear-gradient(to bottom, #FFFFFF 0%, #E2E9F9 100%)",
      color: "black",
      fontWeight: "bold",
      border: "1px solid",
      borderColor: "#DEE6E9 #9EB3CD #9EB3CD #DEE6E9 !important",
      margin: "1px",
    },
  },
  p: {
    "&.MuiTypography-root": {
      position: "relative",
      padding: "5px",
      float: "right"
    },
  },
  Image: {
    "&.MuiCardMedia-root": {
      margin: "5px 0",
    },
  },
 
  Link: {
    "&.MuiTypography-root": {
      color: "#1976d2",
      fontWeight: "bold",
      textDecoration: "none",
      "&:hover": {
        color: "#FFAB19",
        fontWeight: "bold",
        cursor: "pointer",
      },
    },
  },
  Label: {
    "&.MuiFormLabel-root": {
      color: "black",
      fontWeight: "bold",
      margin: "1px 0px 0px 10px",
      padding: "0px",
      lineHeight: "1.5em",
      position: "relative",
      top: "5px",
    },
  },
});

export default LoginComponent;