// React and Redux declarations
import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import form related actions and declarations
import { Grid, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import BackButton from "../common/BackButton";
import { useParams } from 'react-router-dom';
import { GearAPI } from "../../apis/GearAPI";


const GearComponent = () => {
    const params = useParams();
    const gear_id = params.gear_id
    const [currentEditGear, setCurrentEditGear] = useState({});

    const getGear = () => {
        GearAPI.get({gear_id}, true)
        .then((response) => {
            if (response.data !== null) {
                const gearData = response.data[0]
                setCurrentEditGear(gearData)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    const classes = useStyle();
    // language area
    const { t } = useTranslation();
    const [state, setState] = useState("")

    useEffect(() => {
        getGear()
    }, []);
    return (
        <Fragment>
            <Grid className="content_panel">
                <Typography component="h1" >{t("adduser_h1_info")}</Typography>
                    {state !== "" && (
                    <Typography component="p" className={state['success'] ? "success_msg": "error_msg"}>
                    {state.message}
                    </Typography>
                )}
                <Grid container>
                    <Grid item xs={5}>
                        <Grid container className='min_height p_10'>
                            <Grid item xs={5} >
                                <b>{t("equipment_id")} </b>
                            </Grid>
                            <Grid item xs={7}>
                                <span>
                                    {currentEditGear && currentEditGear.equipment_id}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={5} >
                                <b>{t("uic_id")} </b>
                            </Grid>
                            <Grid item xs={7}>
                                <span>
                                {currentEditGear && currentEditGear.uic_id}
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height p_10'>
                            <Grid item xs={5} >
                                <b>{t("sncf_number")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                                {currentEditGear && currentEditGear.sncf_number}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={5} >
                                <b>{t("trainset_number")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                                {currentEditGear && currentEditGear.trainset_number}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height p_10'>
                            <Grid item xs={5} >
                                <b>{t("config_fleet_name")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                            {currentEditGear && currentEditGear.fleet && currentEditGear.fleet.fleetname ? currentEditGear.fleet.fleetname : ''}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={5} >
                                <b>{t("config_act_olm")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                            {currentEditGear && currentEditGear.config && currentEditGear.config.is_active === true ?
                                t("gear_activated") : t("gear_inactivated")}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height p_10'>
                            <Grid item xs={5} >
                                <b>{t("nidc_ranges")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                                {currentEditGear && currentEditGear.config && currentEditGear.config.nidc_value ? currentEditGear.config.nidc_value.map((data, index) => {
                                    return <div className={classes.nidC}>
                                        <span>{`[${data.lower} ; ${data.upper}]`}</span>
                                    </div>
                                }) : ''}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={5} >
                                <b>{t("GutM_low_th")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                            {currentEditGear && currentEditGear.config && currentEditGear.config.gutMlowTh ? currentEditGear.config.gutMlowTh : ''}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height p_10'>
                            <Grid item xs={5} >
                                <b>{t("GutM_up_th")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                            {currentEditGear && currentEditGear.config && currentEditGear.config.gutMupTh ? currentEditGear.config.gutMupTh : ''}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={5} >
                                <b>{t("config_nomsg_packet")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                            {currentEditGear && currentEditGear.config && currentEditGear.config.msgNumber ? currentEditGear.config.msgNumber : ''}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height p_10'>
                            <Grid item xs={5} >
                                <b>{t("target_server")} </b>
                            </Grid>
                            <Grid item xs={7}>
                            <span>
                            {currentEditGear && currentEditGear.config && currentEditGear.config.server && currentEditGear.config.server.server_type === "0" ?
                                t("flag2_integ_server") : t("flag2_prod_server")}
                            </span>
                            </Grid>
                        </Grid>
                        <Grid container className='min_height bg_color p_10'>
                            <Grid item xs={5} >
                                <b>{t("certificate_present")} </b>
                            </Grid>
                            <Grid item xs={7}>
                                <span>
                                {currentEditGear && currentEditGear.config && currentEditGear.config.server && (currentEditGear.config.server.certificate === "" || currentEditGear.config.server.certificate === null )  ?
                                    <Typography component="p" className="error_msg">
                                        {t("missing_certificate")}
                                    </Typography>
                                    :
                                    <>
                                        <FileCopyIcon className={classes.downImage} />
                                    </>  
                                }
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <BackButton url={'/gears'} text={t("back_to_gears")} />
        </Fragment >
    );
};

const useStyle = makeStyles({
    downImage: {
      "&.MuiSvgIcon-root": {
        width: "10%",
        color: "#2F3D92",
      },
    },
    nidC: {
        marginBottom: '3px',
        "&:last-child": {
            marginBottom: '0',
        }
    }
  });
export default GearComponent;