import axios from "axios"


export const api = axios.create({
    baseURL: `${process.env.REACT_APP_URL}/api/v1/`.replace("undefined/", "")
})

const TOKEN_EXCEMPT = ['/users/login/', 'users/forgot-password/', '/management/config/']

api.interceptors.request.use(
    async config => {
        if (!TOKEN_EXCEMPT.includes(config.url)) {
            const keys = JSON.parse(localStorage.getItem("user"));
            config.headers = { 
                'Authorization': `Bearer ${keys?.token || ''}`,
                'Content-Type': 'application/json'
            }
            return config;
        } else {
            return config;
        }
        
    },
    error => {
        Promise.reject(error)
});

const refreshToken = async () => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    return await axios.post(
        `${process.env.REACT_APP_URL}/api/v1/users/token/refresh/`,
        {
            refresh: currentUser.refresh
        }
    ).then((response) => {
        currentUser.token = response.data.access
        localStorage.setItem("user", JSON.stringify(currentUser))
        return currentUser.token
    }).catch((error) => {
        console.log(error)
        window.location = '/';
    });
}

api.interceptors.response.use((response) => {
    return response
}, async (error) => {
    console.log(error)
    const originalRequest = error.config
    const statusCode = error.response?.status

    if (error.code === "ERR_CANCELED") {
        return Promise.resolve()
    }

    if (statusCode && statusCode !== 401) {
        return Promise.reject(error.response.data)
    }

    if (statusCode === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshToken();
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return api(originalRequest);
    }

    return Promise.reject(error)
})