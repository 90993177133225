export function openNewWindow(target, width, height) {
    const top = (window.screen.height - height) / 2;
    const left = (window.screen.width - width) / 2;
    window.open(
        target,
        "_blank",
        "width=" +
        width +
        ",height=" +
        height +
        ",toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=1,left=" +
        left +
        ",top=" +
        top
    );
}